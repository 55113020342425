import React from 'react';
import { connect } from "react-redux";
import { Route, Router, Switch } from 'react-router';
import { Routes } from '../routes';
import AppLayout from "../layout";
import Homepage from "../homepage";
import Profile from "../profiile";
import TreeView from "../tree_view";
import TreeWizard from "../tree_wizard";
import TemplatePreview from "../template_preview";
import TemplateWizard from "../template_wizard";
import ReportView from "../report_view";
import ReportWizard from "../report_wizard";
import { loadCurrentUser } from "../store/current_user/actions";

import DSightTreeView from "../dsight/tree_view";
import DSightTemplatePreview from "../dsight/template_preview";

import history from '../get_history';
import LoginPage from "../sign_in/LoginPage";
import LoginLink from "../sign_in/LoginLink";
import CompleteUserProfile from "../sign_in/CompleteProfile";
import ResetPassword from "../sign_in/ResetPassword";
import TemplateView from "../template_view";
import TemplateSetView from "../template_set_view";
import TemplateSetAddTemplateView from "../template_set_add_template_view";
import TemplateSetPreview from "../template_set_preview";
import DecisionSetView from "../decision_set_view";
import DecisionSetAddDecisionView from "../decision_set_add_decision_view";
import DecisionSetSearchView from "../decision_set_search_view";
import ChangePassword from "../sign_in/ChangePassword";
import Loader from "./Loader";
import DSightTemplateView from "../dsight/template_view";

import DSightAnalysis from "../dsight/analysis";
import DSightAnalysisInsight from "../dsight/analysis/analysis_insight";
import DSightAnalysisInsightKpi from "../dsight/analysis/analysis_insight_kpi";
import DSightAnalysisMetrics from "../dsight/analysis/analysis_metrics";
import LearnDetails from "../learn/details";
import LearnTemplateDetails from "../learn/template_details";
import DSightMetrics from "../dsight/metrics";
import AskCloverpop from "../../decision_tree/homepage/tabs/dsight/AskCloverpop/AskCloverpop";
import { checkLoadingEffect } from "../helpers/callbacks_helpers";
import DecisionState from "../decision_state";
import SlackSuccess from "../slack_bone/success";
import SlackBone from "../slack_bone";
import ForecastSimulatorScenario from "../forecast_simulator_scenario";
import QuickPollPage from ".././quick_poll_entry_page/QuickPollPage";
import AssignedDriversView from "../assigned_drivers_view";

const SignedUserRoutes = () =>
  <Switch>
    <Route exact path={Routes.root} component={Homepage} />
    <Route path={Routes.homepage_tab_item} component={Homepage} />
    <Route exact path={Routes.homepage_tab} component={Homepage} />
    <Route exact path={Routes.homepage} component={Homepage} />
    <Route path={Routes.profile} component={Profile} />

    <Route exact path={Routes.dsight_template_view} component={DSightTemplateView} />
    <Route exact path={Routes.dsight_template_preview} component={DSightTemplatePreview} />

    <Route exact path={Routes.dsight_metrics} component={DSightMetrics} />
    <Route exact path={Routes.dsight_metric} component={DSightMetrics} />

    <Route exact path={Routes.dsight_analysis} component={DSightAnalysis} />
    <Route exact path={Routes.dsight_analysis_metric} component={DSightAnalysisMetrics} />
    <Route exact path={Routes.dsight_analysis_metrics} component={DSightAnalysisMetrics} />
    <Route exact path={Routes.dsight_analysis_insight} component={DSightAnalysisInsight} />
    <Route exact path={Routes.dsight_analysis_insight_kpi} component={DSightAnalysisInsightKpi} />

    <Route exact path={Routes.forecast_simulator_scenario} component={ForecastSimulatorScenario} />

    <Route exact path={Routes.dsight_tree_view} component={DSightTreeView} />
    <Route path={Routes.template_preview} component={TemplatePreview} />
    <Route path={Routes.template_wizard} component={TemplateWizard} />
    <Route exact path={Routes.template_view} component={TemplateView} />
    <Route path={Routes.tree_view} component={TreeView} />
    <Route path={Routes.tree_wizard} component={TreeWizard} />
    <Route path={Routes.decision_state} component={DecisionState} />

    <Route exact path={Routes.template_set_view} component={TemplateSetView} />
    <Route exact path={Routes.template_set_add_decision_view} component={TemplateSetAddTemplateView} />
    <Route path={Routes.template_set_preview} component={TemplateSetPreview} />
    <Route path={Routes.decision_set_add_decision_view} component={DecisionSetAddDecisionView} />
    <Route path={Routes.decision_set_search_view} component={DecisionSetSearchView} />
    <Route path={Routes.decision_set_view} component={DecisionSetView} />
    <Route exact path={Routes.learn_template_details} component={LearnTemplateDetails} />
    <Route path={Routes.learn_details} component={LearnDetails} />
    <Route path={Routes.report_wizard} component={ReportWizard} />
    <Route path={Routes.report_view} component={ReportView} />
    <Route exact path={Routes.dsight_ask_cloverpop_conversation} component={AskCloverpop} />
    <Route path={Routes.dsight_ask_cloverpop} component={AskCloverpop} />
    <Route path={Routes.assigned_drivers} component={AssignedDriversView} />

    <Route exact path={Routes.slack_bone} component={SlackBone} />
    <Route exact path={Routes.slack} component={SlackBone} />
    <Route exact path={Routes.slack_success} component={SlackSuccess} />
  </Switch>

const NonAuthUserRoutes = () =>
  <Switch>
    <Route exact path={Routes.root} component={LoginPage} />
    <Route path={Routes.sign_in} component={LoginPage} />
    <Route path={Routes.login_link} component={LoginLink} />
    <Route path={Routes.reset_password} component={ResetPassword} />
    <Route exact path={Routes.submit_reset_password} component={ChangePassword} />
    <Route path={Routes.change_reset_password} component={ChangePassword} />
    <Route path={Routes.tree_view} component={TreeView} />
    <Route path={Routes.dsight_tree_view} component={DSightTreeView} />
    <Route path={Routes.invites_landing_page} component={CompleteUserProfile} />
    <Route path={Routes.quick_poll_entry} component={QuickPollPage} />

    <Route exact path={Routes.slack_bone} component={SlackBone} />
    <Route exact path={Routes.slack} component={SlackBone} />
    <Route exact path={Routes.slack_success} component={SlackSuccess} />

    <Route exact path={Routes.dsight_analysis} component={DSightAnalysis} />
    <Route exact path={Routes.dsight_analysis_metric} component={DSightAnalysisMetrics} />
    <Route exact path={Routes.dsight_analysis_metrics} component={DSightAnalysisMetrics} />
    <Route exact path={Routes.dsight_analysis_insight} component={DSightAnalysisInsight} />
    <Route exact path={Routes.dsight_analysis_insight_kpi} component={DSightAnalysisInsightKpi} />
  </Switch>

export const Content = ({ current_user, signIn, loadCurrentUser }) => {
  checkLoadingEffect(current_user, loadCurrentUser)

  return <Router history={history}>
    <AppLayout>
      {
        current_user.loaded ?
          signIn.signedIn ? <SignedUserRoutes /> : <NonAuthUserRoutes /> :
          <Loader />
      }
    </AppLayout>
  </Router>
};

const mapStateToProps = ({ current_user, signIn }) => ({ current_user, signIn });
export default connect(mapStateToProps, { loadCurrentUser })(Content);
