import React, { useState, useEffect } from 'react';
import Wrapper from '../Wrapper';
import DriverInfo from "../driver/DriverInfo";
import AggregatedData from "../driver/AggregatedData";
import Header from "../driver/Header";
import ContractedContent from "../driver/ContractedContent";
import ExpandedContent from "../driver/ExpandedContent";
import { isLeftSidebarOpen } from "../../../helpers/sidebar_helpers";
import { isDriverInEditModeByOthers, performEditDriverAnswerAction } from "../../../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../../../channels/tree_channel";
import { driverInitData, editingDriverAlert } from "../shared/helpers";
import {isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
import { isPresent } from "../../../helpers/common";
import DriverStatus from "../driver/DriverStatus";
import { ArrowUpIcon } from "../../../icons/custom_icons";

export const assignedDriverUser = (collaborators = [], contactsData = {}, driverObject) => {
  if (!driverObject.isAssigned) return {};

  return driverObject.assignee(contactsData.contacts) ||
    collaborators.find((user) => driverObject.driver.assign_to_user?.toLowerCase() === user.email) ||
    { email: driverObject.driver.assign_to_user }
}

export const onEditClickCallback = (user, channels, slug, sidebar, decision, setDriverInputSidebarOpen, resetDriverInputSidebar,
                                    allowStreaming = true, assignDriverEntry = false) => {

  if((isVisitor(user) && !assignDriverEntry) || isDeciderReadOnly(user) || decision?.read_only) return false;

  if(allowStreaming && isDriverInEditModeByOthers(channels, TREE_CHANNEL_ACTIONS.edit_driver_answer, slug, user))
    return editingDriverAlert(channels, TREE_CHANNEL_ACTIONS.edit_driver_answer, slug)

  allowStreaming && performEditDriverAnswerAction(user, slug, true);
  if(sidebar.driverInputSidebar && sidebar.driverSlug === slug) {
    setDriverInputSidebarOpen(!sidebar.driverInputSidebar);
    return;
  }
  const timeout = sidebar.driverInputSidebar ? 600 : 100;
  if(sidebar.driverInputSidebar) resetDriverInputSidebar();
  setTimeout(() => setDriverInputSidebarOpen(true, slug), timeout)
}

export const expandDriverCardCallbacks = (sidebar, slug, setExpanded, expanded, driverObject) => {
  useEffect(() => {
    if(expanded && isLeftSidebarOpen(sidebar) && sidebar.driverSlug === slug) setExpanded(false);
  }, [sidebar])
  useEffect(() => {
    if (expanded && !driverObject.isCompleted) {
      setExpanded(false)
    }
  }, [expanded, driverObject.isCompleted]);
}

const DriverCard = ({
                      driverData, tree,
                      indentStep = 1,
                      collaborators = [],
                      decisionObject, user,
                      contactsData, sidebar,
                      channels, decision,
                      setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData, shortBorderToArrow = false,
                      classNames = 'my-2'
                    }) => {
  const { driverObject, childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData, decisionObject);
  const [expanded, setExpanded] = useState(false);
  const assignedUser = assignedDriverUser(collaborators, contactsData, driverObject)
  const slug = driverData.driver.slug;

  expandDriverCardCallbacks(sidebar, slug, setExpanded, expanded, driverObject);

  useEffect(() => {
    const driverSlug = tree.scroll_to_driver_comment.split('-')[0];
    if(driverSlug === slug) setExpanded(true);
  }, [tree.scroll_to_driver_comment])

  useEffect(() => {
    if(isPresent(tree.scroll_to_driver)) {
      const [driverSlug, commentId] = tree.scroll_to_driver.split('-');
      if (driverSlug === slug) {
        setTimeout(() => {
          const selectedEl = document.getElementById(`driver-card-${slug}`);
          if (selectedEl) {
            selectedEl.scrollIntoView({ behavior: 'smooth' });
            setExpanded(true);
          }
          updateTreeData({ scroll_to_driver: '' })
        }, 100)
      }
    }
  }, [tree.scroll_to_driver])

  const onExpandDriverClick = () => setExpanded(!expanded)
  const onEditClick = () => onEditClickCallback(user, channels, slug, sidebar, decision, setDriverInputSidebarOpen, resetDriverInputSidebar);

  const onClickEntireCard = (e) => {
    if (driverObject.isCompleted && !expanded) {
      onExpandDriverClick();
      e.stopPropagation();
    }
    return e;
  }

  return <>
    <Wrapper indentStep={indentStep} onClick={onClickEntireCard} clickable={driverObject.isCompleted && !expanded}
             hasSingleChild={shortBorderToArrow} {...{classNames, expanded}}>
      <div className={`${expanded ? "d-block" : "d-flex"}`} id={`driver-card-${slug}`}>
        {
          expanded ? <></> : <DriverStatus {...{driverObject, contactsData}}/>
        }
        <div className={`${expanded ? '' : 'd-flex flex-column '}my-auto lh-sm w-100 me-1`} style={{minHeight: 36}}>
          <Header {...{user, driverData, decision, driverObject, childrenDrivers, setShowChildren, onExpandDriverClick, onEditClick, expanded}} />
          <DriverInfo {...{driverData, driverObject, decisionObject, user, hidden: expanded}} />
          <AggregatedData {...{driverData, hidden: expanded}} />
        </div>
        {
          expanded ?
            <ExpandedContent {...{driverData, onEditClick}} /> :
            <ContractedContent {...{driverData, driverObject, assignedUser, contactsData, sidebar}} />
        }
      </div>
      <div className={`arrow-up-icon-block ${showChildren && childrenDrivers.length > 0 ? 'd-block' : 'd-none'} ${expanded ? 'ml-expanded' : ''}`}>
        <ArrowUpIcon />
      </div>
    </Wrapper>
    {
      showChildren && childrenDrivers.length > 0 ?
        <div className={`position-relative ml-border-${indentStep} ${shortBorderToArrow ? '' : 'border-to-arrow'}`}>
          {childrenDrivers.map((child, index) =>
            <DriverCard key={`driver-card-${child.driver.slug}`}
                        {...{
                          driverData: child,
                          indentStep: indentStep + 1,
                          decisionObject, user, collaborators, contactsData, sidebar, channels, tree,
                          setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData,
                          shortBorderToArrow: childrenDrivers.length === 1 || index === childrenDrivers.length - 1
                        }} />
          )}
        </div> : null
    }

  </>
}
export default DriverCard;
