import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import Image from "react-bootstrap/Image";
import Button from 'react-bootstrap/Button';
import { Link, useHistory } from "react-router-dom";
import {
  createGroup,
  deleteGroup,
  inviteUsersToGroup,
  loadDashboardGroups,
  loadDashboardUsers,
  needToLoadHomepageSection,
  removeExistedUserFromGroup,
  removePendingUserFromGroup,
  updateGroup
} from "../../store/homepage/actions";
import { ALL_GROUP_SLUG, isAdmin, isMember, TABS_URLS } from "../../helpers/home_helpers";
import { Loader } from "../../common/Loader";
import { isBlank, isPresent, paginateArray, qSortArray } from "../../helpers/common";
import { isExistedUser, isPendingUser, SORT_BY_TYPES, sortUsersCallback, USERS_PER_PAGE } from "./UsersTab";
import {
  removeExistedUserFromGroups,
  removeGroupWarning,
  removeLastUserWarning,
  removeUserSelfFromGroup
} from "../../helpers/alert_helpers";
import { reloadContacts } from "../../store/contacts/actions";
import UpdateGroupModal from "../modals/UpdateGroupModal";
import AddGroupUsersModal from "../modals/AddGroupUsersModal";
import AddGroupModal from "../modals/AddGroupModal";
import { Pagination } from "../../common/Pagination";
import Select from 'react-select';
import { NoBorderFilterStyle } from "../../tree_wizard/styles/choice_entry_styles";
import { BaseDropdownBtn, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import { IconBtnDropdown } from "../../common/dropdowns";
import UserAvatarImage from "../../common/UserAvatarImage";
import { userName } from "../../helpers/user_helpers";

export const GroupsTab = ({
                            home, current_user, current_org,
                            loadDashboardUsers, loadDashboardGroups,
                            removeExistedUserFromGroup, removePendingUserFromGroup,
                            updateGroup, deleteGroup, createGroup, inviteUsersToGroup,
                            reloadContacts,
                            match
                          }) => {
  const history = useHistory()
  // initial states
  const [submitState, setSubmitState] = useState(false)
  const [addModalShown, setAddModalShown] = useState(false)
  const [editGroupModalShown, setEditGroupModalShown] = useState(false)
  const [addUsersModalShown, setAddUsersModalShown] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)


  const refreshPagination = (users) => {
    if (isBlank(users)) {
      setTotalPages(1)
      setPage(1)
    } else {
      const pages = users.length / USERS_PER_PAGE;
      const newTotalPages = pages > parseInt(pages.toString()) ? parseInt(pages.toString()) + 1 : pages
      setTotalPages(newTotalPages)
      if (page > newTotalPages) setPage(1)
    }
  }

  // initialize loading data
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'users')) loadDashboardUsers({ groups_data: isAdmin(current_user) })
  }, [home.users.loaded])
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'groups') && !isAdmin(current_user)) loadDashboardGroups()
  }, [home.groups.loaded])

  // collect all groups and active group
  const [newActiveGroupName, setNewActiveGroupName] = useState('')
  const [activeGroupSlug, setActiveGroupSlug] = useState(null)
  const [activeGroup, setActiveGroup] = useState(null)
  const allOrgGroup = useMemo(() => ({ name: `All ${current_org?.name}`, slug: ALL_GROUP_SLUG }), [current_org])
  const allGroups = useMemo(() =>
    [...qSortArray(home.groups.data, true, (a) => a.name.trim().toLowerCase()), allOrgGroup],
    [home.groups, current_org])
  const isOrgAllUsersGroup = useMemo(() => activeGroup?.slug === ALL_GROUP_SLUG, [home, match, activeGroup])
  const currentUserData = useMemo(() =>
    home.users.data.find(u => isExistedUser(u) && u.slug === current_user.slug),
    [current_user, home.users])
  const isUserAdmin = useMemo(() => isAdmin(current_user), [current_user])
  const isUserHaveAccess = useMemo(() =>
    isMember(current_user) && currentUserData?.group_slugs?.includes(activeGroup?.slug)
  , [current_user, activeGroup, currentUserData])
  const activeGroupUsers = useMemo(() =>
      home.users.data.filter(u => isOrgAllUsersGroup || u.group_slugs.includes(activeGroup?.slug))
        .sort(sortUsersCallback(SORT_BY_TYPES.first_name))
  , [home.users, activeGroup])
  const activeGroupUsersPerPage = useMemo(() =>
    paginateArray(activeGroupUsers, page, USERS_PER_PAGE)
  , [activeGroupUsers, page])

  useEffect(() => {
    let activeGroup = null;
    if (isPresent(match?.params?.id)) activeGroup = allGroups.find(g => g.slug === match.params.id)
    if (isPresent(newActiveGroupName)) activeGroup = allGroups.find(g => g.name === newActiveGroupName)
    if (isBlank(activeGroup) && isPresent(activeGroupSlug)) activeGroup = allGroups.find(g => g.slug === activeGroupSlug)
    if (isBlank(activeGroup)) activeGroup = allGroups[0];
    if (home.users.loaded && home.groups.loaded) setActiveGroupSlug(activeGroup?.slug)
    setActiveGroup(activeGroup)
  }, [match, home, home.groups, current_org, activeGroupSlug, newActiveGroupName])

  useEffect(() => {
    refreshPagination(activeGroupUsers)
  }, [match, home.users, activeGroupSlug, activeGroupUsers.length])

  // callbacks
  const submitCallback = success => {
    setSubmitState(false)
    if (success) { reloadContacts(); }
  }

  const openAddGroupModal = useCallback(() => { setAddModalShown(true) }, [home])
  const openEditGroupModal = useCallback(() => { setEditGroupModalShown(true) }, [home])
  const openAddUsersToGroupModal = useCallback(() => { setAddUsersModalShown(true) }, [home])

  const onSubmitAddGroupModal = useCallback((name, emails, callback) => {
    setSubmitState(true)
    createGroup(name, emails.join(','), (success, errors) => {
      setNewActiveGroupName(name)
      submitCallback(success)
      callback(success, errors)
    })
  }, [home])
  const onSubmitUpdateGroupModal = useCallback((slug, updateData, callback) => {
    setSubmitState(true)
    updateGroup(slug, updateData, success => {
      submitCallback(success)
      callback(success)
    })
  }, [home])
  const onSubmitAddUsersToGroupModal = useCallback((slug, emails, callback) => {
    setSubmitState(true)

    inviteUsersToGroup(slug, emails.join(','), success => {
      submitCallback(success)
      callback(success)
    })
  }, [home])

  const deleteGroupCallback = useCallback(() => {
    removeGroupWarning().then(confirmed => {
      if(!confirmed) return;

      setSubmitState(true)
      deleteGroup(activeGroup.slug, submitCallback)
    })
  }, [home, activeGroup])

  const deleteUserCallback = useCallback(user => {
    const request = isExistedUser(user) ? removeExistedUserFromGroup : removePendingUserFromGroup
    const callback = (success) => {
      if(success) {
        setSubmitState(true)
        request(user, activeGroup, submitCallback)
      }
    }
    if (activeGroupUsers.length < 2) {
      removeLastUserWarning().then((success) => {
        if (!success) return;

        setSubmitState(true)
        deleteGroup(activeGroup.slug, submitCallback)
      })
    } else if (isExistedUser(user) && user.slug === currentUserData.slug) {
      removeUserSelfFromGroup().then(callback)
    } else {
      removeExistedUserFromGroups(user).then(callback)
    }
  }, [home, home.users, current_user, activeGroup, activeGroupUsers, submitCallback])

  // Inner components
  const PageHeader = useCallback(({disabled}) =>
    <div className="row">
        <div className="col-6">
          <h1>Groups</h1>
        </div>
        <div className="col-6">
          <Button disabled={disabled} onClick={openAddGroupModal} className="h5 float-end h-36 py-1">
            <i className="fas fa-plus fa-lg me-1" />
            <span>Add group</span>
          </Button>
      </div>
    </div>

  , [home])

  const UserTile = useCallback(({user, disabled}) => {
    const name = userName(user)

    return <div className="d-flex align-items-center mt-2">
      <div className="me-2">
        <UserAvatarImage user={user} isPendingUser={isPendingUser(user)} />
      </div>
      <div className="me-2 text-truncate lh-sm">
        {name} { isPendingUser(user) ? <div className="text-muted">(Pending)</div> : '' }
      </div>
      <div className="ms-auto" hidden={isOrgAllUsersGroup || !(isUserAdmin || isUserHaveAccess)}>
        <Button disabled={disabled} onClick={() => deleteUserCallback(user)} className="btn-sm btn-sm-round btn-light text-danger">
          <i className="fas fa-times w-100" aria-hidden={"true"} />
        </Button>
      </div>
    </div>
  }, [home, current_user, activeGroup, isOrgAllUsersGroup])

  const GroupContent = useCallback(({disabled}) =>
    <div>
      <div className="d-flex">
        <h2 className="m-0 align-self-center">{activeGroup?.name}</h2>
        <div className="ms-auto" hidden={isOrgAllUsersGroup || !(isUserAdmin || isUserHaveAccess)}>
          <IconBtnDropdown id={`group-menu-dropdown-${activeGroup?.slug}`}
                           bsPrefix="ms-auto" disabled={disabled} className="ms-1">
            <BaseDropdownBtn eventKey={activeGroup?.slug} onSelect={openAddUsersToGroupModal} title="Add members" />
            <BaseDropdownBtn eventKey={activeGroup?.slug} onSelect={openEditGroupModal} title="Edit group name" />
            <BaseDropdownBtn eventKey={activeGroup?.slug} onSelect={deleteGroupCallback}>
              <DeleteItemElement name="group" />
            </BaseDropdownBtn>
          </IconBtnDropdown>
        </div>
      </div>
      <div className="">
        {activeGroupUsersPerPage.map(user =>
          <UserTile key={`group-${activeGroup?.slug}-user-${user.type}-${user.slug}`} user={user} disabled={disabled}/>
        )}
      </div>
      <div hidden={totalPages < 2} className="row mt-2">
        <Pagination page={page} totalPages={totalPages} setPage={setPage} totalCount={activeGroupUsers.length} perPage={USERS_PER_PAGE} />
      </div>
    </div>
  , [home, current_user, activeGroup, isOrgAllUsersGroup, page, totalPages, activeGroupUsersPerPage])

  const onChangeGroupsFilter = useCallback((group) => {
    setNewActiveGroupName('')
    history.push(`/homepage/${TABS_URLS.groups}/${group.value}`)
  }, [home])

  return <>
    <div className="groups-container me-auto">
      <PageHeader disabled={submitState} />
      <div className="row" hidden={!home.users.loading && !home.groups.loading}>
        <Loader />
      </div>
      <div className={`w-100 mb-3 ${home.users.loading || home.groups.loading ? 'd-none' : 'd-md-flex'}`} hidden={home.users.loading || home.groups.loading}>
        <div className={`bg-white groups-tile groups-menu d-none d-md-inline-block me-3 w-100`}>
          <div className="list-group py-2">
            {allGroups?.map(group =>
              <Link key={`menu-group-${group.slug}`} className={`list-group-item list-group-item-action fw-bolder border-0 px-3 ${group.slug === activeGroup?.slug ? 'active' : ''}`}
                    onClick={() => setNewActiveGroupName('') }
                    to={`/homepage/${TABS_URLS.groups}/${group.slug}`}>
                {group.name}
              </Link>
            )}
          </div>
        </div>
        <div className="d-md-none w-100 mb-2">
          <Select value={{value: activeGroup?.slug, label: activeGroup?.name}}
                  isDisabled={submitState}
                  placeholder="Select a group"
                  onChange={onChangeGroupsFilter}
                  components={{ IndicatorSeparator:() => null }}
                  options={allGroups.map(group => ({ value: group.slug, label: group.name }))}
                  styles={NoBorderFilterStyle} />
        </div>
        <div className="bg-white groups-tile p-3 ms-auto groups-content align-top w-100">
          <GroupContent disabled={submitState} />
        </div>
      </div>
      <div className="modals">
        <UpdateGroupModal shown={editGroupModalShown} slug={activeGroup?.slug} name={activeGroup?.name}
                          onClose={() => setEditGroupModalShown(false)}
                          onSubmit={onSubmitUpdateGroupModal} />
        <AddGroupUsersModal shown={addUsersModalShown} slug={activeGroup?.slug} group_users={activeGroupUsers}
                            onSubmit={onSubmitAddUsersToGroupModal}
                            onClose={() => setAddUsersModalShown(false)} />
        <AddGroupModal shown={addModalShown}
                       onSubmit={onSubmitAddGroupModal}
                       onClose={() => setAddModalShown(false)}
                       currentUser={current_user}
                       allGroups={allGroups}/>
      </div>
    </div>
  </>;
}

const mapStateToProps = ({ home, current_user, current_org }) => ({
  home, current_user, current_org
});

const mapDispatchToProps = (dispatch) => ({
  loadDashboardGroups: (data = {}) => {
    dispatch(loadDashboardGroups(data))
  },
  loadDashboardUsers: (data = {}) => {
    dispatch(loadDashboardUsers(data))
  },
  removeExistedUserFromGroup: (user, group, callback) => {
    dispatch(removeExistedUserFromGroup(user, group, callback))
  },
  removePendingUserFromGroup: (invite, group, callback) => {
    dispatch(removePendingUserFromGroup(invite, group, callback))
  },
  createGroup: (name, contacts, callback) => {
    dispatch(createGroup(name, contacts, callback))
  },
  updateGroup: (slug, data, callback) => {
    dispatch(updateGroup(slug, data, callback))
  },
  inviteUsersToGroup: (slug, emails, callback) => {
    dispatch(inviteUsersToGroup(slug, emails, callback))
  },
  deleteGroup: (slug, callback) => {
    dispatch(deleteGroup(slug, callback))
  },
  reloadContacts: () => {
    dispatch(reloadContacts())
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupsTab);
