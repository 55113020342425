import React, {useCallback, useMemo, useState, useEffect} from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { isBlank } from "../../helpers/common";
import Context from "./Context";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Decision from "../../models/decision";
import HamburgerDropdown, { redirectToHomepageCallback, resetDispatch } from './HamburgerDropdown'
import { isPresent } from "../../../ms_teams/helpers/common";
import OrgLogo from "./OrgLogo";
import RenderDecisionInputSidebar from "./buttons/RenderDecisionInputSidebar";
import RenderRecommendationInputSidebar from "./buttons/RenderRecommendationInputSidebar";
import RenderFollowupResults from "./buttons/RenderFollowupResults";
import RenderRateBuyInButton from "./buttons/RenderRateBuyInButton";
import {isNonUser} from "./buttons/helpers";
import Moment from "moment";
import RateBuyInModal from "../modals/RateBuyInModal";
import RenderAssignedDriversButton from "./buttons/RenderAssignedDriversButton";

const TreeHeader = ({ tree, current_org, decision, current_user, isDsight = false, ...opts }) => {
  const { error, drivers } = tree;
  if (error || isBlank(decision)) return <nav className="navbar"/>;

  const decisionObject = useMemo(() => new Decision(decision, drivers), [decision, tree]);
  const history = useHistory();
  const processBackClick = useCallback(redirectToHomepageCallback({
    current_user, history, ...opts
  }), [current_user]);

  const [showRateBuyIn, setShowRateBuyIn] = useState(false);
  const [showFollowupResults, setShowFollowupResults] = useState(false);
  const [showRateBuyInModal, setShowRateBuyInModal] = useState(false)

  const hasRateBuyIn = () => {
    if (!decision?.rate_support) return false;

    const current_date = Moment(new Date()).format('YYYY-MM-DD')
    const followup_due_date = decisionObject.lastFollowup.followup_due_date
    const userSupport = decisionObject.findSupportForUser(current_user)
    if (!decisionObject.isDecisionRecorded || decisionObject.isDecisionWithResults) return false;
    if ((isPresent(followup_due_date) && (followup_due_date <= current_date))) return false;
    if (isPresent(userSupport.support)) return false;

    return true;
  }

  const hasFollowupResults = () => {
    const followup_due_date = decisionObject.lastFollowup.followup_due_date;
    const current_date = Moment(new Date()).format('YYYY-MM-DD');
    return !decisionObject.isDecisionWithResults && decisionObject.isRecordedOrShowAsCompleted && isPresent(followup_due_date) && (followup_due_date <= current_date);
  }

  useEffect(() => {
    setShowRateBuyIn(hasRateBuyIn());
    setShowFollowupResults(hasFollowupResults())
  }, [decisionObject]);

  return <header className="decision-tree-header">
    <nav className="navbar navbar-expand bd-navbar px-3">
      <div className="d-flex align-items-center active">
        <div className="logo-dropdown pointer d-none d-lg-flex me-2">
          <OrgLogo current_org={current_org} processBackClick={processBackClick}/>
        </div>
        <div className="w-100">
          <h5><Context/></h5>
        </div>
      </div>
      <div className="navbar-nav ms-auto">
        <div className='d-lg-none'>
          <HamburgerDropdown showDecisionSet={isPresent(decision.decision_set_slug)}
                             isDsight={isDsight}
                             {...{showFollowupResults, showRateBuyIn, setShowRateBuyInModal}}/>
        </div>
        <ul className="navbar-nav ms-auto d-none d-lg-flex align-items-center">
          <li className="nav-item ms-2">
            <RenderAssignedDriversButton />
          </li>
          <li className="nav-item ms-2">
            <RenderRecommendationInputSidebar/>
            {
              !decisionObject.showAsCompleted && <RenderDecisionInputSidebar/>
            }
            {
              !isNonUser(current_user) && decisionObject && <>
                {showRateBuyIn && <RenderRateBuyInButton {...{setShowRateBuyInModal}}/>}
                {showFollowupResults && <RenderFollowupResults />}
              </>
            }
          </li>
        </ul>
      </div>
    </nav>
    <ProgressBar now={decisionObject.answeringProgress}/>
    <div className="modals">
      <RateBuyInModal shown={showRateBuyInModal} onClose={() => setShowRateBuyInModal(false)}/>
    </div>
  </header>;
}

const mapStateToProps = ({tree, current_org, current_user, decision}) => ({
  tree, current_org, current_user, decision
});
export default connect(mapStateToProps, resetDispatch)(TreeHeader);
