import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { apiReducer } from './api/reducers';
import { treeReducer } from './tree/reducers';
import { sidebarReducer } from "./sidebar/reducers";
import { contactsReducer } from "./contacts/reducers";
import { decisionAdmissionsReducer } from "./decision_admissions/reducers"
import { alertReducer } from "./alerts/reducers";
import { modalsReducer } from "./modals/reducers";
import { decisionReducer } from "./decision/reducers";
import { shareReducer } from "./share/reducers";
import { currentUserReducer } from "./current_user/reducers";
import { wizardReducer } from "./wizard/reducers";
import { homepageReducer } from "./homepage/reducers";
import { templateReducer } from "./template/reducers";
import { orgCategoriesReducer } from "./org_categories/reducers";
import { currentOrgReducer } from "./current_org/reducers";
import { orgDriverTypesReducer } from "./org_driver_types/reducers";
import { signInReducer } from "./sign_in/reducers";
import { analysisReducer } from "./analysis/reducers";
import { learnReducer } from "./learn/reducers";
import { playbookNotesReducer } from "./playbook_notes/reducers";
import { metricsReducer } from "./metrics/reducers";
import { dSightGoalReducer } from "./d_sight_goal/reducers";
import { channelsReducer } from "./channels/reducers";
import { templateSetReducer } from "./template_set/reducers";
import { decisionSetReducer } from "./decision_set/reducers";
import { orgReportPropertiesReducer } from "./org_report_properties/reducers"
import { reportReducer } from "./report/reducers"
import { forecastSimulatorScenarioReducer } from "./forecast_simulator_scenario/reducers";
import { assignedDriversReducer } from "./assigned_drivers/reducers";
export const LARGE_SCREEN_WIDTH = 1200;
export const MAX_SMALL_SCREEN_WIDTH = 768;

const rootReducer = combineReducers({
    api: apiReducer,
    tree: treeReducer,
    sidebar: sidebarReducer,
    contacts: contactsReducer,
    decision_admissions: decisionAdmissionsReducer,
    alerts: alertReducer,
    decision: decisionReducer,
    share_data: shareReducer,
    current_user: currentUserReducer,
    modal: modalsReducer,
    wizard: wizardReducer,
    home: homepageReducer,
    template: templateReducer,
    current_org: currentOrgReducer,
    org_categories: orgCategoriesReducer,
    org_driver_types: orgDriverTypesReducer,
    signIn: signInReducer,
    analysis: analysisReducer,
    learn: learnReducer,
    playbook_notes: playbookNotesReducer,
    metrics: metricsReducer,
    d_sight_goal: dSightGoalReducer,
    channels: channelsReducer,
    template_set: templateSetReducer,
    decision_set: decisionSetReducer,
    org_report_properties: orgReportPropertiesReducer,
    report: reportReducer,
    forecast_simulator_scenario: forecastSimulatorScenarioReducer,
    assigned_drivers: assignedDriversReducer
});

export default function configureStore(){
    const middlewares = [thunkMiddleware];
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const middleWareEnhancer = composeEnhancers(applyMiddleware(...middlewares));

    const store = createStore(rootReducer, middleWareEnhancer);

    return store;
}

export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}
