import React, {Fragment, useEffect} from 'react';
import { connect } from "react-redux";
import CloseIcon from "../../../common/CloseIcon";
import { openModal } from "../../../store/modals/actions";
import {DriverNotes} from "./QuestionRow";
import DriverEntryModal from "../../modals/DriverEntryModal";
import { saveSideBarData } from "../../../store/sidebar/common_actions";
import Driver, { DEFAULT_DRIVER_TYPE_NAME } from "../../../models/driver";
import { isBlank, isPresent, qSortArray } from "../../../helpers/common";
import AssignmentSection from "../sections/AssignmentSection";
import ChatGptDriverSummarize from "./ChatGptDriverSummarize";
import Decision from "../../../models/decision";
import SummarizeFilesButton, {filterAndSortDataSources} from "./SummarizeFilesButton";
import {findDriverData} from "../../accordion_tree/shared/helpers";
import * as moment from "moment/moment";
import {isShownGptButtons} from "../recommendation_input/AssignedToSection";
import { assignedDriverUser } from "../../accordion_tree/cards/DriverCard";
import {isVisitor} from "../../../helpers/user_helpers";

const TopRow = ({
                  driverData, openSideBar, driverTypeName, decision, contactsData, children, current_org,
                  isDSight, dataSources, assignDriverEntry = false, user
                }) => {
  if (isBlank(driverData?.driver) || isBlank(decision)) return null;

  const driverNotes = driverData.driver.notes
  const decisionObject = new Decision(decision)
  const driverObject = new Driver(driverData.driver, driverData.driver_sources_slugs, [], decisionObject)
  const assignedUser = assignedDriverUser([], contactsData, driverObject)
  const assignedCollaboratorEmail = contactsData.contacts.find(c => c.email === driverData.driver.assign_to_user)?.email
  const decisionObj = new Decision(decision, driverData.drivers)
  const hideFromAssignee = assignDriverEntry && isVisitor(user)
  const shownGptButtons = () => isShownGptButtons(decision, current_org, decisionObj, driverData.children, dataSources)

  return <>
    <div className="side-panel header h-auto px-3">
      <div className='d-flex justify-content-between my-auto pt-2'>
        <h2>{driverTypeName || DEFAULT_DRIVER_TYPE_NAME}</h2>
        <div className="d-flex ms-auto">
          { children }
          <CloseIcon classParams="d-inline-block" onClose={() => { openSideBar(false) }} />
        </div>
      </div>
    </div>
    <div className={`${(isPresent(driverNotes) || (isPresent(assignedUser) && !hideFromAssignee) || (isPresent(assignedCollaboratorEmail) && !hideFromAssignee) || shownGptButtons()) ? 'border-bottom mb-3' : ''}`}>
      <AssignmentSection assignedUser={assignedUser} assignedCollaboratorEmail={assignedCollaboratorEmail}
                         addClass="mx-3" modalType='DriverAssignModal' assignDriverEntry={assignDriverEntry} isDSight={isDSight}/>
      <DriverNotes driverNotes={driverNotes} />
      <div className='mb-2'>
        <ChatGptDriverSummarize driverData={driverData} type={'driver'} addClass='px-3' />
        <SummarizeFilesButton driverData={driverData} type={'driver'} addClass='px-3' dataSources={dataSources} />
      </div>
    </div>
  </>

}

const DriverTypeOrQuestionRow = ({
                                   driverData, decision, sidebar, tree, channels, user, openSideBar, dataSources,
                                   openModal, saveSideBarData, contactsData, current_org, isDSight, assignDriverEntry = false,
                                   ...opts
                                }) => {
  const slug = driverData.driver.slug;

  useEffect(() => {
    saveSideBarData({ drivers: tree.drivers })
  }, [tree.drivers])

  return <Fragment>
    <TopRow driverData={driverData} decision={decision} sidebar={sidebar} slug={slug} user={user} dataSources={dataSources}
            openModal={openModal} openSideBar={openSideBar} channels={channels} current_org={current_org} assignDriverEntry={assignDriverEntry}
            driverTypeName={driverData.driver.driver_type_name} contactsData={contactsData} isDSight={isDSight} {...opts} />
    <div className="modals">
      <DriverEntryModal key={`left-sidebar-driver-details-modal-${slug}`} driverData={driverData} />
    </div>
  </Fragment>
}

const mapStateToProps = ({ sidebar, tree, decision, channels, current_user, org_driver_types, contacts, current_org }) => {
  const allDataSources = (tree.data_sources) || [];
  const allowedDataSources = filterAndSortDataSources(allDataSources);
  const driverData = sidebar.driverSlug ? findDriverData(sidebar, sidebar.driverSlug || '') : sidebar.drivers
  const driverSourcesSlugs = driverData.driver_sources_slugs || [];
  const sortedDriverDataSources = qSortArray(allowedDataSources.filter(source => driverSourcesSlugs.includes(source.slug)), true, (o) => moment(o.created_at));

  return {
    sidebar, tree, decision, channels, user: current_user, org_driver_types, contactsData: contacts, current_org, dataSources: sortedDriverDataSources
  };
};
export default connect(mapStateToProps, { openModal, saveSideBarData })(DriverTypeOrQuestionRow);
