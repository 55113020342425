import React from "react";
import { isFuturePeriod } from "../../models/forecast/ForecastScenario";
import { isBlank } from "../../helpers/common";
import FactsCellRenderer from "../table_components/FactsCellRenderer";
import {
  aggregatedFact,
  parseFormatData,
  valueFormatter,
  getAggregatedValues,
  calculateCAGR,
  filterLeafChildren,
  calculateYTD_YTG,
} from "./common";
import { isEditableCell } from "./ag_grid_cell_style";
import {
  FACTS_HEADER,
  HIDDEN_COLUMNS,
  GROUP_COL_ID_SUFFIX,
  VALUE_SALES_FACT, FACTS_GR_COL_ID, SUB_FACT_HEADER
} from "./ag_grid_vars";
import CustomPeriodCellRenderer from "../table_components/CustomPeriodCellRenderer";
import { cmuColumnsFilterParams, factsFilterParams, filterValueGetter } from "./ag_grid_filters";
import CustomFilter from "./CustomFilter";
import { timePeriodNameParse } from "../../models/forecast/ForecastTImeScale";

const BASE_HEADER_OPTIONS = {
  sortable: true,
  filter: true,
  resizable: true
}
const CHOOSE_COLUMN_ITEM = 'columnChooser';
const ROW_GROUP_MENU_ITEM = 'rowGroup';
const ROW_UNGROUP_MENU_ITEM = 'rowUnGroup';
const EXPAND_ALL_MENU_ITEM = 'expandAll';
const CONTRACT_ALL_MENU_ITEM = 'contractAll';
const EXCLUDE_MENU_ITEMS = [
  CHOOSE_COLUMN_ITEM, ROW_GROUP_MENU_ITEM, ROW_UNGROUP_MENU_ITEM
];

const totalAggOutput = (allValues, allRows, value, decimal) => {
  return {
    allValues,
    allRows,
    toNumber: () => value,
    toString: () => valueFormatter(value, { decimal })
  };
};

const getPeriod = (forecastScenario, timeScale, colId) => {
  return forecastScenario.allTimeScalePeriods(timeScale).find(period => period.id === colId);
};

const handleLeafGroup = (params, forecastScenario, config) => {
  if (params.values[0] === undefined) return undefined;
  return totalAggOutput([{ value: params.values[0] }], [], params.values[0], parseFormatData(params, forecastScenario, config).decimal);
};

const checkTimeScale = (params, forecastScenario) => {
  if (forecastScenario.timeScale !== params.colDef.context.timeScale)
    forecastScenario.setTimeScale(params.colDef.context.timeScale);
};

export const CAGRHistoricalAggFunc = (params, forecastScenario) => {
  // NOTE: this is a hack to prevent the issue with wrong current time scale in forecastScenario instance
  checkTimeScale(params, forecastScenario);
  return calculateCAGR(params, forecastScenario, forecastScenario.historicalCAGRPeriods);
};

export const CAGRForecastedAggFunc = (params, forecastScenario) => {
  // NOTE: this is a hack to prevent the issue with wrong current time scale in forecastScenario instance
  checkTimeScale(params, forecastScenario);
  return calculateCAGR(params, forecastScenario, forecastScenario.forecastedCAGRPeriods);
};

export const YTDAggFunc = (params, forecastScenario) => {
  // NOTE: this is a hack to prevent the issue with wrong current time scale in forecastScenario instance
  checkTimeScale(params, forecastScenario);
  return calculateYTD_YTG(params, forecastScenario, forecastScenario.YTDPeriods, forecastScenario.YTDPreYearPeriods);
};

export const YTGAggFunc = (params, forecastScenario) => {
  // NOTE: this is a hack to prevent the issue with wrong current time scale in forecastScenario instance
  checkTimeScale(params, forecastScenario);
  return calculateYTD_YTG(params, forecastScenario, forecastScenario.YTGPeriods, forecastScenario.YTGPrevYearPeriods);
};

export const totalAggFunc = (params, forecastScenario) => {
  const filteredAllLeafChildren = filterLeafChildren(params.rowNode.allLeafChildren);
  const period = getPeriod(forecastScenario, params.colDef.context.timeScale, params.colDef.colId);

  if (isBlank(filteredAllLeafChildren[0]?.data) || isBlank(period)) return undefined;

  const config = forecastScenario.config;
  const aggFact = aggregatedFact(config, forecastScenario) || forecastScenario.findFactByDriverName(VALUE_SALES_FACT);

  if (params.rowNode.leafGroup) {
    return handleLeafGroup(params, forecastScenario, config);
  }
  const { allValues, allRows, aggregatedValue } = getAggregatedValues(config, aggFact, filteredAllLeafChildren, params, period, forecastScenario);
  if(aggregatedValue === undefined) return undefined;

  return totalAggOutput(allValues, allRows, aggregatedValue.value, parseFormatData(params, forecastScenario, config).decimal);
};

const factsMenuItems = (params) => {
  const menuItems = [];
  const excludeItems = [...EXCLUDE_MENU_ITEMS, EXPAND_ALL_MENU_ITEM, CONTRACT_ALL_MENU_ITEM];
  params.defaultItems.forEach((item) => {
    if (excludeItems.indexOf(item) < 0) {
      menuItems.push(item);
    }
  });
  return menuItems;
}

const customMenuItems = (params) => {
  return params.defaultItems.filter(item => !EXCLUDE_MENU_ITEMS.includes(item)).map(item => {
    if ([EXPAND_ALL_MENU_ITEM, CONTRACT_ALL_MENU_ITEM].includes(item)) {
      const isExpanded = item === EXPAND_ALL_MENU_ITEM;
      const name = isExpanded ? 'Expand All Rows Groups' : 'Collapse All Rows Groups';
      return {
        name,
        action: () => {
          params.api.forEachNode(node => {
            if (node.field === params.column.colDef.headerName) {
              params.api.setRowNodeExpanded(node, isExpanded);
            }
          });
        }
      };
    }
    return item;
  });
}

const groupedColDefs = ({ forecastScenario, config, forecastBenchmarkScenario }) => {
  const colDefs = forecastScenario.groupFields.map(groupField =>
    ({
      headerName: groupField,
      showRowGroup: groupField,
      colId: `${groupField}-${GROUP_COL_ID_SUFFIX}`,
      cellRenderer: 'agGroupCellRenderer',
      filter: CustomFilter,
      filterParams: cmuColumnsFilterParams(config, forecastScenario, groupField),
      filterValueGetter: (params) => params.data[groupField],
      cellRendererParams: { suppressCount: true },
      width: 170,
      sort: 'asc',
      mainMenuItems: customMenuItems
    })
  )
  colDefs.push(
    {
      headerName: 'Facts',
      colId: FACTS_GR_COL_ID,
      minWidth: 250,
      showRowGroup: 'Facts',
      filter: CustomFilter,
      filterParams: factsFilterParams(config, forecastScenario),
      filterValueGetter: (params) => filterValueGetter(params),
      cellRenderer: (params) => <FactsCellRenderer {...{ params, config, forecastScenario, forecastBenchmarkScenario } } />,
      cellRendererParams: { suppressCount: true },
      type: 'styledFactsColumn',
      aggFunc: 'first',
      valueGetter: (params) => {
        if (params.data) {
          return params.data[SUB_FACT_HEADER];
        }
      },
      mainMenuItems: factsMenuItems
    }
  )
  return colDefs;
}

export const getPeriodId = (periods = [], field = '') => periods.find(p => p.name === field).id;

const CAGR_YTD_YTG_col_def = (definitions, aggFuncName) => {
  definitions.aggFunc = aggFuncName;
  definitions.type = ['styledCAGRColumn'];
  definitions.filter = false
}

export const preparedColDefs = ({ forecastScenario, timeScale, config, ...opts }) => {
  const periods = forecastScenario.periods();
  const isEditableTimeScale = forecastScenario.isEditableTimeScale;
  return groupedColDefs({ forecastScenario, config, ...opts }).concat(forecastScenario.viewHeaders.map(field => {
    let definitions = { field, ...BASE_HEADER_OPTIONS };
    definitions.context = { timeScale };
    if (HIDDEN_COLUMNS.includes(field)) {
      definitions.hide = true;
      }
    if (forecastScenario.groupFields.includes(field)) {
      definitions.hide = true;
      definitions.rowGroup = true;
    }
    if(FACTS_HEADER === field) {
      definitions.rowGroup = true;
    }
    if(forecastScenario.periodHeadersNames.includes(field)) {
      const periodId = getPeriodId(periods, field);
      definitions.headerName = timePeriodNameParse(field);
      definitions.colId = periodId;
      definitions.cellRenderer = (params) =>
        <CustomPeriodCellRenderer params={params} forecastScenario={forecastScenario} />;
      definitions.aggFunc = 'totalAggFunc';
      definitions.filter = 'agNumberColumnFilter';
      definitions.width = 120;
      if(isEditableTimeScale && isFuturePeriod(forecastScenario, periodId)) {
        definitions.editable = (params) => isEditableCell(params, config, timeScale);
        definitions.type = ['editableColumn', 'styledPeriodColumn'];
      } else if(!isEditableTimeScale) {
        definitions.type = ['styledPeriodColumn'];
      }
    }
    if(forecastScenario.CAGRHistoricalHeader === field) {
      CAGR_YTD_YTG_col_def(definitions, 'CAGRHistoricalAggFunc');
    }
    if(forecastScenario.CAGRForecastedHeader === field) {
      CAGR_YTD_YTG_col_def(definitions, 'CAGRForecastedAggFunc');
    }
    if(forecastScenario.YTDHeader === field) {
      CAGR_YTD_YTG_col_def(definitions, 'YTDAggFunc');
    }
    if(forecastScenario.YTGHeader === field) {
      CAGR_YTD_YTG_col_def(definitions, 'YTGAggFunc');
    }
    return definitions;
  }
  )).flat()
}
