import {failedResponseHandler, isResponseFailed} from "../../helpers/store_helpers";
import {
  loadQuickVotesDataRequest,
  updateQuickVotesData
} from "../../utils/Api";
import EntryPoint from "../../EntryPoint";

export const loadQuickPollData = (callback = () => {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance
  loadQuickVotesDataRequest(objectSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

    const { data } = response;
    callback(true, data);
  })
}

export function updateQuickPoll(data, callback = () => {}) {
  return (dispatch) => {
    const { objectSlug } = EntryPoint.instance;
    updateQuickVotesData({ objectSlug, data }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, response, callback)

      const { data } = response;
      const { success, redirect } = data;
      callback(success, redirect);
    })
  }
}
