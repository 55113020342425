import React, {Fragment, useEffect, useMemo} from 'react';
import TreeHeader from "./header";
import Tree from "./accordion_tree/Tree";
import { loadDecisionTree } from "../store/tree/actions";
import { updateTreeData } from "../store/tree/common_actions";
import { connect } from "react-redux";
import DecisionDetailsPanel from "./side_panel/DecisionDetailsPanel";
import DiscussionPanel from "./side_panel/DiscussionPanel";
import DecisionInputLeftPanel from "./side_panel/DecisionInputRightPanel";
import DriverInputRightPanel from "./side_panel/DriverInputRightPanel";
import DataSourcesPanel from "./side_panel/DataSourcesPanel";
import RecommendationInputRightPanel from "./side_panel/RecommendationInputRightPanel";
import DecisionPlaybookPanel from "./side_panel/DecisionPlaybookPanel";
import TreePanel from "./side_panel/TreePanel";
import 'react-sliding-side-panel/lib/index.css';
import AlertsSection from "../alerts";
import HelpPanel from "./side_panel/HelpPanel";
import EntryPoint, { updateEntryPointData } from "../EntryPoint";
import { loadOrgCategories } from "../store/org_categories/actions";
import { loadOrgDriverTypes } from "../store/org_driver_types/actions";
import { Loader } from "../common/Loader";
import { getWindowDimensions, LARGE_SCREEN_WIDTH } from "../store";
import {
  closeDriverInputSidebarBySlug,
  setDecisionInputSidebarOpen,
  setDecisionOrderSidebarOpen,
  setDecisionSidebarOpen,
  setDiscussionPanelOpen,
  setDriverInputSidebarOpen,
  setFinalDecisionSidebarOpen,
  setRecommendationDetailsSidebarOpen,
  setTreeSidebarOpen, setLinkPanelOpen,
  setCollaboratorsSidebarOpen, setDataSourcesSidebarOpen, setMoreActionsSidebarOpen, resetSidebars
} from "../store/sidebar/actions";
import {
  openModal,
  closeAndResetDriverDetailsModal,
  closeModal,
  transferDataAndCloseModal
} from "../store/modals/actions";
import { updateTreeChannelData } from "../store/channels/actions"
import {isBlank, isPresent, successActions} from "../helpers/common";
import {driversToArray, TREE_MODES} from "../helpers/drivers_helpers";
import Decision from "../models/decision";
import Driver from '../models/driver';
import { useHistory } from "react-router";
import {isDSightDecision, isInFlightDecision} from "../helpers/home_decision_helpers";
import { SidebarTreeNavigation } from "./navigation";
import { initTreeChannelSubscription, isDriverInEditModeByOthers, } from "../helpers/channel_helpers";
import { TREE_CHANNEL_ACTIONS } from "../../../channels/tree_channel";
import DecisionSetPanel from "./side_panel/DecisionSetPanel";
import { loadDecisionSet } from "../store/decision_set/actions";
import { checkLoadingEffect, isStoreLoading } from "../helpers/callbacks_helpers";
import { loadContacts } from "../store/contacts/actions";
import LinkPanel from "./side_panel/link_panel/LinkPanel";
import StartDuplicateDecisionModal, { showStartDuplicateDecisionModal } from "./modals/StartDuplicateDecisionModal";
import RatingsAndWeightsPanel from "./side_panel/RatingsAndWeightsPanel";
import DriverAssignModal, { showDriverAssign } from "../tree_editor/modals/DriverAssignModal";
import RecommendationAssignModal, { showRecommendationAssign } from "../tree_view/modals/RecommendationAssignModal";
import DecisionAssignModal, { showDecisionAssign } from "../tree_view/modals/DecisionAssignModal";
import {
  ChatGptDriverSummarizePanel, DecisionChatGptSummarizePanel, RecommendationGptDriverSummarizePanel
} from "./side_panel/ChatGptDriverSummarizePanel";
import PollPanel from "./side_panel/PollPanel";
import {
  DecisionFilesSummarizePanel,
  FilesSummarizePanel,
  RecommendationFilesSummarizePanel
} from "./side_panel/FilesSummarizePanel";
import CollaboratorsPanel from "./side_panel/CollaboratorsPanel";
import CollaboratorsSection from "./side_panel/sections/CollaboratorsSection";
import DataSourcesSection, {dataSourcesMapStateToProps} from "./side_panel/sections/DataSourcesSection";
import MoreActionsPanel from "./side_panel/MoreActionsPanel";
import MoreActionsSection from "./side_panel/sections/MoreActionsSection";
import {isDecider, isDeciderReadOnly, isDecisionMember, isPublicVisitor, isVisitor} from "../helpers/user_helpers";
import {isGuest} from "../helpers/home_helpers";

const { width } = getWindowDimensions();

export const openDriverInputSidebarCallback = (tree_data, getParams, decision, current_user, setDriverInputSidebarOpen, history) => {
  const driverOpenSlug = getParams.get('driver_open')
  if (isPresent(driverOpenSlug)) {
    const driverData = driversToArray(tree_data.drivers).find((driverData) => driverData.driver.slug === driverOpenSlug)
    if(isPresent(driverData)) {
      if (new Driver(driverData.driver, driverData.driver_sources_slugs, {}, new Decision(decision)).isCompleted) {
        updateTreeData({ scroll_to_driver: driverOpenSlug });
      } else {
        if(!isDriverInEditModeByOthers(tree_data.channels, TREE_CHANNEL_ACTIONS.edit_driver_answer, driverOpenSlug, current_user))
          setTimeout(() => setDriverInputSidebarOpen(driverOpenSlug), 100)
      }
    }
    getParams.delete('driver_open');
    history.replace({ search: getParams.toString() });
    return;
  }
}

export const Sidebars = ({ showDecisionSet = false, hideMoreSection = true }) =>
  <Fragment>
    <div className="sidebars">
      <RatingsAndWeightsPanel key="ratings-and-weights-panel"/>
      <RecommendationInputRightPanel key="recommendation-input-side-panel"/>
      <DriverInputRightPanel key="driver-input-side-panel"/>
      <DecisionInputLeftPanel key="decision-input-side-panel"/>
      <DecisionDetailsPanel key="decision-details-side-panel"/>
      <CollaboratorsPanel key="collaborators-side-panel" >
        <CollaboratorsSection isDecision={true} classNames="px-3 pb-3" />
      </CollaboratorsPanel>
      <DataSourcesPanel key="data-sources-panel">
        <DataSourcesSection addClass="px-3 pb-3" isDecision={true}/>
      </DataSourcesPanel>
      <TreePanel key="tree-side-panel" />
      <DiscussionPanel key="discussion-side-panel"/>
      <DecisionPlaybookPanel key="playbook-side-panel" addClass="left-side-panel-position tree-page"/>
      <LinkPanel key="link-side-panel" />
      <PollPanel key="decision-poll-panel" />
      {
        showDecisionSet ?
          <DecisionSetPanel addClass="left-side-panel-position tree-page" isDecision={true} /> :
          null
      }
      <ChatGptDriverSummarizePanel key="chat-gpt-driver-summarize-panel" />
      <RecommendationGptDriverSummarizePanel key="chat-gpt-recommendation-summarize-panel" />
      <DecisionChatGptSummarizePanel key="chat-gpt-decision-summarize-panel" />
      <FilesSummarizePanel key="driver-files-summarize-panel" />
      <DecisionFilesSummarizePanel key="decision-files-summarize-panel" />
      <RecommendationFilesSummarizePanel key="recommendation-files-summarize-panel" />
      {
        !hideMoreSection && <MoreActionsPanel>
          <MoreActionsSection isDetailsPanel={true}/>
        </MoreActionsPanel>
      }
      <div className="help-side-panels">
        <HelpPanel/>
      </div>
    </div>
  </Fragment>

export const Wrapper = ({ children, modal, closeModal, transferDataAndCloseModal, ...opts }) =>
  <Fragment>
    <AlertsSection/>
    <TreeHeader {...opts}/>
    <div className="d-flex vertical-tree-container">
      <SidebarTreeNavigation {...opts} />
      {children}
    </div>
    <Sidebars {...opts} />
    <div className="modals">
      <DecisionAssignModal key={`decision-assign-modal-${modal.slug}`} show={showDecisionAssign(modal)} onClose={transferDataAndCloseModal} />
      <RecommendationAssignModal key={`recommendation-assign-modal-${modal.slug}`} show={showRecommendationAssign(modal)} onClose={transferDataAndCloseModal} />
      <DriverAssignModal key={`driver-assign-modal-${modal.slug}`} show={showDriverAssign(modal)} onClose={closeModal} />
      <StartDuplicateDecisionModal shown={showStartDuplicateDecisionModal(modal)} onClose={closeModal} />
    </div>
  </Fragment>

export const handleUserLanding = (decision, openFinalDecisionSideBar, openRecommendationDetailsSideBar, openDecisionDetailsSideBar) => {
  if( width >= LARGE_SCREEN_WIDTH) {
    const decisionObject = new Decision(decision);
    if (decisionObject.isDecisionRecorded) {
      openFinalDecisionSideBar();
    } else if(decisionObject.isEnteredRecommendation) {
      openRecommendationDetailsSideBar();
    } else if(isInFlightDecision(decisionObject)) {
      return false;
    } else {
      openDecisionDetailsSideBar();
    }
  }
};

export const loadDecisionTreeCallback = ({
                                           history,
                                           tree,
                                           current_user,
                                           loadDecisionTree,
                                           openFinalDecisionSideBar,
                                           openDecisionOrderSideBar,
                                           openDecisionDetailsSideBar,
                                           setTreeSidebarOpen,
                                           openDiscussionSideBar,
                                           openRecommendationDetailsSideBar,
                                           setDecisionInputSidebarOpen,
                                           setDriverInputSidebarOpen,
                                           openCollaboratorsSideBar,
                                           openDataSourcesSideBar,
                                           openMoreActionsSideBar,
                                           openModal,
                                           updateTreeChannelData,
                                           closeDriverInputSidebarBySlug,
                                           setLinkPanelOpen,
                                           closeAndResetDriverDetailsModal,
                                           updateTreeData,
                                           hideMoreSection = true
                                         }) => {
  useEffect(() => {
    if (tree.loaded || tree.loading) return;

    loadDecisionTree((success, decision = {}, tree_data = {}) => {
      if (success) {
        initTreeChannelSubscription(decision, current_user, updateTreeChannelData, closeDriverInputSidebarBySlug, closeAndResetDriverDetailsModal)
        updateTreeData({ locationPath: history.location.pathname })
        const getParams = new URLSearchParams(document.location.search)
        switch (getParams.get('side_bar_open')) {
          case 'decision':
            openFinalDecisionSideBar();
            break;
          case 'details':
            openDecisionDetailsSideBar();
            break;
          case 'discussion':
            openDiscussionSideBar();
            break;
          case 'decision_tree':
            setTreeSidebarOpen()
            break;
          case 'link_panel':
            setLinkPanelOpen()
            break;
          case 'collaborators':
            openCollaboratorsSideBar()
            break;
          case 'data_sources':
            openDataSourcesSideBar()
            break;
          case 'more_action':
            if (hideMoreSection) break;
            openMoreActionsSideBar()
            break;
        }
        switch (getParams.get('left_side_bar_open')) {
          case 'decision_order':
            openDecisionOrderSideBar();
            return;
          case 'decision':
            openFinalDecisionSideBar();
            break;
          case 'recommendation_details':
            openRecommendationDetailsSideBar();
            break;
        }
        switch (getParams.get('modal_open')) {
          case 'buy-in':
            openFinalDecisionSideBar();
            break;
          case 'decision':
            setTimeout(() => setDecisionInputSidebarOpen(), 100)
            getParams.delete('modal_open');
            history.replace({ search: getParams.toString() });
            break;
        }
        openDriverInputSidebarCallback(tree_data, getParams, decision, current_user, setDriverInputSidebarOpen, history)
        if (isPresent(getParams.get('decision_set'))) {
          openDecisionOrderSideBar();
          return;
        }
        if (isPresent(getParams.get('expand_poll_results'))) {
          updateTreeData({ expand_poll_results: true });
          getParams.delete('expand_poll_results');
          history.replace({ search: getParams.toString() });
          return;
        }
        const pollPanelOpen = getParams.get('left_side_bar_open');
        if (isBlank(getParams.get('side_bar_open')) && pollPanelOpen !== 'decision_poll_panel') {
          handleUserLanding(decision, openFinalDecisionSideBar, openRecommendationDetailsSideBar, openDecisionDetailsSideBar);
        }
      } else if(decision?.status === 403) {
        document.location.reload()
      }
    });
  }, [tree.loaded]);
}

export const loadTreeViewerEffects = ({
                                        match, tree, decision, current_user, contactsData, signIn, org_categories,
                                        org_driver_types, loadOrgCategories, loadOrgDriverTypes, loadContacts,
                                        updateTreeData, history, resetSidebars
                                      }) => {
  useEffect(() => {
    updateEntryPointData(match, 'decisions')
    if (tree.loaded && isPresent(match?.params?.id) && (decision.slug !== match?.params?.id || tree.locationPath.includes('assigned_drivers'))) {
      tree.locationPath.includes('assigned_drivers') && successActions(true, [resetSidebars]);
      updateTreeData({ loaded: false, locationPath: history.location.pathname })
    }
  }, [match?.params?.id, tree.loaded, decision, tree.locationPath])

  useEffect(() => {
    if (!org_categories.loaded && !org_categories.loading)
      loadOrgCategories({ decision_slug: EntryPoint.instance.objectSlug });
  }, [org_categories.loaded])
  checkLoadingEffect(contactsData, loadContacts)
  checkLoadingEffect(org_driver_types, loadOrgDriverTypes);
  useEffect(() => {
    if (tree.loaded && current_user.loaded) {
      if (!signIn.signedIn && !decision.sharable) document.location.href = '/';
    }
  }, [tree.loaded, current_user.loaded])
}

export const loadDecisionSetCallback = ({ tree, decision, decision_set, loadDecisionSet }) => {
  checkLoadingEffect(decision_set,
    () => loadDecisionSet({ scope: 'decision_sets', slug: decision.decision_set_slug, decision_slug: decision.slug }),
    {
      condition: ([tree]) => tree.loaded && isPresent(decision.decision_set_slug),
      another_stores: [tree]
    }
  )
}

const TreeView = ({
                    modal, tree, decision, match, closeModal, transferDataAndCloseModal, resetSidebars,
                    ...opts
                  }) => {
  const history = useHistory();
  loadTreeViewerEffects({ tree, decision, match, history, resetSidebars, ...opts })

  const hideMoreSection = useMemo(() =>
    isBlank(opts.current_user) ||
    !isDecider(opts.current_user) && (!isDecisionMember(decision, opts.current_user) || isDSightDecision(decision)) ||
    isPublicVisitor(opts.current_user) ||
    !isDecider(opts.current_user) && !isDeciderReadOnly(opts.current_user) && isGuest(opts.current_user) ||
    opts.current_org.slug !== opts.current_user.org_slug, [opts.current_user, opts.current_org]);

  loadDecisionTreeCallback({ history, tree, hideMoreSection, ...opts })

  useEffect(() => {
    if (isPresent(decision.recommendation)) {
      EntryPoint.instance.recommendationSlug = decision.recommendation.slug;
    }
  }, [decision])
  loadDecisionSetCallback({ tree, decision, ...opts })

  const hideDataSources = () => {
    return (isVisitor(opts.current_user) || isDeciderReadOnly(opts.current_user))
      && isBlank(dataSourcesMapStateToProps({tree, current_org: opts.current_org, current_user: opts.current_user, decision_set: opts.decision_set}).data_sources)
  }

  if (isStoreLoading(tree)) {
    return <Wrapper {...{ modal, closeModal, transferDataAndCloseModal, tree }} {...opts}>
      <Loader/>
    </Wrapper>;
  } else {
    return <Wrapper {...{ modal, closeModal, decision, tree, hideMoreSection, hideDataSources: hideDataSources(),
                          showDecisionSet: isPresent(decision.decision_set_slug), transferDataAndCloseModal }} {...opts}>
      <Tree />
    </Wrapper>;
  }
};
export const mapStateToProps = ({ modal, tree, org_categories, decision, current_user, signIn, org_driver_types, decision_set, contacts, current_org }) => ({
  modal, tree, org_categories, decision, current_user, signIn, org_driver_types, decision_set, contactsData: contacts, current_org
});
export const mapDispatchToProps = (dispatch) => ({
  loadDecisionSet: (data) => dispatch(loadDecisionSet(data)),
  loadDecisionTree: (callback) => { dispatch(loadDecisionTree(callback)); },
  updateTreeData: (data) => { dispatch(updateTreeData(data)); },
  loadOrgCategories: (data = {}) => {
    dispatch(loadOrgCategories(data));
  },
  openCollaboratorsSideBar: () => dispatch(setCollaboratorsSidebarOpen(true)),
  openMoreActionsSideBar: () => dispatch(setMoreActionsSidebarOpen(true)),
  openDataSourcesSideBar: () => dispatch(setDataSourcesSidebarOpen(true)),
  openDecisionOrderSideBar: () => dispatch(setDecisionOrderSidebarOpen(true)),
  openDecisionTreeSideBar: () => {
    dispatch(setTreeSidebarOpen(true, TREE_MODES.view));
  },
  openDecisionDetailsSideBar: () => {
    dispatch(setDecisionSidebarOpen(true));
  },
  setDecisionInputSidebarOpen: () => {
    dispatch(setDecisionInputSidebarOpen(true))
  },
  setDriverInputSidebarOpen: (driverSlug) => {
    dispatch(setDriverInputSidebarOpen(true, driverSlug))
  },
  openFinalDecisionSideBar: () => {
    dispatch(setFinalDecisionSidebarOpen(true));
  },
  openRecommendationDetailsSideBar: () => {
    dispatch(setRecommendationDetailsSidebarOpen(true));
  },
  openDiscussionSideBar: () => {
    dispatch(setDiscussionPanelOpen(true));
  },
  openLinkPanel: () => {
    dispatch(setLinkPanelOpen(true));
  },
  setTreeSidebarOpen: () => {
    dispatch(setTreeSidebarOpen(true, TREE_MODES.view));
  },
  openModal: (data = {}) => {
    dispatch(openModal(data));
  },
  loadOrgDriverTypes: () => {
    dispatch(loadOrgDriverTypes())
  },
  updateTreeChannelData: (data) => {
    dispatch(updateTreeChannelData(data))
  },
  closeDriverInputSidebarBySlug: (driverSlug) => {
    dispatch(closeDriverInputSidebarBySlug(driverSlug))
  },
  closeAndResetDriverDetailsModal: (driverSlug) => dispatch(closeAndResetDriverDetailsModal(driverSlug)),
  loadContacts: () => dispatch(loadContacts()),
  closeModal: () => { dispatch(closeModal()) },
  transferDataAndCloseModal: () => { dispatch(transferDataAndCloseModal()) },
  resetSidebars: () => dispatch(resetSidebars())
});

export default connect(mapStateToProps, mapDispatchToProps)(TreeView);
