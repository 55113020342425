import React, { useState, useEffect, useMemo } from "react";
import ReactDOM, { render } from "react-dom";
import { connect } from "react-redux";
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { agGridInit } from "../helpers/ag_grid_helpers";
import ActionsRow from "./components/ActionsRow";
import { allContacts } from "../../helpers/filter_helpers";
import { isStoreLoading } from "../../helpers/callbacks_helpers";
import { Loader } from "../../common/Loader";
import {
  runModel,
  updateScenario,
  updateScenarioData,
  updateOpenedGroups,
  updateTableCells
} from "../../store/forecast_simulator_scenario/actions";
import { setLargeScalePanelOpen } from "../../store/sidebar/actions";
import TablePaginationPanel from "../table_components/TablePaginationPanel";
import { setupShownCmuGroups, DEFAULT_TOTAL_PAGES } from "../helpers/ag_grid_setup_content";
import { getTablePageFromCookies, saveTablePageToCookies } from "../helpers/ag_grid_cookies";

const DEFAULT_PAGE = 0;

const calcPage = (forecastScenario) => {
  return getTablePageFromCookies(forecastScenario) ? parseInt(getTablePageFromCookies(forecastScenario)) : 0;
}

const ScenarioForecastData = ({
                                forecast_simulator_scenario, forecastScenario, forecastBenchmarkScenario,
                                contactsData, current_user, gridRef,
                                ...opts
                              }) => {
  const [runModelActive, setRunModelActive] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(calcPage(forecastScenario));
  const [currentCmuGroups, setCurrentCmuGroups] = useState([]);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);

  useEffect(() => {
    if(loading || needUpdate) {
      const { newCurrentCmuGroups, newTotalPages } = setupShownCmuGroups(forecastScenario, page, forecastScenario.config, loading);
      if(newCurrentCmuGroups !== currentCmuGroups) setCurrentCmuGroups(newCurrentCmuGroups);
      if(newTotalPages !== totalPages) setTotalPages(newTotalPages);
      if(newTotalPages === DEFAULT_TOTAL_PAGES) onChangePage(DEFAULT_PAGE, false);
      setNeedUpdate(false);
      setLoading(false);
    }
  }, [loading, needUpdate]);

  const { onRunModel, ...agGridOpts } = agGridInit({
    forecast_simulator_scenario,
    forecastScenario,
    forecastBenchmarkScenario,
    setRunModelActive,
    gridRef,
    page,
    setPage,
    currentCmuGroups,
    setNeedUpdate,
    ...opts
  })
  const allContactsData = useMemo(() => allContacts(contactsData, current_user), [contactsData.contacts, current_user]);
  const userEmail = forecast_simulator_scenario.scenario.data.attributes.user_email;
  const scenarioUser = allContactsData.find(i => i.email === userEmail) || { email: userEmail, value: userEmail  }

  const onChangePage = (newPage, allowUpdate = true) => {
    setPage(newPage);
    if(allowUpdate) setNeedUpdate(true);
    saveTablePageToCookies(newPage, forecastScenario);
  };

  useEffect(() => {
    const agRootWrapper = document.querySelector('.ag-root-wrapper');
    let paginationPanelWrapper = '';
    if (agRootWrapper) {
      paginationPanelWrapper = document.createElement('div');
      agRootWrapper.appendChild(paginationPanelWrapper);
      ReactDOM.render(<TablePaginationPanel page={page}
                                            onChangePage={onChangePage}
                                            totalPages={totalPages}
      />, paginationPanelWrapper);
    }
    return () => {
      if (paginationPanelWrapper) ReactDOM.unmountComponentAtNode(paginationPanelWrapper);
    };
 }, [page, totalPages]);

  if (isStoreLoading(forecast_simulator_scenario)) return <Loader />

  return <>
    <ActionsRow {...{ gridRef, forecastScenario, forecast_simulator_scenario, user: scenarioUser, runModelActive, onRunModel }} />
    <div className="row">
      <div className="col ag-theme-quartz ag-quartz-styles">
        <AgGridReact {...{...agGridOpts}} ref={gridRef} />
      </div>
    </div>
  </>
}

const mapStateToProps = ({forecast_simulator_scenario, contacts, current_user}) => ({
  forecast_simulator_scenario, contactsData: contacts, current_user
});
const mapDispatchToProps = (dispatch) => ({
  updateScenario: (scenario_id, updateData, callback) =>
    dispatch(updateScenario(scenario_id, updateData, callback, false)),
  runModel: (scenario_id, data, callback) => dispatch(runModel(scenario_id, data, callback)),
  updateScenarioData: (data) => dispatch(updateScenarioData(data)),
  setLargeScalePanelOpen: (open, selectedDriverId) => dispatch(setLargeScalePanelOpen(open, selectedDriverId)),
  updateOpenedGroups: (scenario_id, data, callback) => dispatch(updateOpenedGroups(scenario_id, data, callback)),
  updateTableCells: (scenario_id, data, callback) => dispatch(updateTableCells(scenario_id, data, callback))
});
export default connect(mapStateToProps, mapDispatchToProps)(ScenarioForecastData);
