import React, {useState} from 'react';
import Wrapper from '../Wrapper';
import AggregatedData from "../driver/AggregatedData";
import {driverInitData} from "../shared/helpers";
import {ArrowUpIcon} from "../../../icons/custom_icons";
import DriverStatus from "../driver/DriverStatus";
import ExpandedContent from "../driver/ExpandedContent";
import ContractedContent from "../driver/ContractedContent";
import DriverInfo from "../driver/DriverInfo";
import {assignedDriverUser, expandDriverCardCallbacks, onEditClickCallback} from "./DriverCard";
import {fetchContacts} from "../../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import Header from "../driver/Header";

const AssignedDriverCard = ({ driverData, tree, indentStep = 1, user, contactsData,
                              sidebar, shortBorderToArrow = false, channels, decisionObject,
                              setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData, decision
                            }) => {

  const { driverObject, childrenDrivers, setShowChildren, showChildren } = driverInitData(tree, driverData, decisionObject);
  const collaborators = fetchContacts({}, contactsData, true, true)
  const assignedUser = assignedDriverUser(collaborators, contactsData, driverObject)
  const slug = driverData.driver.slug;
  const [expanded, setExpanded] = useState(false);

  expandDriverCardCallbacks(sidebar, slug, setExpanded, expanded, driverObject);
  const onExpandDriverClick = () => setExpanded(!expanded)
  const onEditClick = () => onEditClickCallback(user, channels, slug, sidebar, decision, setDriverInputSidebarOpen, resetDriverInputSidebar, true, true)
  const onClickEntireCard = (e) => {
    if (driverObject.isCompleted && !expanded) {
      onExpandDriverClick();
      e.stopPropagation();
    }
    return e;
  }

  return <>
    <Wrapper indentStep={indentStep} onClick={onClickEntireCard} clickable={driverObject.isCompleted && !expanded}
             hasSingleChild={shortBorderToArrow} {...{expanded}}>
      <div className={`${expanded ? "d-block" : "d-flex"}`} id={`quick-driver-card-${slug}`}>
        {
          expanded ? <></> : <DriverStatus {...{driverObject, contactsData}}/>
        }
        <div className={`${expanded ? '' : 'd-flex flex-column '}my-auto lh-sm w-100 me-1`} style={{minHeight: 36}}>
          <Header {...{user, driverData, decision, driverObject, childrenDrivers, setShowChildren, onExpandDriverClick, onEditClick, expanded, allowVisitor: true}} />
          <DriverInfo {...{driverData, driverObject, decisionObject, user, hidden: expanded}} />
          <AggregatedData {...{driverData, hidden: expanded}} />
        </div>
        {
          expanded ?
            <ExpandedContent {...{driverData, onEditClick}} /> :
            <ContractedContent {...{driverData, driverObject, assignedUser, contactsData, sidebar}} />
        }
      </div>
      <div
        className={`arrow-up-icon-block ${showChildren && childrenDrivers.length > 0 ? 'd-block' : 'd-none'} ${expanded ? 'ml-expanded' : ''}`}>
        <ArrowUpIcon/>
      </div>
    </Wrapper>
    {
      showChildren && childrenDrivers.length > 0 ?
        <div className={`position-relative ml-border-${indentStep} ${shortBorderToArrow ? '' : 'border-to-arrow'}`}>
          {childrenDrivers.map((child, index) =>
            <AssignedDriverCard key={`driver-card-${child.driver.slug}`}
                                {...{
                          driverData: child,
                          indentStep: indentStep + 1,
                          decisionObject, user, collaborators, contactsData, sidebar, channels, tree,
                          setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData, decision,
                          shortBorderToArrow: childrenDrivers.length === 1 || index === childrenDrivers.length - 1
                        }} />
          )}
        </div> : null
    }
  </>
}

const mapStateToProps = ({channels, sidebar}) => ({
  channels, sidebar
});
export default AssignedDriverCard;