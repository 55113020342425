import Cookies from "js-cookie";
import { GROUP_COL_ID_SUFFIX, FACTS_GR_COL_ID } from "./ag_grid_vars";
import { aggregatedFact, refreshAggregations, getScenarioFiltersJson } from "./common";
import { isBlank } from "../../helpers/common";

const factsFilterChanged = (event) => event.columns[0]?.colId === FACTS_GR_COL_ID;
const cmuRootColumnFiltersChanged = (event, forecastScenario) => {
  return `${forecastScenario.config.rootCmuHeader.name}-${GROUP_COL_ID_SUFFIX}` === event.columns[0]?.colId;
};

const setAggregatedFact = (forecastScenario, forecast_simulator_scenario, filterModel) => {
  const prevAggregatedFact = forecast_simulator_scenario.ag_grid_aggregated_fact;
  const newAggregatedFact = aggregatedFact(forecastScenario.config, forecastScenario, filterModel);
  if(prevAggregatedFact?.id !== newAggregatedFact?.id) {
    return newAggregatedFact;
  } else {
    return prevAggregatedFact;
  }
};

const updateAggregatedFact = (event, forecastScenario, forecast_simulator_scenario, filterModel, updateScenarioData) => {
  const aggregatedFact = setAggregatedFact(forecastScenario, forecast_simulator_scenario, filterModel);
  forecastScenario.aggregatedFact = aggregatedFact;
  updateScenarioData({ ag_grid_aggregated_fact: aggregatedFact });
  refreshAggregations(event.api, forecastScenario);
};

export const saveFilters = (event, forecastScenario, forecast_simulator_scenario, updateScenarioData, setNeedUpdate) => {
  // We want to apply this function only when user clicked on filter selection
  if(event.source === 'api') return;

  const filterModel = event.api.getFilterModel();
  setScenarioFiltersToCookies(forecastScenario, filterModel);
  updateScenarioData({ table_fitlers_updated_at: Date.now() });
  if(factsFilterChanged(event)) {
    updateAggregatedFact(event, forecastScenario, forecast_simulator_scenario, filterModel, updateScenarioData);
  } else if(cmuRootColumnFiltersChanged(event, forecastScenario)) {
    setNeedUpdate(true);
  }
};

export const setScenarioFiltersToCookies = (forecastScenario, filterModel) => {
  Cookies.set(`forecast-scenario-filters-${forecastScenario.local_id}`, JSON.stringify(filterModel));
};

export const restoreFilters = (event, forecastScenario, forecast_simulator_scenario, updateScenarioData) => {
  const filterModelData = getScenarioFiltersJson(forecastScenario)
  if(filterModelData) {
    event.api.setFilterModel(filterModelData);
    updateAggregatedFact(event, forecastScenario, forecast_simulator_scenario, filterModelData, updateScenarioData);
  }
};

export const saveFiltersToCookie = (filterModel, forecastScenario) => {
  Cookies.set(`forecast-scenario-filters-${forecastScenario.local_id}`, JSON.stringify(filterModel));
};

export const clearForecastCookies = (forecastScenario) => {
  Cookies.remove(`forecast-scenario-filters-${forecastScenario?.local_id}`);
  Cookies.remove(`forecast-scenario-collapsed-groups-${forecastScenario?.local_id}`);
  Cookies.remove(`forecast-scenario-pinned-data-${forecastScenario?.local_id}`);
};

export const saveCollapsedGroups = (event, forecastScenario) => {
  let collapsedGroups = Cookies.get(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`);
  collapsedGroups = collapsedGroups ? JSON.parse(collapsedGroups) : [];

  if (!event.node.expanded) {
    collapsedGroups.push(event.node.id);
  } else {
    collapsedGroups = collapsedGroups.filter(id => id !== event.node.id);
  }
  Cookies.set(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`, JSON.stringify(collapsedGroups));
};

export const restoreCollapsedGroups = (event, forecastScenario) => {
  const collapsedGroups = Cookies.get(`forecast-scenario-collapsed-groups-${forecastScenario.local_id}`);
  if (!collapsedGroups) return;

  JSON.parse(collapsedGroups).forEach(id => {
    const node = event.api.getRowNode(id);
    if (node) node.setExpanded(false);
  });
};

export const saveColumnState = (event, forecastScenario) => {
  if(isBlank(event?.column?.colId)) return;

  let pinnedColumns = Cookies.get(`forecast-scenario-pinned-data-${forecastScenario.local_id}`);
  pinnedColumns = pinnedColumns ? JSON.parse(pinnedColumns) : {};

  pinnedColumns[event.column.colId] = event.pinned;
  Cookies.set(`forecast-scenario-pinned-data-${forecastScenario.local_id}`, JSON.stringify(pinnedColumns));
};

export const restoreColumnState = (event, forecastScenario) => {
  const columnState = Cookies.get(`forecast-scenario-pinned-data-${forecastScenario.local_id}`);
  if(columnState) {
    const parsedColumnState = JSON.parse(columnState);
    Object.keys(parsedColumnState).forEach(colId => {
      event.api.setColumnsPinned([colId], parsedColumnState[colId]);
    });
  } else {
    initPinnedColumns(event);
  }
};

export const restoreForecastCookies = (event, forecastScenario, forecast_simulator_scenario, updateScenarioData) => {
  restoreFilters(event, forecastScenario, forecast_simulator_scenario, updateScenarioData);
  restoreCollapsedGroups(event, forecastScenario);
  restoreColumnState(event, forecastScenario);
};

export const initPinnedColumns = (event) => {
  const columnState = event.api.getColumnState();
  columnState.forEach(column => {
    if(column.rowGroup) {
      event.api.setColumnsPinned([`${column.colId}-${GROUP_COL_ID_SUFFIX}`], 'left')
    }
  });
};

export const saveTableOrderToCookies = (event, forecastScenario) => {
  Cookies.set(`forecast-scenario-table-order-${forecastScenario.local_id}`, event);
};

export const getTableOrderFromCookies = (forecastScenario) => {
  return Cookies.get(`forecast-scenario-table-order-${forecastScenario.local_id}`);
};

export const saveTablePageToCookies = (page, forecastScenario) => {
  Cookies.set(`forecast-scenario-table-page-${forecastScenario.local_id}`, page);
};

export const getTablePageFromCookies = (forecastScenario) => {
  return Cookies.get(`forecast-scenario-table-page-${forecastScenario.local_id}`);
};
