import $ from "jquery";
import React, { useEffect, useMemo, useRef } from "react";
import {connect, Provider} from "react-redux";
import { render } from "react-dom";
import { useHistory } from "react-router-dom";
import { isBlank, isPresent } from "../../helpers/common";
import EntryPoint from "../../EntryPoint";
import ZTreeSectionNode from "./nodes/ZTreeSectionNode";
import ZTreeHoverSectionNode from "./nodes/ZTreeHoverSectionNode";
import ZTreeDecisionNode from "./nodes/ZTreeDecisionNode";
import ZTreeHoverDecisionNode from "./nodes/ZTreeHoverDecisionNode";
import { MOVE_TYPES } from "../wizard/ZTree";
import {
  beforeCollapseCallback,
  onUnmountZTree,
} from "../tree_view/ZTree";
import DecisionSet, { calcSequenceIndexByIndex, calcSequenceNumber } from "../../models/decision_set";
import TemplateSet from "../../models/template_set";
import {isDSightDecision} from "../../helpers/home_decision_helpers";
import Decision from "../../models/decision";
import { isTemplateDraft } from "../../helpers/template_helpers";
import { BASE_ZTREE_SETTING } from "../../template_set_view/side_panel/sections/TreeSection";
import {openModal} from "../../store/modals/actions";
import { fetchAssignedToCollaborator } from "../../helpers/decision_user_helpers";
import { isDraftDecision, isUnstartedDecision } from "../../models/decision_state";

export const DECISION_ZTREE_ID = 'decisionTree';
const panelContext = (isTemplateSet) =>
  `Drag and drop a decision title to change the display order. ${!isTemplateSet ? 'Removing a decision from the flow will not delete it.' : '' }`

const BASE_NODE_SETTINGS = {
  isRoot: false, node: {}, isTemplateSet: false, children: undefined, slug: '', isSectionNode: false, isDecisionNode: false,
  open: true, name: '', drag: false, drop: false, draft: false, assigned: false, assignedToUser: '', answered: false,
  iconSkin: 'non-answered', default_user_avatar_url: '', users: [], isDSight: false
}

const buildDataFromZTree = (zTreeNodes) => {
  return zTreeNodes.map((zTreeNode, index) => {
    let nodeData = {
      node: {
        slug: zTreeNode.slug,
        is_root: zTreeNode.isRoot,
        is_section: zTreeNode.isSectionNode,
        order: index,
        is_decision: zTreeNode.isDecisionNode
      },
      children: []
    }
    if(isPresent(zTreeNode.children)) {
      nodeData.children = buildDataFromZTree(zTreeNode.children);
    }
    return nodeData
  })
}

const onDropNode = (treeId, dragDecisionAction) => {
  const treeObj = $.fn.zTree.getZTreeObj(treeId);
  const newNodes = buildDataFromZTree(treeObj.getNodes()[0].children);
  dragDecisionAction({ nodes_data: newNodes });
}

const removeHoverDom = (treeId, treeNode, set, assignDecisions) => addDiyDom(treeId, treeNode, set, assignDecisions);

const highlightLastDecision = (treeNode, set, $sectionRow, addedDecisionSlug) => {
  if (treeNode.slug === addedDecisionSlug) {
    $sectionRow.addClass('bg-light').css('height', 'auto');
  }
}

const addDiyDom = (treeId, treeNode, set, assignDecisions, addedDecisionSlug) => {
  const $sectionRow = $(`#${treeNode.tId}_a`);
  treeNode.isDecisionNode && $sectionRow.addClass('prevent-inner-drag');
  treeNode.isDecisionNode ?
    render(<Provider store={EntryPoint.instance.store}>
      <ZTreeDecisionNode key={`ztree-decision-node-${treeNode.slug}`} {...{ treeNode, set, assignDecisions }} />
    </Provider>, $sectionRow[0]) :
    render(<Provider store={EntryPoint.instance.store}>
      <ZTreeSectionNode key={`ztree-section-node-${treeNode.slug}`} treeNode={treeNode} />
    </Provider>, $sectionRow[0]);

  highlightLastDecision(treeNode, set, $sectionRow, addedDecisionSlug);
}

const addHoverDom = (treeId, treeNode, isTemplateSet, history, assignDecisions, set) => {
  if(assignDecisions) return;

  const $sectionRow = $(`#${treeNode.tId}_a`);
  treeNode.isRoot || treeNode.isSectionNode ?
    render(<Provider store={EntryPoint.instance.store}>
      <ZTreeHoverSectionNode key={`ztree-hover-section-node-${treeNode.slug}`} { ...{ treeNode, isTemplateSet, history, set } } />
    </Provider>, $sectionRow[0]) :
    render(<Provider store={EntryPoint.instance.store}>
      <ZTreeHoverDecisionNode key={`ztree-hover-decision-node-${treeNode.slug}`} { ...{ treeNode, isTemplateSet, set } } />
    </Provider>, $sectionRow[0])
}

const preventDropCallback = (treeId, targetNode, moveType) => {
  return isBlank(targetNode) || isBlank(moveType) || (targetNode.isRoot && moveType === MOVE_TYPES.prev) ||
    (targetNode.isDecisionNode && moveType === MOVE_TYPES.inner);
}

const clickOnTreeNode = (treeNode, assignDecisions, openModal) => {
  if(!treeNode.isDecisionNode || !assignDecisions || treeNode.isDSight || treeNode.answered) return false;

  openModal({ decision: treeNode.node, slug: treeNode.slug, type: 'AssignDecisionModal' })
  return true;
}

const zTreeEditModeSettings = (set, isTemplateSet, dragDecisionAction, history, assignDecisions, openModal, addedDecisionSlug) => {
  return {
    edit: { enable: true, drag: { isCopy: true, isMove: true }, showRemoveBtn: false, showRenameBtn: false },
    view: {
      addDiyDom: (treeId, treeNode) => treeNode.isRoot ?
        addHoverDom(treeId, treeNode, isTemplateSet, history, assignDecisions, set) :
        addDiyDom(treeId, treeNode, set, assignDecisions, addedDecisionSlug),
      addHoverDom: (treeId, treeNode) => treeNode.isRoot === false && addHoverDom(treeId, treeNode, isTemplateSet, history, assignDecisions, set),
      removeHoverDom: (treeId, treeNode) => treeNode.isRoot === false && removeHoverDom(treeId, treeNode, set, assignDecisions),
    },
    callback: {
      beforeCollapse: (treeId, treeNode) => beforeCollapseCallback(treeId, treeNode, set),
      beforeClick: (treeId, treeNode) => clickOnTreeNode(treeNode, assignDecisions, openModal),
      beforeDrag: (treeId, treeNodes) => !treeNodes.some((node) => !node.drag),
      beforeDrop: (treeId, treeNodes, targetNode, moveType) => !preventDropCallback(treeId, targetNode, moveType),
      onDrop: (event, treeId, treeNodes, targetNode, moveType, isCopy) => {
        if(isBlank(moveType)) return false;

        onDropNode(treeId, dragDecisionAction);
        return true;
      }
    }
  }
}

const generateDecisionObject = ({ node = {},  default_user_avatar_url, isTemplateSet =  false}) => {
  const decisionObject = new Decision(node);
  const inFlight = !isTemplateSet && decisionObject.isInFlight;
  const totalCompletionPercentage = inFlight && decisionObject.totalCompletionPercentage
  const answered = !isTemplateSet && decisionObject.isCompleted;
  const assignedToUser = fetchAssignedToCollaborator(node, decisionObject);
  return {
    ...BASE_NODE_SETTINGS,
    node,
    default_user_avatar_url,
    isTemplateSet, answered,
    inFlight, totalCompletionPercentage,
    users: node.users,
    slug: node.slug, name: node.description, drag: true, drop: true,
    isDecisionNode: true,
    draft: isTemplateSet ? isTemplateDraft(node) : (isDraftDecision(node) && !isUnstartedDecision(node)),
    isDSight: isDSightDecision(node),
    assigned: isPresent(assignedToUser),
    iconSkin: inFlight ? 'in-flight' : (answered ? 'answered' : 'non-answered'),
    assignedToUser: assignedToUser,
    sequence_number: node.sequence_number
  };
}

const generateSectionObject = ({
                                 node = {},
                                 isTemplateSet =  false,
                                 children = []
                              }) => {
  const result = {
    ...BASE_NODE_SETTINGS, node, isTemplateSet, slug: node.slug, name: node.name, isSectionNode: true
  };

  if (children.length > 0)
    result['children'] = children.map((hash, index) =>
      generateDecisionObject({
        node: { ...hash, sequence_number: calcSequenceNumber(node, node.startIndex, index) },
        isTemplateSet
      })
    )

  return result;
}

const generateSetObject = ({
                             node = {},
                             default_user_avatar_url,
                             isTemplateSet =  false,
                             children = []
                           }) => {
  const result = {
    ...BASE_NODE_SETTINGS,
    node,
    isRoot: true,
    isTemplateSet,
    default_user_avatar_url,
    slug: node.slug,
    name: node.name
  };

  if (children.length > 0)
    result['children'] = children.map(hash =>
      hash.isDecisionNode ?
        generateDecisionObject({ node: { ...hash }, default_user_avatar_url, isTemplateSet }) :
        generateSectionObject({
          node: { ...hash },
          children: isTemplateSet ?
            hash.templates :
            hash.decisions.map(sub_hash => ({...sub_hash, ...node.decisions.find(d => d.slug === sub_hash.slug)}) ),
          isTemplateSet
        })
    )
  return result;
}

export const generateSetChildren = (set, isTemplateSet) => {
  const setObj = isTemplateSet ? (new TemplateSet(set)) : (new DecisionSet(set))
  const nodesWithoutSection = isTemplateSet ? setObj.templatesWithoutSection : setObj.decisionsWithoutSection;
  const decisionNodes = nodesWithoutSection.map((s, index) =>
    ({
      ...s,
      inFlight: new Decision(s).isInFlight,
      isDecisionNode: true, isSectionNode: false,
      sequence_number: calcSequenceNumber(set, 0, index)
    })
  );
  const sections = setObj.sections
  const sectionNodes = sections.map((s, index) =>
    ({
      ...s,
      isSectionNode: true, isDecisionNode: false,
      objects_sequence: set.objects_sequence,
      startIndex: calcSequenceIndexByIndex(sections, index, nodesWithoutSection)
    })
  );
  return [...decisionNodes, ...sectionNodes].flat()
}

const ZTree = ({
                 zTreeId = DECISION_ZTREE_ID,
                 set,
                 default_user_avatar_url,
                 isTemplateSet = false,
                 preview,
                 dragDecisionAction, assignDecisions = false, openModal
               }) => {
  const ref = useRef(null);
  const history = useHistory();
  const setObjects =  useMemo(() => isTemplateSet ? set.templates : set.decisions, [set, isTemplateSet]);
  const addedDecisionSlug =  useMemo(() => isTemplateSet ? set.added_template_slug : set.added_decision_slug, [set, isTemplateSet]);
  useEffect(() => {
    const stateSettings = zTreeEditModeSettings(set, isTemplateSet, dragDecisionAction, history, assignDecisions, openModal, addedDecisionSlug);
    const setting = { ...BASE_ZTREE_SETTING, ...stateSettings };
    const nodes = generateSetObject({
      ...BASE_NODE_SETTINGS,
      node: { ...set },
      children: generateSetChildren(set, isTemplateSet),
      default_user_avatar_url,
      isTemplateSet,
      isRoot: true
    });
    $.fn.zTree.init($(ref.current), setting, nodes);
    return () => onUnmountZTree(() => {}, zTreeId);
  }, [set, setObjects, assignDecisions])

  return <div>
    <div className="text-muted my-1" hidden={preview}>{panelContext(isTemplateSet)}</div>
    <div className={"tree-container decision-order"}>
      <ul id={zTreeId} className="ztree p-0" ref={ref} />
    </div>
  </div>
}
const mapStateToProps = ({ tree }) => ({ default_user_avatar_url: tree.default_user_avatar_url });
const mapDispatchToProps = (dispatch) => ({
  openModal: (data) => dispatch(openModal(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ZTree);
