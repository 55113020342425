import {isBlank, isPresent} from "./common";
import {COLLABORATION_TYPES, DRIVERS_INVITE_TYPE} from "./decision_helpers";

export function isDecider(user, previewMode = false) {
  if (previewMode) return false;

  return isPresent(user) && user['decision_role'] === 'decider';
}

export function collaboratorCanEditTree(user, decision, previewMode = false) {
  if (previewMode) return false;

  return user['decision_role'] === 'collaborator' && decision.allow_collaborators_edit_tree;
}

export function collaboratorCanEditCollaborators(user, decision, previewMode = false) {
  if (previewMode) return false;

  return  user['decision_role'] === 'collaborator' && decision.allow_collaborators_edit_collaborators;
}

export function userHasAdmissions(user, decision_admissions) {
  return decision_admissions.some(admission => admission.slug === user.slug);
}

export function isDecisionMember(decision, user) {
  if (isBlank(decision) || isBlank(user)) return false;

  return decision.users.find(u => u.email === user.user_email)?.length !== 0
}

export function isDeciderReadOnly(user, previewMode = false) {
  if (previewMode) return false;

  return isPresent(user) && user['decision_role'] === 'decider_read_only';
}

export function isCollaborator(user, previewMode = false) {
  if (previewMode) return false;

  return isPresent(user) && (user['decision_role'] === 'decider' || user['decision_role'] === 'collaborator'
    || user['decision_role'] ==='collaborator_tree_editor');
}

export function hasParticipantAdmission(user, previewMode = false) {
  if (previewMode) return false;

  return isPresent(user) && (user['decision_role'] === 'participant_admission');
}

export function isVisitor(user, previewMode = false) {
  if (previewMode) return true;

  return isBlank(user) || user['decision_role'] === 'visitor';
}

export function isOrgAdmin(user) {
  return isPresent(user) || current_user['decision_role'] === 'admin';
}

export function isPublicVisitor(user, previewMode = false) {
  if (previewMode) return true;

  return isBlank(user) || isBlank(user.email);
}

export function showPointer(isVisitor = true, answered = false) {
  if (!isVisitor) return 'pointer'

  return answered ? 'pointer' : ''
}

export const userName = (user, email = '') => {
  const name = user?.full_name || user?.email || email;
  const status = user?.hasOwnProperty('active') && !user.active ? ' (removed)' : ''
  return `${name}${status}`;
}

export const userInitials = (user) => user?.full_name?.split(' ')?.map(w => w[0]?.toUpperCase( ))?.join('')

export const totalUsersVotes = (usersChoices = []) => usersChoices.reduce((total, choice) => total + choice?.votes?.filter(vote => vote?.voted).length, 0);

export const isExistingCollaborator = (value, invites, manager) =>
  invites.some((collaborator) => collaborator.email === value && COLLABORATION_TYPES.includes(collaborator.type)) || manager.email === value;

export const userAlreadyAddedAsAssignee = (email, driversData) => driversData.some(driverData => isPresent(email) && driverData.driver.assign_to_user === email)

export const userAlreadyAddedAsResponder = (email, driversData) => driversData.some(driverData => isPresent(email) && driverData.driver.responder_email === email)