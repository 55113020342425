import { isBlank } from "../../helpers/common";
import { N_A_AG_GRID_VALUE } from "./ag_grid_vars";

export const validatedResult = (result) => {
  return isNaN(result) || !isFinite(result) || isBlank(result) ? N_A_AG_GRID_VALUE : result;
};

export const calcSimVsValue = (simRoot, compareRoot) => {
  return (simRoot - compareRoot) / compareRoot * 100;
};

export const calcYARootFromPrevSimVsYa = (prevRootValue, prevSimVsYaValue) => {
  return prevRootValue / (1 + parseFloat(prevSimVsYaValue) / 100);
};

export const calcRootFromSimVs = (rootValue, simVs) => {
  return rootValue + rootValue * (parseFloat(simVs) / 100);
};

export const calcRootBySimVsRootValue = (rootValue, simVsRootValue) => {
  return (parseFloat(rootValue) - simVsRootValue) / simVsRootValue * 100
};

export const calcCAGR = (endingValue, startingValue, periodNumbers) => {
  return (Math.pow(endingValue / startingValue, 1 / (periodNumbers-1)) - 1) * 100;
};

export const calcYTD_YTG = (periodsData, periodsDataToCompare) => {
  const periodsDataSum = periodsData.reduce((acc, value) => acc + parseFloat(value), 0);
  const periodsDataToCompareSum = periodsDataToCompare.reduce((acc, value) => acc + parseFloat(value), 0);

  return (periodsDataSum/periodsDataToCompareSum - 1) * 100;
};
