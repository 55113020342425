import { arrayLastElement, isPresent, qSortArray } from "../../helpers/common";
import { ForecastBaseModel } from "./Base";
import * as moment from "moment";

export const TIME_SCALES_KEYS = {
  year: 'y',
  quarter: 'q',
  month: 'm',
  week: 'w',
  period: 'p'
}

export const TIME_SCALE_NEXT_PERIODS_LIMIT = {
  y: false,
  q: 3,
  m: 11,
  p: 12,
}

export const timePeriodNameParse = (name) => {
  const splitNameArray = name.split('_');
  if (splitNameArray.length !== 2) return name;

  return splitNameArray.reverse().join(' ');
};

export const genYearAgoPeriod = ({ period, allowBlank = false }) => {
  const existedPeriod = period.timeScale.timePeriods.find(p =>
    period.parsedPeriod === p.parsedPeriod && (period.parsedYear-1) === p.parsedYear
  )
  if (isPresent(existedPeriod) || allowBlank) return existedPeriod;

  const customPeriod = new ForecastTimePeriod({
    id: 'custom-year-ago',
    attributes: {
      start_date: period.startDate.subtract(1, 'year').format('YYYY-MM-DD'),
      end_date: period.endDate.subtract(1, 'year').format('YYYY-MM-DD'),
      name: period.startDate.subtract(1, 'year').format('YYYY')
    }
  })
  customPeriod.timeScale = period.timeScale
  return customPeriod;
}

export const generateCustomPeriod = ({ fromPeriod, toPeriod, timeScale, namePeriod = null }) => {
  const customPeriod = new ForecastTimePeriod({
    id: 'custom',
    attributes: {
      start_date: fromPeriod.start_date,
      end_date: toPeriod.end_date,
      name: timeScale.isYear ? (namePeriod || fromPeriod).name : `${fromPeriod.optionLabel} - ${toPeriod.optionLabel}`
    }
  })
  customPeriod.timeScale = timeScale
  return customPeriod;
}

export class ForecastTimePeriod extends ForecastBaseModel {

  isWithinTimePeriod(date) {
    return moment(date) >= this.startDate && moment(date) <= this.endDate
  }

  get name() {
    return this.attributes.name;
  }
  get timeScaleId() {
    return parseInt(this.attributes.ts_id);
  }
  get startDate() {
    return moment(this.start_date);
  }
  get start_date() {
    return this.attributes.start_date
  }
  get endDate() {
    return moment(this.end_date);
  }
  get end_date() {
    return this.attributes.end_date;
  }
  get timeScaleKey() {
    return this.timeScale?.key;
  }
  get optionLabel() {
    return timePeriodNameParse(this.name)
  }

  get parsedYear() {
    const nameParts = this.name.split('_')
    if (this.timeScale.isYear) return parseInt(nameParts[0]);

    return parseInt(arrayLastElement(nameParts));
  }

  get parsedPeriod() {
    const nameParts = this.name.split('_')
    if (this.timeScale.isYear) return 0;

    return nameParts[0];
  }
}

export class ForecastTimeScale extends ForecastBaseModel {

  fillPeriods(periods) {
    this.timePeriods = qSortArray(
      periods.filter(period => period.timeScaleId === this.id),
      true, (period) => period.startDate
    )
    this.timePeriods.forEach(period => period.timeScale = this);
  }

  get isYear() {
    return this.key === TIME_SCALES_KEYS.year;
  }
  get isQuarter() {
    return this.key === TIME_SCALES_KEYS.quarter;
  }

  get isMonth() {
    return this.key === TIME_SCALES_KEYS.month;
  }

  get nextOptionsLimit() {
    return TIME_SCALE_NEXT_PERIODS_LIMIT[this.key] || false;
  }

  get fromPeriod() {
    return this.timePeriods[0];
  }

  get toPeriod() {
    return this.timePeriods[this.timePeriods.length - 1];
  }

  get displayName() {
    return this.attributes.display_name;
  }
  get key() {
    return this.attributes.config_key;
  }
  get isVisible() {
    return isPresent(this.attributes.visible);;
  }

  get isInitData() {
    return isPresent(this.attributes.config_loaded);
  }

  get isUsedForDecomp() {
    return isPresent(this.attributes.decomposition_display);
  }
}
