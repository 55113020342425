import {
  ASSIGNED_DRIVERS_LOAD_DATA,
  ASSIGNED_DRIVERS_LOAD_DATA_STARTED,
  ASSIGNED_DRIVERS_DATA_FAILED,
  RESET_ASSIGNED_DRIVERS_DATA,
  UPDATE_ASSIGNED_DRIVERS_DATA
} from "./types";

const initialState = {
  loading: false,
  loaded: false,
  error: '',
  drivers: [],
  decision: {},
  assign_user_email: '',
};

export function assignedDriversReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ASSIGNED_DRIVERS_LOAD_DATA: {
      return {
        ...state,
        ...action.payload,
        error: '',
        loading: false
      };
    }
    case ASSIGNED_DRIVERS_LOAD_DATA_STARTED: {
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    }
    case ASSIGNED_DRIVERS_DATA_FAILED: {
      return {
        ...state,
        ...action.payload,
        loading: false
      }
    }
    case RESET_ASSIGNED_DRIVERS_DATA: {
      return {
        ...state,
        ...initialState
      }
    }
    case UPDATE_ASSIGNED_DRIVERS_DATA: {
      return {
        ...state,
        ...action.payload
      }
    }
    default:
      return state;
  }
}
