import React from 'react';
import { connect } from "react-redux";
import { openModal, closeModal, transferDataAndCloseModal, updateTemplate } from "../../store/modals/actions";
import {treeInitData} from "./shared/helpers";
import AssignedDriverCard from "./cards/AssignedDriverCard";
import {setDriverInputSidebarOpen} from "../../store/sidebar/actions";
import {resetDriverInputSidebar} from "../../store/sidebar/common_actions";
import {updateTreeData} from "../../store/tree/common_actions";
import {isLeftSidebarOpen} from "../../helpers/sidebar_helpers";

const AssignedDriversTree = ({ tree, decision, user, contactsData, openModal, sidebar,
                            setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData,
                            channels
                         }) => {
  const { rootDrivers, decisionObject } = treeInitData(tree, decision)

  return <div className="container-fluid p-0">
    <div className={`px-2 px-md-0 my-3 ${isLeftSidebarOpen(sidebar) ? 'mx-auto mx-md-3 vertical-assigned-drivers-tree-grow' : 'mx-auto'} vertical-tree`}>
      <h1>Drivers</h1>
      {
        rootDrivers.map((driverData, index) =>
          <AssignedDriverCard
            key={`template-driver-card-${driverData.driver.slug}`}
            {...{
              driverData, tree, contactsData, user, decision, openModal, decisionObject,
              shortBorderToArrow: rootDrivers.length === 1 || index === rootDrivers.length - 1,
              setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData, channels, sidebar
            }} />
        )
      }
    </div>
    <div className="modals">
    </div>
  </div>
}
const mapStateToProps = ({tree, decision, current_user, contacts, channels, sidebar}) => ({
  tree, decision,
  user: current_user,
  contactsData: contacts,
  channels, sidebar
});
export default connect(mapStateToProps, {
  closeModal, updateTemplate, transferDataAndCloseModal, openModal,
  setDriverInputSidebarOpen, resetDriverInputSidebar, updateTreeData
})(AssignedDriversTree);