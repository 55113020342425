import { isDSightDecision } from "../../../helpers/home_decision_helpers";
import { TABS_URLS } from "../../../helpers/home_helpers";
import { isDraftDecision } from "../../../models/decision_state";

const isSearchSection = (section) => section === 'search';
const isDSightHistorySection = (section) => section === 'dsight_history'
const isDecisionSetSection = (section) => section === 'decision_sets'

export const tileSectionsFlags = (section) => {
  const isSearch = isSearchSection(section)
  const isDSightHistory = isDSightHistorySection(section)
  const isInDecisionSet = isDecisionSetSection(section)

  return {
    isSearch, isDSightHistory, isInDecisionSet
  }
}

export const decisionLink = (decision, hasAccess = true, isInDecisionSet = false) => {
  if (isDraftDecision(decision)) return `/decisions/${decision.slug}/tree_wizard`;
  if (!hasAccess) return `/homepage/${TABS_URLS.decisions_center}?request_access_decision_slug=${decision.slug}`;
  if (isDSightDecision(decision)) return `/dsight/decisions/${decision.slug}/tree_viewer`;
  if (decision?.assigned_drivers_available) return `/decisions/${decision.slug}/assigned_drivers`

  return `/decisions/${decision.slug}/tree_viewer${isInDecisionSet ? '?decision_set' : ''}`;
}
