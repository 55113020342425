import { driversToArray } from "../helpers/drivers_helpers";
import { isBlank, isPresent, pluralize, qSortArray, uniqArray, uniqueBy } from "../helpers/common";
import {
  collectCollaborationInvites,
  collectUserSupports, collectUserSupportsLegacy,
  getLastFollowup, getLastLegacyFollowup,
  getLastPendingFollowup,
  getScheduledFollowup
} from "../helpers/decision_helpers";
import * as moment from "moment";
import Driver from './driver';
import Recommendation from './recommendation';
import {Type, DecisionDataSourcesTypes} from "./data_source";
import {
  sortChoicesByRankPoints,
  sortPickedChoices,
  sortRankedChoices
} from "./decision_choices";
import { sortChoicesByRating } from "./DriverChoiceRating";
import { flow, MS_TEAMS_FLOWS, SLACK_FLOWS } from "../helpers/decision_flows";
import DecisionState from "./decision_state";
import {isLegacySlackMsTeamsFlow} from "../helpers/home_helpers";
import {RESPONSE_TYPE} from "../helpers/discussion_helpers";

export const DESCRIPTION_INPUT_LIMIT = 100;

export const ChoiceEntryType = window.TreeLayoutConstants.decision.choice_entry_types;

export const ResponseTypes = [
  { value: ChoiceEntryType.OPEN_ENDED, label: 'Open-ended' },
  { value: ChoiceEntryType.TOP_CHOICE, label: 'Pick top choice' },
  { value: ChoiceEntryType.PICKY, label: 'Pick all that apply' },
  { value: ChoiceEntryType.RANK_LIST, label: 'Rank a list' },
];

export const CHOICE_ENTRY_TYPE_DATA = {
  open_ended: 'Open-ended',
  top_choice: 'Pick a choice.',
  picky: 'Pick all that apply.',
  rank_list: 'Select and rank all that apply. Click to select, drag to rank.'
};

export const LEGACY_CHOICE_ENTRY_TYPE_DATA = {
  top_choice: 'Pick top choice',
  picky: 'Pick all that apply',
  rank_list: 'Rank a list',
  brainstorm: 'Brainstorm for ideas',
  questionnaire: 'Questionnaire'
};

export const FOLLOWUP_EXPECTATIONS = [
  { description: 'Much Worse', number: 1, value: 'Much Worse', className: 'mush-worse', formatLabel: 'Much worse than expected', learnChartOrder: 5 },
  { description: 'Worse', number: 2, value: 'Worse', className: 'worse', formatLabel: 'Worse than expected', learnChartOrder: 4 },
  { description: 'As expected', number: 3, value: 'On Track', className: 'on-track', formatLabel: 'As expected', learnChartOrder: 3 },
  { description: 'Better', number: 4, value: 'Better', className: 'better', formatLabel: 'Better than expected', learnChartOrder: 2 },
  { description: 'Much Better', number: 5, value: 'Much Better', className: 'mush-better', formatLabel: 'Much better than expected', learnChartOrder: 1 }
];

export const LEGACY_FOLLOWUP_EXPECTATIONS = [
  { description: 'Much Worse', number: 1, value: 'Much Worse', className: 'mush-worse', formatLabel: 'Much worse than expected', learnChartOrder: 5 },
  { description: 'Worse', number: 2, value: 'Worse', className: 'worse', formatLabel: 'Worse than expected', learnChartOrder: 4 },
  { description: 'Slightly Worse', number: 2.5, value: 'Slightly Worse', className: 'worse', formatLabel: 'Slightly worse than expected', learnChartOrder: 3.5 },
  { description: 'As expected', number: 3, value: 'On Track', className: 'on-track', formatLabel: 'As expected', learnChartOrder: 3 },
  { description: 'Slightly Better', number: 3.5, value: 'Slightly Better', className: 'better', formatLabel: 'Slightly better than expected', learnChartOrder: 2.5 },
  { description: 'Better', number: 4, value: 'Better', className: 'better', formatLabel: 'Better than expected', learnChartOrder: 2 },
  { description: 'Much Better', number: 5, value: 'Much Better', className: 'mush-better', formatLabel: 'Much better than expected', learnChartOrder: 1 }
];

export const CATEGORIES = window.TreeLayoutConstants.decision.categories;

export const SUPPORT_VALUES = [
  { description: '1', number: 1, value: 1, className: 'not-at-all-confident' },
  { description: '2', number: 2, value: 2, className: 'not-so-confident' },
  { description: '3', number: 3, value: 3, className: 'somewhat-confident' },
  { description: '4', number: 4, value: 4, className: 'very-confident' },
  { description: '5', number: 5, value: 5, className: 'extremely-confident' }
]

export const LEGACY_SUPPORT_VALUES = [
  { description: '0', number: 0, value: 0, className: 'not-at-all-confident' },
  { description: '1', number: 1, value: 1, className: 'not-at-all-confident' },
  { description: '2', number: 2, value: 2, className: 'not-at-all-confident' },
  { description: '3', number: 3, value: 3, className: 'not-so-confident' },
  { description: '4', number: 4, value: 4, className: 'not-so-confident' },
  { description: '5', number: 5, value: 5, className: 'somewhat-confident' },
  { description: '6', number: 6, value: 6, className: 'somewhat-confident' },
  { description: '7', number: 7, value: 7, className: 'very-confident' },
  { description: '8', number: 8, value: 8, className: 'very-confident' },
  { description: '9', number: 9, value: 9, className: 'extremely-confident' },
  { description: '10', number: 10, value: 10, className: 'extremely-confident' }
]

export const decisionSelectedCategories = (org_categories, selectedCategories) =>
  org_categories.available_categories.filter(category =>
    (selectedCategories || []).some(h => category.slug === h.slug)
  )

export const decisionDataSources = (allDataSources, allDrivers, types = [...DecisionDataSourcesTypes]) => {
  const driversData = isPresent(allDrivers) ? driversToArray(allDrivers) : [];
  const driverDataSourcesSlugs = uniqArray(driversData.map(driverData => driverData.driver_sources_slugs).flat());
  const relatedDataSources = allDataSources.filter((source) => types.includes(source.type) || driverDataSourcesSlugs.includes(source.slug));

  return qSortArray(relatedDataSources, true,(o) => moment(o.created_at));
}

export const linkedReportDataSources = (dataSources) => {
  return dataSources.filter((source) => [Type.LINKED_REPORT_DATA_SOURCE, Type.LINKED_REPORT_TEMPLATE_DATA_SOURCE].includes(source.type));
};

export const linkedForecastScenarioDataSources = (dataSources) => {
  return dataSources.filter((source) => [Type.LINKED_FORECAST_SCENARIO_DATA_SOURCE].includes(source.type));
};

const RECOMMENDATION_FLOWS = [flow.TREE_RECOMMENDATION, flow.D_SIGHT_RECOMMENDATION]

export default class Decision {
  constructor(decision, drivers = []) {
    this.decision = decision;
    this.isLegacy = isLegacySlackMsTeamsFlow(decision);
    this.drivers = drivers;
    this.recommendation = new Recommendation(decision?.recommendation);
    this.keyDrivers = this.actualDriversObjs.filter(d => d.isKeyDriver);
    this.decisionStateObj = new DecisionState(this);
  }

  get cloneObj() {
    return new Decision({...this.decision}, this.drivers.map(h => ({...h})))
  }
  get isSlackFlows() {
    return SLACK_FLOWS.includes(this.decision.flow_enum);
  }
  get isMsTeamsFlows() {
    return MS_TEAMS_FLOWS.includes(this.decision.flow_enum);
  }
  get isRecommendationFlow() {
    return RECOMMENDATION_FLOWS.includes(this.decision.flow_enum);
  }
  get isReadOnly() {
    return this.decision.read_only;
  }
  // Decision choice type
  get isFinalDecisionRanked() {
    return this.decision.final_decision_ranked;
  }
  get isRankedList() {
    return this.decision.choice_entry_widget_type === ChoiceEntryType.RANK_LIST;
  }
  get isPicky() {
    return this.decision.choice_entry_widget_type === ChoiceEntryType.PICKY;
  }
  get isTopChoice() {
    return this.decision.choice_entry_widget_type === ChoiceEntryType.TOP_CHOICE;
  }
  get isOpenEnded() {
    return this.decision.choice_entry_widget_type === ChoiceEntryType.OPEN_ENDED;
  }
  get isQuestionnaire() {
    return this.decision.choice_entry_widget_type === ChoiceEntryType.QUESTIONNAIRE;
  }
  get isPoll() {
    return isPresent(this.decision.poll_due_date);
  }

  get pollType() {
    switch (this.decision.choice_entry_widget_type) {
      case ChoiceEntryType.TOP_CHOICE:
        return LEGACY_CHOICE_ENTRY_TYPE_DATA.top_choice;
      case ChoiceEntryType.RANK_LIST:
        return LEGACY_CHOICE_ENTRY_TYPE_DATA.rank_list;
      case ChoiceEntryType.PICKY:
        return LEGACY_CHOICE_ENTRY_TYPE_DATA.picky;
      case ChoiceEntryType.BRAINSTORM:
        return LEGACY_CHOICE_ENTRY_TYPE_DATA.brainstorm;
      case ChoiceEntryType.QUESTIONNAIRE:
        return LEGACY_CHOICE_ENTRY_TYPE_DATA.questionnaire;
      default:
        return '';
    }
  }

  get isRateAndCompareDriverChoicesHidden() {
    const isOpenEnded = this.isTreeRecommendation ?
      this.recommendation.isOpenEnded :
      this.isOpenEnded

    return isOpenEnded || isBlank(this.decision.rate_compare_choices)
  }

  assignee(contactsData = [], prefer_decision_collaborator = false) {
    if (prefer_decision_collaborator) {
      return this.collaborators.find(c => this.assignedDecisionCollaboratorUser === c.email) ||
        contactsData.find(contact => this.assignedDecisionCollaboratorUser === contact.email)
    }

    return this.collaborators.find(c => this.assignedCollaboratorUser === c.email) ||
      contactsData.find(contact => this.assignedCollaboratorUser === contact.email)
  }

  get assignedCollaboratorUser() {
    if (isPresent(this.recommendation.assignedCollaboratorUser) && this.isTreeRecommendation)
      return this.recommendation.assignedCollaboratorUser;
    if (this.filteredChoices().length > 0 && isPresent(this.decision.assigned_collaborator_email))
      return this.decision.assigned_collaborator_email;

    return this.decision.assigned_collaborator_email || this.recommendation.assignedCollaboratorUser;
  }

  get assignedDecisionCollaboratorUser() {
    if (this.filteredChoices().length > 0 && isPresent(this.decision.assigned_collaborator_email))
      return this.decision.assigned_collaborator_email;

    return this.decision.assigned_collaborator_email;
  }

  // Decision choices
  get decisionChoices() {
    return (this.isLegacy ? this.decision?.choices : this.decision?.choices?.filter(c => isBlank(c.hide_in_choices_section))) || [];
  }
  get hideDecisionChoices() {
    return this.decisionChoices.length === 0 || isBlank(this.decision?.choices?.filter(c => isBlank(c.hide_in_choices_section))) || false;
  }
  get openEndedChoice() {
    return this.decision?.choices?.filter(c => c.open_ended) || [];
  }
  filteredChoices(filterOpenEnded = false) {
    return this.decisionChoices.filter((choice) => !filterOpenEnded ? true : !choice.open_ended);
  }
  sortedChoices(filterOpenEnded = false) {
    if(this.isRankedList) {
      return this.filteredChoices(filterOpenEnded).sort(sortRankedChoices);
    } else {
      return this.filteredChoices(filterOpenEnded).sort(sortChoicesByRankPoints);
    }
  }
  sortedChoicesOrRecommendations(filterOpenEnded = false) {
    return this.sortedChoices(filterOpenEnded).length > 0 ? this.sortedChoices(filterOpenEnded) : this.sortedRecommendationChoices(filterOpenEnded)
  }
  get sortedRatedChoices() {
    return this.isRateAndCompareDriverChoicesHidden ?
      this.sortedChoicesOrRecommendations(true) :
      sortChoicesByRating(this.choicesOrRecommendations(true), this.keyDrivers, {
        postSort: this.choiceDriverSortCallback(true)
      })
  }

  choicesOrRecommendations(filterOpenEnded = false) {
    return this.isTreeRecommendation ? this.recommendationChoices(filterOpenEnded) : this.filteredChoices(filterOpenEnded)
  }
  choiceSortCallback(filterOpenEnded = false) {
    if (this.filteredChoices(filterOpenEnded).length > 0) {
      return this.isRankedList ?  sortRankedChoices : sortPickedChoices
    } else {
      return this.recommendation.sortCallback
    }
  }
  choiceDriverSortCallback(filterOpenEnded = false) {
    if (this.filteredChoices(filterOpenEnded).length > 0) {
      return sortChoicesByRankPoints
    } else {
      return this.recommendation.sortDriverCallback
    }
  }
  get finalDecisions() {
    if (!this.isLegacy && !this.isDecisionRecorded) return [];

    return this.sortedChoices().filter(c => isPresent(c.final_decision))
  }
  get otherChoices() {
    if (!this.isDecisionRecorded) return [];

    return this.sortedChoices().filter(c => isBlank(c.final_decision) && !c.open_ended)
  }

  // Decision methods
  get collaborators() {
    return [this.decision.user, ...collectCollaborationInvites(this.decision).map(i => i.user)].filter(u => isPresent(u))
  }

  // Approval methods
  findApprovalForUser(user) {
    return this.decision.user_approvals.find(s => s.user_email === user?.email) || {}
  }
  get isWithApprovalInvites() {
    const canceled_approvals = this.decision.user_approvals.filter(u_a => u_a.canceled)
    let filtered_invites = this.decision.invites.filter(invite => !canceled_approvals.some(i => i.invite_id === invite.id))

    if (this.isLegacy)
      filtered_invites = filtered_invites.filter(invite => isBlank(invite.is_decision_user))

    return filtered_invites.some(invite => invite.type === 'ApprovalInvite')
  }
  get isWithApproval() {
    return this.decision.user_approvals.some(u_a => !u_a.canceled)
  }

  // User support methods
  findSupportForUser(user) {
    return this.decision.user_supports.find(s => s.user_email === user?.email) || {}
  }
  get isDisplayBuyIn() {
    return this.isLegacy ?
      this.userSupportsLegacy.length > 0 :
      isPresent(this.decision.rate_support) && this.userSupports.length > 0;
  }
  get userSupports() {
    return uniqueBy(collectUserSupports(this.decision), 'user_email');
  }
  get userSupportsLegacy() {
    return uniqueBy(collectUserSupportsLegacy(this.decision), 'user_email');
  }
  get sortedUserSupportsLegacy() {
    return qSortArray(this.userSupportsLegacy, true, (s) => s.created_at)
  }
  get sortedUserSupports() {
    return qSortArray(this.userSupports, true, (s) => s.created_at)
  }
  get supportWithComments() {
    if (this.isLegacy) return qSortArray(this.sortedUserSupportsLegacy.filter(s => isPresent(s.support_comment)), true, s => s.commented_at)
    return qSortArray(this.sortedUserSupports.filter(s => isPresent(s.support_comment)), true, s => s.commented_at)
  }
  get avgSupport() {
    const userSupports = this.userSupports;
    if (userSupports.length < 1) return 0.0;
    if (userSupports.length === 1) return parseFloat(userSupports[0].support.toFixed(1));

    const sum = userSupports.map(a => a.support).reduce((a, b) => a + b)
    return parseFloat((sum / userSupports.length).toFixed(1)) ;
  }
  get supportRatingsText() {
    const userSupports = this.isLegacy ? this.userSupportsLegacy : this.userSupports
    const supportWithComments = this.supportWithComments
    if (isPresent(supportWithComments)) {
      return [pluralize(userSupports.length, 'rating'), pluralize(supportWithComments.length, 'comment')].join(', ')
    } else {
      return pluralize(userSupports.length, 'rating')
    }
  }

  get recommendationAssignedUser() {
    return this.decision.users.find(u => u.email === this.decision?.recommendation?.assigned_collaborator_email)
  }

  get decisionAssignedUser() {
    return this.decision.users.find(u => u.email === this.decision.assigned_collaborator_email)
  }

  get hideDetailsSection() {
    return this.finalDecisions.length < 1 &&
    !this.isDecisionRecorded &&
    !this.isDisplayBuyIn &&
    isBlank(this.decision.final_decision_reasons) &&
    isBlank(this.decision.expected_results) &&
    isBlank(this.decision.rate_support)
  }

  // Decision drivers
  get answeringProgress() {
    if (this.isRecordedOrRecommended) return 0;

    return (this.answeringProgressValue * 100).toFixed(0);
  }
  get answeringProgressValue() {
    if (isBlank(this.driversArray)) return 0;
    if (isBlank(this.answeredDrivers)) return 0;

    return this.answeredDrivers.length / this.actualDriversArray.length
  }
  get totalCompletionPercentage() {
    if (isBlank(this.decision.drivers?.total_drivers) || this.decision.drivers.total_drivers < 1) return false;

    return (parseFloat(this.decision.drivers?.tree_completion) * 100).toFixed(0);
  }
  get driversArray() {
    return driversToArray(this.drivers);
  }
  get actualDriversArray() {
    return this.driversArray.filter(driverData => isPresent(driverData.driver.question))
  }
  get actualDriversObjs() {
    return this.actualDriversArray.map(d =>  new Driver(d.driver, [], {}, this))
  }
  get answeredDrivers() {
    return this.actualDriversArray.filter(driverData => (new Driver(driverData.driver, driverData.driver_sources_slugs, {}, this).isCompleted))
  }
  get answeredDriversWithConfidence() {
    return this.answeredDrivers.filter((driverData) => isPresent(driverData.driver.confidence))
  }
  get displayConfidence() {
    return this.answeredDriversWithConfidence.length >= 2;
  }
  get avgConfidenceLevel() {
    const sum = this.answeredDriversWithConfidence.reduce((sum, driverData) => sum + driverData.driver.confidence, 0);
    return Number((sum / this.answeredDriversWithConfidence.length).toFixed(1))
  }
  get anyAnsweredDriver() {
    return this.answeredDrivers.length > 0;
  }
  get isDecisionRecorded() {
    return isPresent(this.decision.decided_at)
  }
  get showAsCompleted() {
    return this.isTreeRecommendation && this.decision.show_as_completed && this.isEnteredRecommendation && !this.isDecisionRecorded
  }
  recommendationChoices(filterOpenEnded = false) {
    if(!this.withRecommendation) return [];

    return this.recommendation.filteredChoices(filterOpenEnded)
  }
  sortedRecommendationChoices(filterOpenEnded = false) {
    if(!this.withRecommendation) return [];

    return this.recommendation.sortedChoices(filterOpenEnded)
  }
  get withRecommendation() {
    return isPresent(this.decision.recommendation?.slug)
  }
  get isEnteredRecommendation() {
    if(!this.withRecommendation) return false;

    return isPresent(this.recommendation.isEnteredRecommendation)
  }
  get isDisplayResults() {
    if (this.isDecisionWithResults && (this.isDecisionRecorded || this.isEnteredRecommendation))
      return true;

    return false;
  }
  get isOpenEndedRecommendation() {
    if(!this.withRecommendation) return false;

    return this.recommendation.isOpenEnded
  }
  get isTreeRecommendation() {
    return this.decision.flow_enum === flow.TREE_RECOMMENDATION
  }
  get isDSightTreeRecommendation() {
    return this.decision.flow_enum === flow.D_SIGHT_RECOMMENDATION
  }
  get isTreeHistorical() {
    return this.decision.flow_enum === flow.TREE_HISTORICAL
  }
  get isRecordedOrRecommended() {
    return this.isDecisionRecorded || this.isEnteredRecommendation
  }
  get isRecordedOrShowAsCompleted() {
    if(this.decision.isSectionNode || this.decision.template_set_name) return;

    return this.isDecisionRecorded || this.showAsCompleted || this.isDisplayResults
  }
  // Decision results
  get isDecisionWithResults() {
    return this.isLegacy ?
      isPresent(this.lastFollowup.followed_up_at || this.lastFollowup.created_at) && isPresent(this.lastFollowup.followup_expectation) :
      isPresent(this.lastFollowup.followed_up_at) && isPresent(this.lastFollowup.followup_expectation)
  }
  get scheduledFollowup() {
    return getScheduledFollowup(this.decision) || getLastPendingFollowup(this.decision)  || {}
  }
  get lastFollowup() {
    if (this.isLegacy) return getLastLegacyFollowup(this.decision) || {};

    return getLastFollowup(this.decision) || {}
  }
  get followupChoice() {
    const { follow_up_choices } = this.decision;
    if (isBlank(follow_up_choices)) return null;
    if (isBlank(this.lastFollowup?.choice_slug)) return null;

    return follow_up_choices.find(c => c.slug === this.lastFollowup?.choice_slug);
  }
  get expectationData() {
    return FOLLOWUP_EXPECTATIONS.find(data => data.value === this.lastFollowup.followup_expectation)
  }

  get expectedOpportunity() {
    return this.decision.expected_opportunity || this.decision.recommendation?.expected_opportunity
  }

  get expectedInvestment() {
    return this.decision.expected_investment || this.decision.recommendation?.expected_investment
  }

  get expectedOpportunityPresent() {
    return isPresent(this.expectedOpportunity) && Number(this.expectedOpportunity) > 0
  }

  get expectedInvestmentPresent() {
    return isPresent(this.expectedInvestment) && Number(this.expectedInvestment) > 0
  }

  get isOverdue() {
    if(isBlank(this.decision.due_date)) return false;

    return moment(this.decision.due_date).isSameOrBefore(moment())
  }

  get statusTooltipText() {
    if(this.isCompleted) return `Entered by ${this.decision.deciding_user?.full_name || this.decision.recommendation?.user?.full_name}`;
    if(this.isUnstarted) return 'Unstarted';
    if(this.isInFlight) return 'In-flight';

    return ''
  }

  // These functions are related to Decision State
  get state() {
    return this.decisionStateObj.state;
  }

  get isCompleted() {
    return this.decisionStateObj.isCompleted;
  }

  get isInFlight() {
    return this.decisionStateObj.isInFlight;
  }

  get isUnstarted() {
    return this.decisionStateObj.isUnstarted;
  }

  get votesFeedbacks() {
    return this.decision.feedbacks.filter(f => f.commentable_type === RESPONSE_TYPE);
  }
}
