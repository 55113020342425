import React, { Fragment, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { WIZARD_STEPS, wizardStepDataBy, isLastStep } from "../../../helpers/wizard_helpers";
import {loadWizardAiFailure, saveWizardStepData, updateWizardData} from "../../../store/wizard/actions";
import { Button } from "react-bootstrap";
import DecisionTitleRow from "../../helpers/DecisionTitleRow";
import { JoditTextEditor } from "../../../common/JoditTextEditor";
import { ChoiceRow } from "../../../template_wizard/steps_wizard/steps/FramingDecisionStep";
import { isBlank, isPresent } from "../../../helpers/common";
import { DEFAULT_CHOICES, onChangeChoiceCallback, onPasteChoiceCallback } from "./FramingDecisionStep";
import { saveUploadingSources } from "../../../store/tree/actions";
import SubmitStepButton from "./SubmitStepButton";
import { submitTreeStep } from "../../helpers/decision_wizard_helpers";
import { DataSourcesList } from "../../../template_view/side_panel/sections/DataSourcesSection";
import {aiDecisionUploaderRequest, vectorStoreUploadFilesRequest} from "../../../store/decision/actions";
import Decision from "../../../models/decision";
import {Loader} from "../../../common/Loader";
import Image from "react-bootstrap/Image";
import {
  ErrorImageWrapper,
  RequestAccessWrapper
} from "../../../homepage/RequestAccess";
import oopsImage from "../../../../../../assets/images/Oops_290x222.png";

const OOPS_IMG_WIDTH = '145px';
const OOPS_IMG_HEIGHT = '111px';

const aiDecisionUploaderCallback = (aiDecisionUploaderRequest, setLoaded, updateWizardData, setLoading,
                                    vectorStoreUploadFilesRequest, setLoadedVs, decisionSlug) => {
  setLoadedVs(false)
  updateWizardData({ loadingAiData: true, errorAi: '' });

  vectorStoreUploadFilesRequest({decision_uploader: true}, (success) => {
    setLoadedVs(true)
    setLoaded(false)

    if (success) {
      aiDecisionUploaderRequest(() => {
        updateWizardData({ loadingAiData: false });
        setLoaded(true);
      });
    }
    setLoading(false);
  }, decisionSlug)
};

const UploaderSpinner = ({loaded, loadedVs}) =>
  <RequestAccessWrapper>
    <h1 className='text-primary text-center'>Loading...</h1>
    {!loadedVs && <p className='text-center px-4'>CloverpopAI is preparing the uploaded document.</p>}
    {!loaded && loadedVs && <p className='text-center px-4'>CloverpopAI is preparing the decision for your review.</p>}
    <div className='py-3'>
      <Loader marginTop='mt-0'/>
    </div>
  </RequestAccessWrapper>

const Error = ({ error, setLoading }) =>
  <RequestAccessWrapper>
    <div className="header text-center">
      <h1>Oops!</h1>
    </div>
    <p className="text-center">
      Something went wrong. <span className='text-primary pointer' onClick={() => setLoading(true)}>Click here</span> to try again.
    </p>
    <ErrorImageWrapper>
      <Image src={oopsImage} alt={error} width={OOPS_IMG_WIDTH} height={OOPS_IMG_HEIGHT}/>
    </ErrorImageWrapper>
  </RequestAccessWrapper>

const DecisionUploaderStep = ({
                                wizard, decision, stepRef, tree, saveWizardStepData, updateWizardData,
                                current_user, aiDecisionUploaderRequest, vectorStoreUploadFilesRequest
                              }) => {
  const decisionObj = new Decision(decision)
  const stepData = wizardStepDataBy(wizard, WIZARD_STEPS.decision_uploader.key);
  const [submitState, setSubmitState] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loadedVs, setLoadedVs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(decision.description);
  const [final_decision, setFinalDecision] = useState(stepData?.choices?.final_decision || '');
  const [rationale, setRationale] = useState(stepData?.rationale); // final_decision_reasons
  const defaultConsideredChoices = () =>
    isBlank(stepData?.considered_choices) ? DEFAULT_CHOICES : stepData?.considered_choices
  const [considered_choices, setConsideredChoices] = useState(defaultConsideredChoices());
  const [next_steps, setNextSteps] = useState(stepData?.next_steps || '');
  const [expected_results, setExpectedResults] = useState(stepData?.expected_results || '');
  const [recommendation_choice, setRecommendationChoice] = useState(stepData?.choices?.recommendation_choice || '');
  const [collaboration_context, setCollaborationContext] = useState(decision.collaboration_context || '');
  const [newChoice, setNewChoice] = useState(false);

  const onChangeChoice = onChangeChoiceCallback(considered_choices, setConsideredChoices);
  const onPasteChoice = onPasteChoiceCallback(considered_choices, setConsideredChoices, setNewChoice, onChangeChoice);

  const aiDecisionUploader = () => aiDecisionUploaderCallback(
    aiDecisionUploaderRequest, setLoaded, updateWizardData, setLoading,
    vectorStoreUploadFilesRequest, setLoadedVs, decision.slug
  );
  const removeChoice = (index) => {
    $(`#decisionChoice-${index}`).removeClass('d-flex').addClass('d-none');
    onChangeChoice('', index);
  };
  const addNewChoice = () => {
    setNewChoice(true);
    setConsideredChoices([...considered_choices, '']);
  };

  const disabledStep = () => {
    if (isBlank(description)) return true;
    if (!decisionObj.isRecommendationFlow && isBlank(final_decision)) return true;
    if (decisionObj.isRecommendationFlow && isBlank(recommendation_choice)) return true;

    return isPresent(wizard.error);
  };

  const submitStep = (nav_data = {}, callback = () => {}) => {
    setSubmitState(true);

    saveWizardStepData(WIZARD_STEPS.decision_uploader.key, {
      complete: isLastStep(wizard),
      ...nav_data,
      response: { step_index: wizard.step_index },
      final_decision,
      recommendation_choice,
      considered_choices,
      rationale,
      expected_results,
      next_steps,
      decision: {
        description,
        collaboration_context
      },
      collaborators: stepData?.collaborators || [],
      next_step: true
    }, callback);
  };

  stepRef.current.submitStep = (additional_data = {}, callback = () => {}) => {
    if (disabledStep()) additional_data.finish_later = true

    submitStep(additional_data, (success, wizardData) => {
      setSubmitState(false)
      callback(success, additional_data.finish_later, wizardData)
    })
  };

  useEffect(() => {
    updateWizardData({ disabledSubmit: disabledStep() });
  }, [description, final_decision, wizard.error, recommendation_choice]);

  useEffect(() => {
    if (isBlank(wizard.chat_gpt_queries)) return aiDecisionUploader();

    setLoaded(true);
    setLoadedVs(true);
  }, [loading]);

  useEffect(() => {
    setDescription(decision.description);
    setFinalDecision(stepData?.choices?.final_decision);
    setRationale(stepData?.rationale);
    setConsideredChoices(defaultConsideredChoices());
    setNextSteps(stepData?.next_steps);
    setExpectedResults(stepData?.expected_results);
    setRecommendationChoice(stepData?.choices?.recommendation_choice);
    setCollaborationContext(decision.collaboration_context);
  }, [stepData, decision.description, decision.collaboration_context, loading]);

  if (isPresent(wizard.errorAi)) return <Error error={wizard.errorAi} setLoading={setLoading} />;
  if (!loaded || !loadedVs) return <UploaderSpinner {...{loaded, loadedVs}} />

  return (
    <Fragment>
      <div className="d-flex">
        <div className="mx-auto overflow-auto">
          <div className="bg-white rounded p-3 mt-3 mb-3 mb-xl-5 historical-tree-wizard d-inline-block">
            <h1>
              <span className="text-primary">Almost there! </span>
              Review the CloverpopAI summary.
            </h1>

            <div className="w-100 mb-3">
              <h3>Source</h3>
              <DataSourcesList hideMenu={true} data_sources={tree.data_sources} user={current_user}/>
            </div>

            <DecisionTitleRow {...{description, setDescription, submit: wizard.submit || submitState}} />

            <div className="w-100 mb-3">
              <h3>Context</h3>
              <JoditTextEditor
                className="jodit-container-default"
                value={collaboration_context}
                placeholder="Explain the reasons for this decision"
                setValue={setCollaborationContext}
                autofocus={false}
              />
            </div>

            {decisionObj.isRecommendationFlow ?
              <div className="w-100 mb-3">
                <h3>What was recommended</h3>
                <JoditTextEditor
                  className="jodit-container-default"
                  value={recommendation_choice}
                  placeholder="Enter the recommendation"
                  setValue={setRecommendationChoice}
                  hasOnChangeAction={true}
                  autofocus={false}
                />
              </div> :
              <div className="w-100 mb-3">
                <h3>What was decided</h3>
                <JoditTextEditor
                  className="jodit-container-default"
                  value={final_decision}
                  placeholder="Enter the decision and resulting actions"
                  setValue={setFinalDecision}
                  hasOnChangeAction={true}
                  autofocus={false}
                />
              </div>
            }

            <div className="w-100 mb-3">
              <h3>Rationale</h3>
              <JoditTextEditor
                className="jodit-container-default"
                value={rationale}
                placeholder="Enter a detailed explanation"
                setValue={setRationale}
                autofocus={false}
              />
            </div>

            <div className="w-100 mb-3">
              <h3>Other choices considered</h3>
              {considered_choices.map((description, index) => (
                <ChoiceRow
                  key={`decision-choice-${index}`}
                  {...{
                    wizard,
                    index,
                    description,
                    submitState,
                    newChoice,
                    setNewChoice,
                    onChangeChoice,
                    removeChoice,
                    onPasteChoice
                  }}
                />
              ))}
              <Button onClick={addNewChoice} disabled={submitState} className="btn-secondary w-100">
                Add another choice
              </Button>
            </div>

            <div className="w-100 mb-3">
              <h3>Next steps</h3>
              <JoditTextEditor
                className="jodit-container-default"
                value={next_steps}
                placeholder="Enter any actions to take"
                setValue={setNextSteps}
                autofocus={false}
              />
            </div>

            <div className="w-100 mb-3">
              <h3>Expected results</h3>
              <JoditTextEditor
                className="jodit-container-default"
                value={expected_results}
                placeholder="Describe what is expected to happen"
                setValue={setExpectedResults}
                hasOnChangeAction={true}
                autofocus={false}
              />
            </div>

            <div className="w-100">
              <SubmitStepButton
                onClick={() => submitTreeStep(wizard, stepRef)}
                disabled={wizard.submit || submitState || disabledStep()}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({wizard, decision, tree, current_user}) => ({wizard, decision, tree, current_user});
const mapDispatchToProps = (dispatch) => ({
  saveWizardStepData: (step, data, callback) => dispatch(saveWizardStepData(step, data, callback)),
  updateWizardData: (data) => dispatch(updateWizardData(data)),
  saveUploadingSources: (sources) => dispatch(saveUploadingSources(sources)),
  aiDecisionUploaderRequest: (callback) => dispatch(aiDecisionUploaderRequest(callback)),
  vectorStoreUploadFilesRequest: (data, callback, decisionSlug) => dispatch(vectorStoreUploadFilesRequest(data, callback, decisionSlug, loadWizardAiFailure))
});

const wrapper = React.forwardRef((props, ref) => <DecisionUploaderStep {...props} stepRef={ref} />);
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(wrapper);