import { useEffect } from "react";
import { ForecastScenario } from "../../models/forecast/ForecastScenario";
import { hideOverlay, MESSAGES, showOverlayWithMessage } from "./custom_loading_overlay";
import { updateCells, relatedCell } from "./common";
import { isPresent } from "../../helpers/common";

const indexedRows = (rows) => {
  const indexedRows = new Map();
  rows.forEach(row => {
    const key = `${row.cmus}-${row.timePeriod.id}-${row.selectedDriver.id}`;
    indexedRows.set(key, row);
  });
  return indexedRows;
}

const initImportedScenario = (forecast_simulator_scenario, forecastScenario) => {
  const { import_values_new_rows, imported_scenario_id } = forecast_simulator_scenario;
  return new ForecastScenario({
    id: imported_scenario_id,
    data: {},
    view_options: forecastScenario.viewOptions,
    rows: import_values_new_rows
  }, forecastScenario.config, forecastScenario.editableTimeScaleKey);
}

const updateTableData = (gridRef, importedScenario, forecastScenario, list, editedCells, delayedUpdateData) => {
  const idxRows = indexedRows(importedScenario.rows);
  forecastScenario.rows.forEach(row => {
    const period = row.timePeriod;
    const key = `${row.cmus}-${row.timePeriod.id}-${row.selectedDriver.id}`;
    const importedRow = idxRows.get(key);
    if (importedRow) {
      const prevDriverData = row.fetchDriverData({ id: row.selectedDriver.id });
      const newDriverData = importedRow.fetchDriverData({ id: importedRow.selectedDriver.id })
      const relatedEditedCell = relatedCell(editedCells, row.cmusDriverId, period.id);
      const actualPrevValue = relatedEditedCell?.value || prevDriverData.base;
      if (newDriverData.base !== actualPrevValue) {
        addToList(list, row, period, newDriverData.base, actualPrevValue);
        if(forecastScenario.isEditableTimeScale)
          delayedUpdateData.push({ node: gridRef.current.api.getRowNode(row.cmusDriverId), key: period.name, value: parseFloat(newDriverData.base) })
      }
    }
  });
};

const addToList = (list, row, period, value, prevValue) => {
  list.push({
    node: { id: row.cmusDriverId, prevValue: parseFloat(prevValue) },
    colDef: { field: period.name, colId: period.id },
    newValue: parseFloat(value)
  });
};

export const applyImportedValues = ({ gridRef, forecast_simulator_scenario, editedCells, updateTableCells, forecastScenario, runModelCells }, callback) => {
  const importedScenario = initImportedScenario(forecast_simulator_scenario, forecastScenario);
  let list = [];
  let delayedUpdateData = [];
  updateTableData(gridRef, importedScenario, forecastScenario, list, editedCells, delayedUpdateData);
  if(isPresent(list)) {
    updateCells(gridRef, editedCells, forecastScenario, runModelCells, list, importedScenario.timeScale, updateTableCells, callback, delayedUpdateData);
  } else {
    hideOverlay(gridRef.current.api);
    callback();
  }
}

export const useImportValuesEffect = ({ gridRef, forecast_simulator_scenario, updateScenarioData, ...opts }) => {
  useEffect(() => {
    if(forecast_simulator_scenario.import_values) {
      showOverlayWithMessage(gridRef.current?.api, updateScenarioData, MESSAGES.updating_scenario);
      setTimeout(() => {
        applyImportedValues({
            gridRef,
            forecast_simulator_scenario,
            ...opts
          },
          () => updateScenarioData({ import_values: false, import_values_new_rows: [], imported_scenario_id: null }));
      }, 0)
    }
  }, [forecast_simulator_scenario.import_values])
}
