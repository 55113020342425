import {failedResponseHandler, isResponseFailed} from "../../helpers/store_helpers";
import {
  loadAssignedDriversDataRequest
} from "../../utils/Api";
import EntryPoint from "../../EntryPoint";
import {
  ASSIGNED_DRIVERS_DATA_FAILED,
  ASSIGNED_DRIVERS_LOAD_DATA,
  ASSIGNED_DRIVERS_LOAD_DATA_STARTED,
  RESET_ASSIGNED_DRIVERS_DATA, UPDATE_ASSIGNED_DRIVERS_DATA
} from "./types";
import {isPresent} from "../../helpers/common";
import {updateDecisionData} from "../decision/common_actions";
import {loadTreeSuccess} from "../tree/common_actions";
import {saveContactsData} from "../contacts/actions";
import {updateUserData} from "../current_user/actions";
import {updateTreeChannelData} from "../channels/actions";
import {updateOrgData} from "../current_org/actions";
import {saveDecisionAdmissionsData} from "../decision_admissions/actions";

export const loadAssignedDriversData = (callback = () => {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  dispatch(loadAssignedDriversStarted({ loaded: false, loading: true }));

  loadAssignedDriversDataRequest(objectSlug).then((response) => {
    const { data } = response;

    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadAssignedDriversFailure }, callback);

    const { decision, drivers, contacts, current_user, channels, data_sources, org, active_logo, decision_admissions } = data;
    const {...decisionData } = decision

    if (isPresent(contacts)) dispatch(saveContactsData({ contacts, loaded: true, loading: false, invite_yourself: true }));
    if (isPresent(current_user)) dispatch(updateUserData({ ...current_user, loaded: true, loading: false }));
    dispatch(updateTreeChannelData(channels.tree));
    dispatch(updateDecisionData({ ...decision }));
    dispatch(loadAssignedDriversSuccess({ ...data, loaded: true, loading: false }));
    dispatch(loadTreeSuccess({ drivers, data_sources, loaded: true, loading: false }));
    dispatch(updateOrgData({ ...org }))
    dispatch(saveDecisionAdmissionsData({ decision_admissions, loaded: true, loading: false }));

    callback(true, decisionData, { ...current_user }, active_logo, { ...data });
  })
}

export const loadAssignedDriversSuccess = (data) => ({
  type: ASSIGNED_DRIVERS_LOAD_DATA,
  payload: {
    ...data
  }
});
export const loadAssignedDriversStarted = (query = {}) => ({
  type: ASSIGNED_DRIVERS_LOAD_DATA_STARTED,
  payload: {
    ...query
  }
});
export const loadAssignedDriversFailure = error => ({
  type: ASSIGNED_DRIVERS_DATA_FAILED,
  payload: {
    error
  }
});

export const updateAssignedDriversData = (data) => ({
  type: UPDATE_ASSIGNED_DRIVERS_DATA,
  payload: {
    ...data
  }
});

export const resetAssignedDriversData = () => ({
  type: RESET_ASSIGNED_DRIVERS_DATA
});
