import React from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

export const BaseHamburgerItem = ({ title = '', children, ...opts }) =>
  <Dropdown.Item {...opts} >
    {children || title}
  </Dropdown.Item>

const HamburgerBtn = ({
                        as = 'button', onSelect, hidden = false,
                        ...opts
                      }) =>
  !hidden && <BaseHamburgerItem as={as} onClick={onSelect} {...opts} />

export const BaseDropdownBtn = ({ hidden = false, bsPrefix = '', onSelect, ...opts }) =>
  !hidden && <BaseHamburgerItem onClick={onSelect} bsPrefix={`dropdown-item ${bsPrefix}`} {...opts} />

export const BaseHamburgerLink = ({ to, ...opts }) =>
  <BaseHamburgerItem as={Link} to={to} {...opts} />

export const DeleteItemElement = ({ name, disabled = false }) => <span className={disabled ? 'text-gray' : "text-danger"}>Delete {name}</span>
export const DangerItemElement = ({ title }) => <span className="text-danger">{title}</span>

export default HamburgerBtn
