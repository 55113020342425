import React, {useRef, useState, useMemo, useEffect} from 'react';
import { Counter } from "../../../tree_view/side_panel/driver_input/DriverRichTextInput";
import { stripHtmlTags } from "../../../helpers/common";
import { DESCRIPTION_INPUT_LIMIT } from "../../../models/decision";
import AutoGrowTextArea from "../../../common/AutoGrowTextArea";

export default ({
                  isDSight, question,
                  setQuestionValue, onChangeQuestion = () => {},
                  showTitle = true, onKeyDown = () => {},
                  ...opts
                }) => {
  if (isDSight) return null;

  const [showChar, setShowChar] = useState(false);
  const inputElement = useRef(null);

  const isExceedLimit = useMemo(() => stripHtmlTags(question).length > DESCRIPTION_INPUT_LIMIT, [question])

  useEffect(() => {
    if (inputElement.current) {
      setTimeout(() => {
        inputElement.current.focus();
      }, 100)
    }
  }, []);

  return <div className={`${showTitle ? 'mb-3' : 'w-100'} driver-question`}>
    {showTitle && <h3>Driver</h3>}

    <Counter {...{ isAnswerField: true, showChar, value: question, isExceedLimit }} />
    <AutoGrowTextArea
      className={`form-control ${showChar ? 'primary-box-shadow' : ''} no-resize`}
      placeholder="Enter a question or title"
      value={question} setValue={setQuestionValue}
      onKeyDown={onKeyDown} ref={inputElement}
      onBlur={() => {onChangeQuestion(); setShowChar(false);}}
      onFocus={() => setShowChar(true)}
      onChange={(e) => setQuestionValue(e.target.value.toString())}
      {...opts}
    />
  </div>
};
