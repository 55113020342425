import { RESET_DECISION_SET_DATA, UPDATE_DECISION_SET_DATA } from "./types";

const initialState = {
  loaded: false,
  loading: false,
  name: '',
  description: '',
  summary: '',
  user_email: '',
  summary_entered_by_email: '',
  user: {},
  summary_entered_by: {},
  users: [],
  invites: [],
  decisions: [],
  accessible_decisions: [],
  objects_sequence: false,
  summary_entered_at: '',
  sections: [],
  data_sources: [],
  uploading_sources: [],
  selected_ztree_node: {},
  copied_ztree_node: {},
  error: ''
};

const lastAddedDecision = {
  added_decision_slug: ''
}

export function decisionSetReducer(
  state = { ...initialState, ...lastAddedDecision },
  action
) {
  switch (action.type) {
    case UPDATE_DECISION_SET_DATA: {
      return {
        ...state,
        ...action.payload
      };
    }
    case RESET_DECISION_SET_DATA: {
      return {
        ...state,
        ...initialState
      }
    }
    default:
      return state;
  }
}
