import React, {Fragment, useEffect} from 'react';
import { connect } from "react-redux";
import {chatGptDriverSummarizeRequest} from "../../../store/decision/actions";
import {RichTextSection} from "../../../common/RichTextEditor";
import DotsLoader from "../helpers/DotsLoader";
import {isBlank, isPresent} from "../../../helpers/common";
import {Link} from "react-router-dom";
import {getInitContext} from "../ChatGptDriverSummarizePanel";

export const gptApiSummarizeRequest = (chatGptDriverSummarizeRequest, setLoaded, setError, context, refineMessage,
                                       setContext, type, objectSlug, setRefineMessage,
                                       only_assigned_drivers = false) => {
  setLoaded(false)
  const callback = (success) => {
    if (success) {
      setLoaded(true)
      setRefineMessage('')
    } else {
      setLoaded(true)
      setError('Oops! Something happened. Please try again.')
    }
  }

  if (isPresent(refineMessage)) {
    context.push({role: 'user', content: refineMessage})
  }
  const data = {context, generated_for: {type, slug: objectSlug}, only_assigned_drivers}
  chatGptDriverSummarizeRequest(data, callback, setContext)
}

export  const AssistantContentSwitcher = ({assistantContent, index, setIndex}) => {
  if (assistantContent.length < 2) return null;

  return <div className='d-flex mx-3'>
    <span className="text-primary pointer" hidden={index === 0} onClick={() => setIndex(index - 1)}>
      <i className={`fa-solid fa-chevron-left fa-sm fw-bold`} />
    </span>
    <span className="text-muted mx-2">
      {index + 1}/{assistantContent.length}
    </span>
    <span className="text-primary pointer" hidden={index + 1 === assistantContent.length} onClick={() => setIndex(index + 1)}>
      <i className={`fa-solid fa-chevron-right fa-sm`} />
    </span>
  </div>
}

export const DriverList = ({expandedList, setExpandedList, childrenDrivers}) => {
  return <div className='mx-3 my-2'>
    <Link to={'#'} onClick={() => setExpandedList(!expandedList)} hidden={expandedList}>
      List summarized drivers
    </Link>
    <div hidden={!expandedList}>
      <div className='fw-bold'>Summarized drivers:</div>
      <ul hidden={!expandedList}>
        { childrenDrivers.map((child, index) => <li key={index}>{child.driver.question}</li>) }
      </ul>
    </div>
  </div>
}

const ChatGptDriverSummarizeSection = ({ driverData, gptApiSummarizeCallback, loaded, setLoaded,
                                         error, context, setContext, assistantContent, index, setIndex,
                                         type, decision, expandedList, setExpandedList,
                                         childrenDrivers, sidebar, explanation
                                      }) => {

  const {recommendationInputSidebar, decisionInputSidebar, driverInputSidebar} = sidebar

  useEffect(() => {
    if (driverInputSidebar || recommendationInputSidebar || decisionInputSidebar) {
      if (isPresent(context)) return setLoaded(true);

      const initialContext = getInitContext(type, decision, driverData) || [];
      if (isBlank(initialContext)) {
        gptApiSummarizeCallback()
      } else {
        setContext(initialContext);
        setLoaded(true)
      }
    }
  }, [explanation])

  useEffect(() => {
    assistantContent.length > 0 && setIndex(assistantContent.length - 1)
  }, [assistantContent.length])

  if(!loaded) return <DotsLoader addClass={'mx-3 my-2'} />
  if(error) return <p className='text-muted mx-3 my-2'>Error: {error}</p>

  return <Fragment>
    <div className='mx-3'>
      <RichTextSection key={`assistant-${index}`} text={assistantContent[index]?.content} />
    </div>
    <DriverList {...{expandedList, setExpandedList, childrenDrivers}} />
    <AssistantContentSwitcher {...{assistantContent, index, setIndex}} />
  </Fragment>
}

const mapStateToProps = ({ sidebar }) => ({ sidebar })

export default connect(mapStateToProps, { chatGptDriverSummarizeRequest })(ChatGptDriverSummarizeSection);
