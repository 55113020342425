import React from 'react'
import SearchRow from "../homepage/tabs/helpers/SearchRow";
import {BaseDropdownBtn} from "./BaseHamburgerBtn";
import {BtnDropdownToggleInlineShadowText} from "./dropdowns";
import * as moment from "moment/moment";

const actualizedTitle = (finalized_at, active = false) =>
  <>
    <i className={`fa-solid fa-lock ${active ? 'text-white' : 'text-gray'} fa-sm ms-2 me-1`} />
    {moment(finalized_at).format('D MMM, yyyy')}
  </>

export default ({
                  selectedOption, setSelectedOption,
                  searchQuery, setSearchQuery,
                  allowDefault = false, active = false, disabled_value = null,
                  options, onClick = (_) => null
                }) =>
  <BtnDropdownToggleInlineShadowText bsPrefix="text-dark fw-normal pe-0 h-auto"
                                     onSelect={setSelectedOption}
                                     title={selectedOption || ''}>
    <div className="px-2 lh-base">
      <SearchRow {...{ searchQuery, setSearchQuery }} bordered hideResults
                 rowStyles={'pb-1'} placeholder={'Search scenarios'}
                 resetSearchAction={() => setSearchQuery('')} />
    </div>
    <div className="dropdown-menu-scroll">
      {
        options.map(({ id, display_name, default_scenario, filtered_actualized, actualized }) =>
          <>
            <BaseDropdownBtn key={id} eventKey={id} title={display_name}
                             bsPrefix={id === active ? 'active' : ''}
                             onClick={() => onClick(id)}
                             disabled={default_scenario && !allowDefault || disabled_value === id} />
            {
              (filtered_actualized || actualized || []).map(({ id, finalized_at }) =>
                <BaseDropdownBtn key={id} eventKey={id} title={actualizedTitle(finalized_at, id === active)}
                                 onClick={() => onClick(id)}
                                 disabled={disabled_value === id}
                                 bsPrefix={`${id === active ? 'active' : ''}`} />
              )
            }
          </>

        )
      }
    </div>
  </BtnDropdownToggleInlineShadowText>
