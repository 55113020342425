import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import { Loader } from "../../common/Loader";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import {
  createCategory,
  deleteCategory,
  loadOrgCategories,
  sortCategories,
  updateCategory
} from "../../store/org_categories/actions";
import { Link, useHistory } from "react-router-dom";
import { isAdmin, TABS_URLS } from "../../helpers/home_helpers";
import Select from 'react-select';
import { NoBorderFilterStyle } from "../../tree_wizard/styles/choice_entry_styles";
import AddCategoryModal from '../modals/AddCategoryModal';
import UpdateCategoryModal from '../modals/UpdateCategoryModal';
import SortCategoriesModal from '../modals/SortCategoriesModal';
import { removeCategoryWarning } from "../../helpers/alert_helpers";
import { BaseDropdownBtn, DeleteItemElement } from "../../common/BaseHamburgerBtn";
import { IconBtnDropdown } from "../../common/dropdowns";
import { EditIcon } from "../../common/EditIcon";
import { checkLoadingEffect } from "../../helpers/callbacks_helpers";

const PageHeader = ({disabled, openAddCategoryModal}) =>
  <div className="row">
    <div className="col-6">
      <h1 className="me-1">Categories</h1>
    </div>
    <div className="col-6">
      <Button disabled={disabled} onClick={openAddCategoryModal} className="h5 float-end h-36 py-1">
        <i className="fas fa-plus fa-lg me-1" />
        <span>Add category</span>
      </Button>
    </div>
  </div>

export const CategoriesTab = ({ home, current_user, current_org, org_categories, loadOrgCategories, match,
                                createCategory, updateCategory, deleteCategory, sortCategories }) => {
  const history = useHistory();

  const [submitState, setSubmitState] = useState(false);
  const [addModalShown, setAddModalShown] = useState(false);
  const [updateModalShown, setUpdateModalShown] = useState(false);
  const [sortCategoriesModalShown, setSortCategoriesModalShown] = useState(false);
  const [activeCategorySlug, setActiveCategorySlug] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const isUserAdmin = useMemo(() => isAdmin(current_user), [current_user]);
  const allCategories = useMemo(() => org_categories.available_categories, [org_categories, current_org])
  const [newActiveCategoryName, setNewActiveCategoryName] = useState('');

  const openAddCategoryModal = useCallback(() => { setAddModalShown(true) }, [org_categories.available_categories, activeCategorySlug, activeCategory]);
  const openEditCategoryModal = useCallback(() => { setUpdateModalShown(true) }, [org_categories.available_categories, activeCategorySlug, activeCategory]);
  const openSortCategoriesModal = useCallback(() => { setSortCategoriesModalShown(true) }, [org_categories.available_categories, activeCategorySlug, activeCategory]);

  checkLoadingEffect(org_categories, loadOrgCategories);

  const onSubmitAddCategoryModal = useCallback((name, settings, categoryOptions, callback) => {
    setSubmitState(true)
    createCategory(name, settings, categoryOptions, (success, errors) => {
      setSubmitState(false)
      if(success) setNewActiveCategoryName(name);
      callback(success, errors)
    })
  }, [org_categories])

  const onSubmitUpdateCategoryModal = useCallback((slug, name, settings, categoryOptions, callback) => {
    setSubmitState(true)
    updateCategory(slug, name, settings, categoryOptions, (success, errors) => {
      setSubmitState(false)
      if(success) setNewActiveCategoryName(name);
      callback(success, errors)
    })
  }, [org_categories])

  const onSubmitSortCategoriesModal = useCallback((data, callback) => {
    setSubmitState(true)
    sortCategories(data, (success) => {
      setSubmitState(false)
      callback(success)
    })
  }, [org_categories])

  useEffect(() => {
    let activeCategory = null;
    if (isPresent(match?.params?.id)) activeCategory = allCategories.find(c => c.slug === match.params.id)
    if (isPresent(newActiveCategoryName)) activeCategory = allCategories.find(c => c.name === newActiveCategoryName)
    if (isBlank(activeCategory) && isPresent(activeCategorySlug)) activeCategory = allCategories.find(c => c.slug === activeCategorySlug)
    if (isBlank(activeCategory)) activeCategory = allCategories[0];
    if (org_categories.loaded) setActiveCategorySlug(activeCategory?.slug)
    setActiveCategory(activeCategory)
  }, [match, home, org_categories, current_org, activeCategorySlug, newActiveCategoryName])

  // Inner components

  const onChangeCategoriesFilter = useCallback((category) => {
    setNewActiveCategoryName('');
    history.push(`/homepage/${TABS_URLS.categories}/${category.value}`);
  }, [home]);

  const deleteCategoryCallback = useCallback(() => {
    removeCategoryWarning().then(confirmed => {
      if(!confirmed) return;

      setSubmitState(true)
      deleteCategory(activeCategory.slug, (success) => {
        if(success) setSubmitState(false);
      })
    })
  }, [org_categories, activeCategory])

  const CategoryOptionTile = useCallback(({option}) => {
    const description = option.description;

    return <div className="d-flex align-items-center mt-2">
      <div className="text-truncate">
        {description}
      </div>
    </div>
  }, [org_categories, activeCategory]);

  const sortedCategoryOptions = useMemo(() => [...qSortArray(activeCategory?.options || [], true, (a) => a.description.trim().toLowerCase())], [activeCategory])

  const EditDSightCategory = useCallback(() => {
    return <EditIcon onClick={openEditCategoryModal} title="Edit category" />
  }, [activeCategory])

  const NonDSightCategoryDropdown = useCallback(({disabled}) =>
      <IconBtnDropdown id={`category-menu-dropdown-${activeCategory?.slug}`}
                       bsPrefix="ms-auto" disabled={disabled} className="ms-1">
        <BaseDropdownBtn eventKey={activeCategory?.slug} onSelect={openEditCategoryModal} title="Edit category" />
        <BaseDropdownBtn eventKey={activeCategory?.slug} onSelect={deleteCategoryCallback}>
          <DeleteItemElement name="category" />
        </BaseDropdownBtn>
      </IconBtnDropdown>

    , [home, activeCategory])

  const CategoryContent = useCallback(({disabled}) =>
      <div>
        <div className="d-flex">
          <h2 className="m-0 align-self-center">{activeCategory?.name}</h2>
          <div className="ms-auto" hidden={!isUserAdmin}>
            {
              isPresent(activeCategory?.d_sight) ?
                <EditDSightCategory /> :
                <NonDSightCategoryDropdown disabled={disabled} />
            }
          </div>
        </div>
        <div className="">
          {sortedCategoryOptions?.map(option =>
            <CategoryOptionTile key={`category-${activeCategory?.slug}-option-${option.id}`} option={option} />
          )}
        </div>
      </div>, [home, org_categories, current_user, activeCategory])

  return (
    <>
      <div className="groups-container me-auto">
        <PageHeader disabled={submitState} openAddCategoryModal={openAddCategoryModal} />
        <div className="row" hidden={!org_categories.loading}>
          <Loader />
        </div>
        <div className={`w-100 mb-3 ${org_categories.loading ? 'd-none' : 'd-md-flex'}`} hidden={org_categories.loading}>
          <div className={`bg-white groups-tile groups-menu d-none d-md-inline-block me-3 w-100`}>
            <div className="list-group py-2">
              {
                allCategories.map(category =>
                  <Link key={`menu-category-${category.slug}`} className={`list-group-item list-group-item-action fw-bolder border-0 px-3 ${category.slug === activeCategory?.slug ? 'active' : ''}`}
                        onClick={() => setNewActiveCategoryName('') }
                        to={`/homepage/${TABS_URLS.categories}/${category.slug}`}>
                    {category.name}
                  </Link>
                )
              }
            </div>
            <div className="mx-3 mb-2">
              <Button onClick={openSortCategoriesModal} className="btn btn-secondary w-100 mb-2" disabled={allCategories.length < 2}>
                Sort categories
              </Button>
            </div>
          </div>
          <div className="d-md-none w-100 mb-2">
            <div>
              <Button onClick={openSortCategoriesModal} className="btn btn-secondary w-100 mb-2 p-1" disabled={allCategories.length < 2}>
                Sort categories
              </Button>
            </div>
            <Select value={{value: activeCategory?.slug, label: activeCategory?.name}}
                    isDisabled={submitState}
                    placeholder="Select a category"
                    onChange={onChangeCategoriesFilter}
                    components={{ IndicatorSeparator:() => null }}
                    options={allCategories.map(category => ({ value: category.slug, label: category.name }))}
                    styles={NoBorderFilterStyle} />
          </div>
          <div className="bg-white groups-tile p-3 ms-auto groups-content align-top w-100">
            <CategoryContent disabled={submitState || isBlank(activeCategory)} />
          </div>
        </div>
      </div>
      <div className="modals">
        <AddCategoryModal shown={addModalShown}
                          allCategories={allCategories}
                          onSubmit={onSubmitAddCategoryModal}
                          onClose={() => setAddModalShown(false)} />
        <UpdateCategoryModal shown={updateModalShown}
                             allCategories={allCategories}
                             onSubmit={onSubmitUpdateCategoryModal}
                             onClose={() => setUpdateModalShown(false)}
                             category={activeCategory}
                             sortedCategoryOptions={sortedCategoryOptions}/>
        <SortCategoriesModal shown={sortCategoriesModalShown}
                             onSubmit={onSubmitSortCategoriesModal}
                             onClose={() => setSortCategoriesModalShown(false)}
                             categories={org_categories} />
      </div>
    </>
  )
}

const mapStateToProps = ({ home, current_user, current_org, org_categories }) => ({
  home, current_user, current_org, org_categories
});

const mapDispatchToProps = (dispatch) => ({
  loadOrgCategories: () => {
    dispatch(loadOrgCategories())
  },
  createCategory: (name, settings, categoryOptions, callback) => {
    dispatch(createCategory(name, settings, categoryOptions, callback))
  },
  updateCategory: (slug, name, settings, categoryOptions, callback) => {
    dispatch(updateCategory(slug, name, settings, categoryOptions, callback))
  },
  deleteCategory: (slug, callback) => {
    dispatch(deleteCategory(slug, callback))
  },
  sortCategories: (data = {}, callback) => {
    dispatch(sortCategories(data, callback))
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesTab);
