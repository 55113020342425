import {visibleAndEditablePeriodsScope, visiblePeriodsScope} from "./period_helpers";
import {
  createComparisonRows,
  updateRowsOnOpen,
  enableHighlights,
  disableHighlights
} from "./common";
import { ROW_DRIVER_ID_KEY } from "./ag_grid_vars";
import { isPresent, isBlank } from "../../helpers/common";

let activeRequests = 0;
let updateTimeoutId;

const applyScrollBehavior = (gridReady, forecastBenchmarkScenario) => {
  return gridReady && isPresent(forecastBenchmarkScenario);
};

const combinedRows = (existingRows, newRows) => {
  return existingRows.map(rowData => {
    const newRow = newRows.find(row => row[ROW_DRIVER_ID_KEY] === rowData[ROW_DRIVER_ID_KEY]);
    return {...rowData, ...newRow};
  });
};

const applyRows = (api, newRows) => {
  api.applyTransaction({ update: newRows });
};

const anyColumnsToUpdate = (group, periodsScope) => {
  if (!group) return false;

  return periodsScope.some(period => group.added_rows.some(row => !row.hasOwnProperty(period.name)));
};

export const onBodyScroll = () => clearTimeout(updateTimeoutId);

const updateRows = ({ rowNode, gridRef, group, forecastScenario, forecastBenchmarkScenario, newRows, editedCells, runModelCells, setScrollInProcess, updateOpenedGroups, updateTableCells }) => {
  updateRowsOnOpen({
    api: gridRef.current.api,
    node: rowNode
  }, forecastScenario, group.output, forecastBenchmarkScenario, newRows, editedCells, runModelCells, true, updateOpenedGroups, updateTableCells, false,(status) => {
    if(status) applyRows(gridRef.current.api, newRows);
    activeRequests--;
    if (activeRequests === 0) {
      setScrollInProcess(false);
      enableHighlights(0);
    }
  });
};

const processRow = ({ config, rowNode, forecastScenario, forecastBenchmarkScenario, gridRef, expandedGroupIds, expandedGroups, periodsScope, setScrollInProcess, editedCells, runModelCells, updateScenario, updateOpenedGroups, updateTableCells }) => {
  if (expandedGroupIds.includes(rowNode.id)) {
    const group = expandedGroups.find(g => g.id === rowNode.id);
    if (anyColumnsToUpdate(group, periodsScope)) {
      const createdRows = createComparisonRows(config, group.output, { node: rowNode }, forecastScenario, forecastBenchmarkScenario, periodsScope);
      const newRows = combinedRows(group.added_rows, createdRows);
      activeRequests++;
      if (activeRequests === 1) {
        setScrollInProcess(true);
        disableHighlights();
      }
      updateRows({
        rowNode,
        gridRef,
        group,
        forecastScenario,
        forecastBenchmarkScenario,
        newRows,
        editedCells,
        runModelCells,
        updateScenario,
        setScrollInProcess,
        updateOpenedGroups,
        updateTableCells
      });
    }
  }
};

const dataIsAlreadyFetched = (forecastScenario, periodsScope) => {
  return isPresent(forecastScenario.agGridPreparedData) &&
    periodsScope.every(period => isPresent(forecastScenario.agGridPreparedData[0][period.name]));
};

const updateMainRows = (forecastScenario, gridRef, periodsScope, currentCmuGroups, updateCachedRows, rowsDataWithEditedCells) => {
  if(forecastScenario.isAnnualTimeScale) return;
  if(dataIsAlreadyFetched(forecastScenario, periodsScope)) return;

  const newRows = forecastScenario.preparedRowsForTable(currentCmuGroups, periodsScope);
  if(isBlank(forecastScenario.agGridPreparedData)) {
    const newRowsWithEditedCells = rowsDataWithEditedCells(newRows);
    applyRows(gridRef.current.api, newRowsWithEditedCells);
    return;
  }

  const existingRowsMap = new Map(forecastScenario.agGridPreparedData.map(row => [row[ROW_DRIVER_ID_KEY], row]));

  newRows.forEach(newRow => {
    const existingRow = existingRowsMap.get(newRow[ROW_DRIVER_ID_KEY]);
    if (isBlank(existingRow)) {
      forecastScenario.agGridPreparedData.push(newRow);
    } else {
      periodsScope.forEach(period => {
        if (isPresent(newRow[period.name])) {
          existingRow[period.name] = newRow[period.name];
        }
      });
    }
  });

  const combinedRows = Array.from(existingRowsMap.values());
  updateCachedRows(combinedRows);
  const newRowsWithEditedCells = rowsDataWithEditedCells(combinedRows);
  applyRows(gridRef.current.api, newRowsWithEditedCells);
};

const getRowNodes = (gridRef) => {
  let rowNodes = [];
  const firstVisibleRowIdx = gridRef.current.api.getFirstDisplayedRowIndex();
  const lastVisibleRowIdx = gridRef.current.api.getLastDisplayedRowIndex();
  for (let i = firstVisibleRowIdx; i <= lastVisibleRowIdx; i++) {
    rowNodes.push(gridRef.current.api.getDisplayedRowAtIndex(i));
  }
  return rowNodes;
};

export const onHorizontalScrollBody = ({
                                         config,
                                         gridRef,
                                         forecastScenario,
                                         expandedGroupIds,
                                         expandedGroups,
                                         setScrollInProcess,
                                         forecastBenchmarkScenario,
                                         editedCells,
                                         runModelCells,
                                         currentCmuGroups,
                                         updateCachedRows,
                                         updateOpenedGroups,
                                         updateTableCells,
                                         rowsDataWithEditedCells
                                       }) => {
  const rowNodes = getRowNodes(gridRef);
  const periodsScope = visibleAndEditablePeriodsScope(gridRef.current, forecastScenario);
  disableHighlights();
  updateMainRows(forecastScenario, gridRef, periodsScope, currentCmuGroups, updateCachedRows, rowsDataWithEditedCells);
  enableHighlights();
  if(expandedGroupIds.length === 0) return;

  rowNodes.forEach(rowNode => {
    processRow({
      config,
      rowNode,
      forecastScenario,
      forecastBenchmarkScenario,
      gridRef,
      expandedGroupIds,
      expandedGroups,
      periodsScope,
      setScrollInProcess,
      editedCells,
      runModelCells,
      updateOpenedGroups,
      updateTableCells
    });
  });
};

const onHorizontalScroll = ({ gridReady, gridRef, forecastBenchmarkScenario, ...opts }) => {
  if (!applyScrollBehavior(gridReady, forecastBenchmarkScenario)) return;

  // Clear any existing timeout to prevent multiple updates
  clearTimeout(updateTimeoutId);

  updateTimeoutId = setTimeout(() => {
    if(!gridRef.current) return;

    onHorizontalScrollBody({ gridRef, forecastBenchmarkScenario, ...opts });
  }, 200);
};

export const onBodyScrollEnd = ({ event, scrollInProcess, ...opts }) => {
  if (scrollInProcess) return;

  switch (event.direction) {
    case 'horizontal':
      return onHorizontalScroll({ event, ...opts });
  }
};
