import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import Button from 'react-bootstrap/Button';
import Driver from "../../../models/driver";
import { driversToArray } from "../../../helpers/drivers_helpers";
import {useHistory} from "react-router";
import {isBlank, isPresent, successActions} from "../../../helpers/common";
import {resetAssignedDriversData} from "../../../store/assigned_drivers/actions";
import {updateTreeData} from "../../../store/tree/common_actions";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";
import Decision from "../../../models/decision";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import {resetSidebars} from "../../../store/sidebar/actions";

export const ASSIGN_DRIVER_TITLE = 'Enter assigned drivers';

const allDriversCompleted = (drivers) => drivers.every(driverData =>
  new Driver(driverData.driver).driverStateData.completed
);

const RenderAssignedDriversButton = ({
                                       current_user, tree, resetAssignedDriversData,
                                       updateTreeData, isHamburgerBtn = false,
                                       decision, resetSidebars
                                    }) => {
  if (isBlank(tree.drivers) || isBlank(current_user)) return null;

  const decisionObj = new Decision(decision);
  if (decisionObj.isRecordedOrRecommended || decision.read_only || isDSightDecision(decision)) return null;

  const assignedDrivers = driversToArray(tree.drivers).filter(driverData =>
    isPresent(current_user.email) && driverData.driver.assign_to_user === current_user.email
  );
  const isAllDriversCompleted = allDriversCompleted(assignedDrivers);
  if (isAllDriversCompleted) return null;

  const history = useHistory();
  const [submit, setSubmit] = useState(false)

  const redirectToAssignedDriversPage = () => {
    setSubmit(true);
    const path = history.location.pathname.replace('tree_viewer', 'assigned_drivers');
    history.push(path);
    updateTreeData({ locationPath: history.location.pathname });
    successActions(true, [resetAssignedDriversData, resetSidebars]);
    setSubmit(false);
  };

  return <>
    {
      isHamburgerBtn
        ? <BaseHamburgerBtn onSelect={redirectToAssignedDriversPage} title={ASSIGN_DRIVER_TITLE} />
        : <Button onClick={redirectToAssignedDriversPage}
                  className="btn btn-secondary w-100 d-none d-lg-flex align-items-center"
                  disabled={submit}>
           {ASSIGN_DRIVER_TITLE}
          </Button>
    }
  </>
};

const mapStateToProps = ({ tree, current_user, decision }) => ({
  tree, current_user, decision
});

export default connect(mapStateToProps, {
  resetAssignedDriversData, updateTreeData, resetSidebars
})(RenderAssignedDriversButton);