import React from "react";
import { isBlank } from "../../helpers/common";
import {TooltipWrapper} from "../../bubble_tooltip/BubbleTooltip";
import Moment from "moment/moment";

const ICON_SIZE = {
  sm: 'fa-sm',
  lg: 'fa-lg'
};

export const actualizedText = (actualizedDate) => `Actualized ${Moment(actualizedDate).format('DD MMM, yyyy')}`

export default ({ actualizedDate, iconSize = 'sm', iconClassName = '' }) => {
  if(isBlank(actualizedDate)) return null;

  return <TooltipWrapper {...{tooltipText: actualizedText(actualizedDate)}}>
    <div className={`d-inline-flex rounded-circle justify-content-center align-items-center bg-gray ${iconClassName}`}>
      <i className={`fa-solid fa-lock text-white ${ICON_SIZE[iconSize]}`}></i>
    </div>
  </TooltipWrapper>
}
