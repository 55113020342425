import React from "react";
import PlusIcon from "../shared/PlusIcon";
import {isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
export default ({ user, description, decision, onClick = () => {}, allowVisitor = false }) =>
  <div onClick={onClick} className={`${(isVisitor(user) && !allowVisitor) || isDeciderReadOnly(user) || decision?.read_only ? 'cursor-default' : 'pointer'} d-inline-flex w-100 my-auto`}>
    <div className="note_paragraph fw-bolder text-ellipsis">
      {description}
      {!(isVisitor(user) && !allowVisitor) && !isDeciderReadOnly(user) && !decision?.read_only && <PlusIcon />}
    </div>
  </div>
