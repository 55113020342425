import React, { useMemo } from "react";
import { connect } from "react-redux";
import { ForecastScenario } from "../../models/forecast/ForecastScenario";
import {
  createComparisonRows,
  disableHighlights,
  enableHighlights,
  updateRowsOnOpen
} from "../helpers/common";
import {
  ROW_DRIVER_ID_KEY,
  FACT_COMPARISON_HEADERS_NAMES,
  VALUE_SALES_FACT
} from "../helpers/ag_grid_vars";
import {
  updateOpenedGroups,
  updateScenarioData,
  updateTableCells
} from "../../store/forecast_simulator_scenario/actions";
import { hideOverlay, MESSAGES, showOverlayWithMessage } from "../helpers/custom_loading_overlay";
import { visibleAndEditablePeriodsScope } from "../helpers/period_helpers";
import { isBlank } from "../../helpers/common";

const addComparisonRows = (newItems, params) => {
  const addIndex = params.node.leafGroup ?
    params.node.allLeafChildren[params.node.allLeafChildren.length-1].rowIndex :
    params.node.rowIndex;
  params.api.applyTransaction({
    add: newItems,
    addIndex
  });
  if(params.node.leafGroup) params.node.setExpanded(true);
};

const removeComparisonRows = (openedGroups, params) => {
  const group = openedGroups.find(group => group.id === params.node.id);
  const idsToRemove = group?.added_rows?.map(row => ({ [ROW_DRIVER_ID_KEY]: row[ROW_DRIVER_ID_KEY] })) || [];
  params.api.applyTransaction({ remove: idsToRemove });
  if(params.node.leafGroup) params.node.setExpanded(false);
};

export const removeAllComparisonRows = (openedGroups, gridRef) => {
  openedGroups.forEach(group => {
    const idsToRemove = group?.added_rows?.map(row => ({ [ROW_DRIVER_ID_KEY]: row[ROW_DRIVER_ID_KEY] })) || [];
    gridRef.current.api.applyTransactionAsync({ remove: idsToRemove });
  });
};

const onRowGroupOpened = ({ config, editedCells, runModelCells, params, expanded, forecastScenario, forecastBenchmarkScenario, openedGroups, updateScenarioData, updateOpenedGroups, updateTableCells }) => {
  showOverlayWithMessage(params.api, updateScenarioData, MESSAGES.updating_scenario);
  disableHighlights();
  setTimeout(() => {
    if(expanded) {
      const isOutputGroup = !params.node.leafGroup;
      const periodsScope = forecastScenario.isAnnualTimeScale ? forecastScenario.allTimeScalePeriods() : visibleAndEditablePeriodsScope(params, forecastScenario);
      const newRows = createComparisonRows(config, isOutputGroup, params, forecastScenario, forecastBenchmarkScenario, periodsScope);
      updateRowsOnOpen(params, forecastScenario, isOutputGroup, forecastBenchmarkScenario, newRows, editedCells, runModelCells, expanded, updateOpenedGroups, updateTableCells, true,(status) => {
        if(status) addComparisonRows(newRows, params);
        enableHighlights();
        hideOverlay(params.api);
      });
    } else {
      const opened_group = openedGroups.find(group => group.id === params.node.id);
      updateOpenedGroups(forecastScenario.local_id, { opened_group, delete: true }, (status) => {
        if (status) {
          removeComparisonRows(openedGroups, params);
        }
        enableHighlights();
        hideOverlay(params.api);
      });
    }
  }, 0);
};

const lookupScenarioEditedData = (forecast_simulator_scenario) => {
  const forecastScenario = useMemo(() => {
    if(!forecast_simulator_scenario || !forecast_simulator_scenario.table_cells_loaded || !forecast_simulator_scenario.opened_groups_loaded) return { editedCells: [], openedGroups: []};
      return new ForecastScenario(
        forecast_simulator_scenario.scenario,
        forecast_simulator_scenario.scenario.config,
        forecast_simulator_scenario.scenario.view_options.timeScale);
    },
    [forecast_simulator_scenario?.scenario, forecast_simulator_scenario?.table_cells_loaded, forecast_simulator_scenario?.opened_groups_loaded]
  );
  return {
    runModelCells: forecastScenario.actualRunModelCells || [],
    editedCells: forecastScenario.actualEditedCells || [],
    openedGroups: forecastScenario.openedGroups || []
  };
};

const cellStyles = (isGroup, isComparisonHeader) => !isGroup && isComparisonHeader ?
  'ag-row-group-leaf-indent ag-row-group-indent-1' : 'ag-cell-expandable ag-row-group ag-row-group-indent-0';


const FactsCellRenderer = ({ params, config, forecastScenario, forecastBenchmarkScenario, forecast_simulator_scenario,
                             updateScenarioData, updateOpenedGroups, updateTableCells }) => {
  const isGroup = params.node.group;
  const aggFact = forecast_simulator_scenario.ag_grid_aggregated_fact;
  const isCmuKey = forecastScenario.groupFields.some(field => field === params.node.field);
  const isComparisonHeader = FACT_COMPARISON_HEADERS_NAMES.includes(params.value);
  const { editedCells, runModelCells, openedGroups } = lookupScenarioEditedData(forecast_simulator_scenario);
  const expanded = isGroup && openedGroups.find(group => group.id === params.node.id)?.expanded;
  const isBenchmarkLoaded = !forecast_simulator_scenario.benchmark_loading && forecast_simulator_scenario.benchmark_loaded;
  const onClick = () => {
    if(!isBenchmarkLoaded) {
      showOverlayWithMessage(params.api, updateScenarioData, MESSAGES.loading_benchmark_data);
      setTimeout(() => hideOverlay(params.api), 3000);
    } else {
      onRowGroupOpened({
        config,
        editedCells,
        runModelCells,
        params,
        expanded: !expanded,
        forecastScenario,
        forecastBenchmarkScenario,
        openedGroups,
        updateScenarioData,
        updateOpenedGroups,
        updateTableCells
      });
    }
  }

  const isExpandable = !isCmuKey || isBlank(aggFact) || aggFact.displayName === VALUE_SALES_FACT;
  return <span className={`ag-cell-wrapper ${cellStyles(isGroup, isComparisonHeader)}`}>
   <span className={`ag-group-expanded ${isExpandable ? (isGroup && expanded ? '' : 'ag-hidden') : 'ag-hidden'}`} onClick={onClick}>
     <span className="ag-icon ag-icon-tree-open" unselectable="on" role="presentation"></span>
   </span>
   <span className={`ag-group-contracted ${isExpandable ? (isGroup && !expanded ? '' : 'ag-hidden') : 'ag-hidden'}`} onClick={onClick}>
     <span className="ag-icon ag-icon-tree-closed" unselectable="on" role="presentation"></span>
   </span>
   <span className="ag-group-checkbox ag-invisible"/>
    <span className="ag-group-value">
      {isGroup && isComparisonHeader || isCmuKey ? (forecastScenario.driverWithMeasure(aggFact) || forecastScenario.valueSalesDriverName) : params.value}
    </span>
    <span className="ag-group-child-count"/>
  </span>
};
const mapStateToProps = ({ forecast_simulator_scenario }) => ({
  forecast_simulator_scenario
});
const mapDispatchToProps = (dispatch) => ({
  updateScenarioData: (data) => dispatch(updateScenarioData(data)),
  updateOpenedGroups: (scenario_id, data, callback) => dispatch(updateOpenedGroups(scenario_id, data, callback)),
  updateTableCells: (scenario_id, data, callback) => dispatch(updateTableCells(scenario_id, data, callback))
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FactsCellRenderer);
