import { useEffect } from 'react';
import { isBlank, isPresent, uniqueBy } from "../../../../helpers/common";
import {
  buildInviteDataBy,
  stepKeyWithInvites, stepWithDrivers,
  wizardStepDataBy
} from "../../../../helpers/wizard_helpers";
import { driversToArray } from "../../../../helpers/drivers_helpers";
import { isExistedUserContact, isGuestContact, isPendingContact } from "../../../../helpers/decision_helpers";

const NEW_DRIVER_DATA = {
  question: "",
  slug: "",
  assign_to_user: "",
  notes: "",
  driver_type_slug: "",
  answer: "",
  parent_id: null,
  level_order: 1,
  draft: '',
  rating_scale: '',
  rating_labels: '',
  due_date: '',
  choice_entry_widget_type: null,
  driver_response_choices: [],
  number_format: 0,
  weight: null,
  responder_email: ''
};
export const buildNewDriver = (parent = null, levelOrder = 1) => {
  const driverData = {
    children: [],
    driver_sources_slugs: [],
    driver: { ...NEW_DRIVER_DATA }
  };
  driverData.driver.slug = isPresent(parent?.driver) ? `new-driver-${parent.driver.slug}-${parent.children.length}` : `new-driver-${Date.now()}`
  // parent_id = 1 it is just a flag that the driver has parent driver
  driverData.driver.parent_id = isPresent(parent?.driver) ? 1 : null;
  driverData.driver.level_order = levelOrder;
  return driverData;
}
export const buildDrivers = ({
                               tree, wizard,
                               newAddedRootDriver = false, fetchFromTree = true,
                               setNewAddedDriver = () => null,
                               step = stepWithDrivers(wizard)
                             }) => {
  const drivers = isPresent(tree.drivers) && fetchFromTree ?
    tree.drivers :
    wizardStepDataBy(wizard, step)?.drivers || []

  if (isBlank(drivers) || !drivers.some((driverData) => isBlank(driverData.driver.question))) {
    drivers.push(buildNewDriver(null, drivers.length+1))
    if (!newAddedRootDriver) setNewAddedDriver(true);
  }
  return drivers;
}

export const submitBuilderStepDataCallback = (stepData = {}, step, submitData, submitState = () => {}, setDrivers = () => {}) => {
  return (nav_data = {}, callback = () => {}) => {
    if(isPresent(nav_data.drivers)) setDrivers(nav_data.drivers)
    submitState(true)
    submitData(step, {
      ...stepData,
      ...nav_data,
      next_step: true
    }, callback)
  }
}


const nullifyRatingScale = (driverData) => driverData.driver.rating_scale = ''

export const saveDriversCallback = (updateTreeData, drivers) => () => updateTreeData({ drivers, loaded: true, loading: false })
export const findDriverIn = (slug, parentDriverData) => {
  for (const driverData of parentDriverData.children) {
    if(driverData.driver.slug === slug) return [driverData, parentDriverData];
    const [children, childrenParentData] = findDriverIn(slug, driverData)
    if(isPresent(children)) return [children, childrenParentData];
  }
  return [null, parentDriverData];
}
export const onRemoveDriverCallback = (drivers, setDrivers) => (slug) => {
  let newDrivers = [...drivers]
  const [driverData, parentDriversData] = findDriverIn(slug, { children: newDrivers })
  if (isPresent(driverData)) {
    if (parentDriversData.children === newDrivers) {
      newDrivers = newDrivers.filter(c => c.driver.slug !== slug)
      reArrangeLevelDrivers(newDrivers)
    } else {
      parentDriversData.children = parentDriversData.children.filter(c => c.driver.slug !== slug)
      parentDriversData.children = reArrangeLevelDrivers(parentDriversData.children)
    }
    setDrivers(newDrivers);
  }
  return newDrivers;
}

const reArrangeLevelDrivers = (newDrivers) =>
  newDrivers.map((driverData, index) => {
    driverData.driver.level_order = index + 1
    return driverData
  })
const buildCopiedDriverSlug = (parent = null, index = 0) => `copied-driver-${parent?.driver?.slug}-${Date.now()}-${index}`
const setCopiedDriverChildrenSlug = (parent, drivers) =>
  drivers.map((driverData, index) => {
    driverData.driver = { ...driverData.driver, slug: buildCopiedDriverSlug(parent, index + 1) }
    if(isPresent(driverData.children)) {
      driverData.children = setCopiedDriverChildrenSlug(driverData, driverData.children)
    }
    return driverData;
  })
export const onCopyDriverCallback = (drivers, setDrivers) => (slug, copied_slug, callback) => {
  let newDrivers = [...drivers]
  const driversArray = driversToArray(drivers)
  const driverData = driversArray.find((dData) => dData.driver.slug === slug)
  const copiedDriver = driversArray.find((dData) => dData.driver.slug === copied_slug)
  const tempCopiedDriver = JSON.parse(JSON.stringify(copiedDriver));
  const newCopiedDriver = { ...tempCopiedDriver, driver: { ...tempCopiedDriver.driver, slug: buildCopiedDriverSlug(driverData) } }
  if (isBlank(driverData)) {
    newCopiedDriver.children = setCopiedDriverChildrenSlug(newCopiedDriver, newCopiedDriver.children)
    newDrivers.splice(-1, 0, newCopiedDriver)
    newDrivers = reArrangeLevelDrivers(newDrivers)
  } else {
    nullifyRatingScale(newCopiedDriver)
    newCopiedDriver.children = setCopiedDriverChildrenSlug(newCopiedDriver, newCopiedDriver.children)
    driverData.children.splice(-1, 0, newCopiedDriver)
    driverData.children = reArrangeLevelDrivers(driverData.children)
  }
  setDrivers(newDrivers);
  callback(true, newDrivers);
}

export const useAddedRootDriverEffect = ({ step, newAddedRootDriver, submitDrivers, drivers }) => {
  useEffect(() => {
    if(newAddedRootDriver) {
      submitDrivers(step, { drivers: drivers, finish_later: true })
    }
  }, [])
}

const stepTreeContacts = (wizard, contactsData, allowGuest = false, allowPending = true) => {
  const stepData = wizardStepDataBy(wizard, stepKeyWithInvites(wizard))
  const wizardEnteredCollaborators = stepData?.collaborators?.map((email) => buildInviteDataBy(email, contactsData.contacts)) || []
  const invites = contactsData.contacts?.filter(c =>
    isExistedUserContact(c) || (allowPending && isPendingContact(c)) || (allowGuest && isGuestContact(c))
  ).map(({ email }) => buildInviteDataBy(email, contactsData.contacts)) || [];
  invites.push(...wizardEnteredCollaborators)
  const combinedInvites = uniqueBy(invites, 'email')
  const users = [
    ...combinedInvites.filter(invite => isPresent(invite.user)).map(invite => invite.user)
  ];
  return { users, invites: combinedInvites }
};
export const fetchContacts = (wizard, contactsData, allowGuest = false, allowPending = true) =>
  isBlank(contactsData.contacts) ? [] : stepTreeContacts(wizard, contactsData, allowGuest, allowPending).users
