import React, {useState} from "react";
import DoneButton from "../common/DoneButton";
import PollChoicesSection from "../tree_view/side_panel/poll_input/PollChoicesSection";
import {PLACEHOLDER} from "../tree_view/driver_entry/AddCommentForm";
import Form from "react-bootstrap/Form";
import {RichTextSection} from "../common/RichTextEditor";
import {isPresent} from "../helpers/common";

const NO_POLLS_WARNING = 'Decision has no polls'

export const SuccessSection = () =>
  <div className="mx-auto quick-driver-container bg-white p-3 mb-3 rounded text-center">
    <div className="text-success" style={{marginBottom: 18}}>
      <i className="fa-regular fa-circle-check" style={{fontSize: '170px'}} />
    </div>
    <h3 className='mb-0'> Thank you!</h3>
    <div>Your response has been added to the decision.</div>
  </div>

const CommentsSection = ({votesFeedback, setVotesFeedback}) => {
  const onChange = (e) => {
    setVotesFeedback({description: e.target.value})
  }

  return <div className="m-3 mt-2">
    <Form.Label className='h3'>Comments</Form.Label>
    <Form.Control
      as='input'
      placeholder={PLACEHOLDER}
      value={votesFeedback.description}
      onChange={onChange}
    />
  </div>

}

const Notes = ({ decision }) => {
  const {poll_notes} = decision

  return isPresent(poll_notes) && <div className='border-top p-3'>
    <h3>Notes</h3>
    <RichTextSection text={poll_notes} showMoreLess={true} />
  </div>
}

export const ChoicesSection = ({decision, updateQuickPoll, choicesVotes, setChoicesVotes, user, votesFeedback, setVotesFeedback}) => {
  const [submitData, setSubmitData] = useState(false);

  const onClose = () => {
    const data = {
      quick_votes_entry: {
        votedChoices: choicesVotes.map(c => ({slug: c.slug, vote: c.vote})),
        votesFeedback
      }
    };
    const callback = (success, redirect) => {
      if (redirect) {
        window.location.href = '/'
        localStorage.setItem('warning', NO_POLLS_WARNING);
      } else if (success){
        setSubmitData(true)
      }
    };

    updateQuickPoll(data, callback);
  }

  const disableBtn = () => {
    const isRecordedVote = choicesVotes.some(c => c.vote.picked || (!c.vote.picked && c.vote.rank) )
    return !isRecordedVote
  }

  if (submitData) return <SuccessSection />

  return <div className="mx-auto quick-driver-container scroll-margin-tab bg-white py-3 mb-3 rounded">
    <PollChoicesSection decision={decision} choicesVotes={choicesVotes} setChoicesVotes={setChoicesVotes} user={user} />
    <CommentsSection votesFeedback={votesFeedback} setVotesFeedback={setVotesFeedback} />
    <Notes decision={decision} />
    <div className='px-3'>
      <DoneButton disabled={disableBtn()} onClose={onClose} />
    </div>
  </div>
}

export default ChoicesSection;
