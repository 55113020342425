import {
  copyDriverData,
  createDataSource,
  createDecisionReportData,
  destroyDecisionReportData,
  deleteDecisionRequest,
  destroyDataSource,
  dragDriverData,
  loadDecisionDriversData,
  postDriverCommentData,
  removeTreeNodeData,
  replaceDataSource,
  transferDecision,
  updateDataSource,
  updateDriverData,
  updateDriverWeightsData,
  updateTreeDataRequest,
  createDriversFromSuggestions,
  createDecisionForecastScenarioData,
  destroyDecisionForecastScenarioData, loadAssignedDriversData
} from "../../utils/Api";
import { collectDecisionDriversComments } from "../decision/actions"
import { updateDecisionData } from "../decision/common_actions"
import EntryPoint from "../../EntryPoint";
import { updateShareData } from "../share/actions";
import { updateUserData } from "../current_user/actions";
import { isPresent, uniqueBy } from "../../helpers/common";
import { failedResponseHandler, isResponseFailed } from "../../helpers/store_helpers";
import { updateOrgData } from "../current_org/actions";
import { updateTemplateData } from "../template/common_actions";
import { updatePlaybookNotesData } from "../playbook_notes/actions";
import { updateTreeChannelData } from "../channels/actions";
import { loadTreeFailure, loadTreeStarted, loadTreeSuccess, updateTreeData } from "./common_actions";
import {updateSidebarDriversDataSourcesData} from "./modal_actions";
import { recalculateDriversCompletion } from "../decision_set/helper_actions";
import {updateGptQueries} from "../sidebar/gpt_actions";

// Tree
export function loadDecisionTree(callback = () => {}) {
  return (dispatch, getStore) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    dispatch(loadTreeStarted({}));
    loadDecisionDriversData({ controllerName, objectSlug }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure }, callback);

      const { data } = response;
      const { decision, share_data, current_user, channels, current_org } = data;
      const { playbook_notes, ...decisionData } = decision
      const currentUserData = { ...getStore().current_user, ...current_user }
      dispatch(updatePlaybookNotesData({ ...(playbook_notes || {}), loaded: true }))
      dispatch(updateDecisionData({ ...decisionData, users: uniqueBy([...decisionData.users, currentUserData], 'email') }));
      dispatch(updateUserData({ ...current_user }));
      dispatch(updateTreeChannelData(channels.tree));
      dispatch(updateShareData({ ...share_data }));
      // TODO: we do not need to save all 'data' in Tree store(See reducers.js of tree store which columns are needed)
      dispatch(loadTreeSuccess({ ...data }));
      dispatch(recalculateDriversCompletion({ ...data }));
      collectDecisionDriversComments(dispatch, getStore, data.drivers)
      if (isPresent(current_org)) {
        dispatch(updateOrgData({ ...current_org }))
      }
      callback(true, decisionData, { ...data });
    })
  }
}

export function updateTree(data) {
  return (dispatch, getStore) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    dispatch(loadTreeStarted({}));
    updateTreeDataRequest({ controllerName, objectSlug, data }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      const { decision, share_data, current_user } = data
      const currentUserData = { ...getStore().current_user, ...current_user }
      dispatch(updateDecisionData({ ...decision, users: uniqueBy([...decision.users, currentUserData], 'email') }));
      if (controllerName === 'templates') {
        dispatch(updateTemplateData({ ...decision }))
      }
      dispatch(updateShareData({ ...share_data }));
      dispatch(updateTreeData({ ...data }));
      dispatch(recalculateDriversCompletion({ ...data }));
      collectDecisionDriversComments(dispatch, getStore, data.drivers)
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}
export function removeTreeNode(slug) {
  return (dispatch, getStore) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    dispatch(loadTreeStarted({}));
    removeTreeNodeData({ controllerName, objectSlug, slug }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      const { decision_chat_gpt_queries, recommendation_chat_gpt_queries } = data;
      dispatch(updateTreeData({ ...data }));
      dispatch(recalculateDriversCompletion({ ...data }));
      updateGptQueries(dispatch, getStore, decision_chat_gpt_queries, recommendation_chat_gpt_queries)
      collectDecisionDriversComments(dispatch, getStore, data.drivers)
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}

// Decision
export function deleteDecision(callback) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    deleteDecisionRequest({ controllerName, objectSlug }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure }, callback)

      callback(true)
    })
  }
}

// Data Sources
export function destroySource(slug, data, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    dispatch(loadTreeStarted({}));
    destroyDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}
export function createSource(data, config = {}, callback = () => {}, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    createDataSource({ controllerName, objectSlug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure }, callback)

      const { data } = response;
      dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
      callback(true);
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}
export function replaceSource(slug, data, config = {}, callback = () => {}, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    dispatch(loadTreeStarted({}));
    replaceDataSource({ controllerName, objectSlug, slug, data, config }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure }, callback)

      const { data } = response;
      dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
      callback(true);
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}
export function updateSource(slug, data, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    dispatch(loadTreeStarted({}));
    updateDataSource({ controllerName, objectSlug, slug, data }).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}

// Attach Report to Decision
export function attachReport(reportSlug, data, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    createDecisionReportData({ controllerName, objectSlug, reportSlug }, data).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}

// Detach Report to Decision
export function detachReport(reportSlug, data, updateOnlySources = false) {
  return (dispatch, getState) => {
    const { objectSlug, controllerName } = EntryPoint.instance;

    destroyDecisionReportData({ controllerName, objectSlug, reportSlug }, data).then((response) => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}

// Attach ForecastScenario to Decision
export const attachScenario = (scenarioId, data, updateOnlySources = false) => (dispatch, getState) => {
  const { objectSlug, controllerName } = EntryPoint.instance;

  createDecisionForecastScenarioData({ controllerName, objectSlug }, { ...data, id: scenarioId }).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

    const { data } = response;
    dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
    setTimeout(() => {
      dispatch(loadTreeSuccess({ changesSaved: false }));
    }, 1000)
  })
}
// Detach Report to Decision
export const detachScenario = (scenarioId, data, updateOnlySources = false) => (dispatch, getState) => {
  const { objectSlug, controllerName } = EntryPoint.instance;

  destroyDecisionForecastScenarioData({ controllerName, objectSlug, scenarioId }, data).then((response) => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

    const { data } = response;
    dispatchTreeDriversDataSources(dispatch, getState, data, updateOnlySources);
    setTimeout(() => {
      dispatch(loadTreeSuccess({ changesSaved: false }));
    }, 1000)
  })
}

// Drivers
export const updateDriversWeights = (data) => (dispatch) => {
  const { objectSlug, controllerName } = EntryPoint.instance;
  updateDriverWeightsData({ controllerName, objectSlug, data }).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

    const { data } = response;
    const { drivers } = data;
    dispatch(updateTreeData({ drivers }));
    dispatch(recalculateDriversCompletion({ drivers }));
    setTimeout(() => {
      dispatch(loadTreeSuccess({ changesSaved: false }));
    }, 1000)
  })
}
export function updateDriver(data) {
  return (dispatch, getStore) => {
    const slug = data.slug;
    const { objectSlug, controllerName } = EntryPoint.instance;
    updateDriverData({ controllerName, objectSlug, slug, data }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      const { drivers } = data;
      dispatch(updateTreeData({ drivers }));
      collectDecisionDriversComments(dispatch, getStore, drivers)
      dispatch(recalculateDriversCompletion({ drivers }));
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}

export function copyDriver(data, callback) {
  return (dispatch) => {
    const slug = data.slug;
    const { objectSlug, controllerName } = EntryPoint.instance;
    copyDriverData({ controllerName, objectSlug, slug, data }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      const { drivers } = data;
      dispatch(updateTreeData({ drivers }));
      dispatch(recalculateDriversCompletion({ drivers }));
      callback(true)
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}

export function dragDriver(data, callback) {
  return (dispatch, getState) => {
    const slug = data.slug;
    const { objectSlug, controllerName } = EntryPoint.instance;
    dragDriverData({ controllerName, objectSlug, slug, data }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      const { drivers,  decision_chat_gpt_queries, recommendation_chat_gpt_queries } = data;
      dispatch(updateTreeData({ drivers }));
      dispatch(recalculateDriversCompletion({ drivers }));
      const {decision} = getState();
      const decisionData = {...decision, chat_gpt_queries: decision_chat_gpt_queries || [], recommendation: {...decision?.recommendation, chat_gpt_queries: recommendation_chat_gpt_queries || []}}
      callback(true, decisionData)
      setTimeout(() => {
        dispatch(loadTreeSuccess({ changesSaved: false }));
      }, 1000)
    })
  }
}

// Create drivers from ChatGPT suggestions
export const createTreeDriversSuggestions = (data, callback) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  createDriversFromSuggestions(objectSlug, data).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });
    const { data } = response;
    const { drivers } = data;
    dispatch(updateTreeData({ drivers }));
    dispatch(recalculateDriversCompletion({ drivers }));
    callback(true)
  })
}

// Driver Comments
export const postUserDriverComment = (driver, data, callback) => (dispatch, getState) => {
  const { objectSlug, controllerName } = EntryPoint.instance;
  postDriverCommentData({ controllerName, objectSlug, driverSlug: driver.slug, data })
    .then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure }, callback)

      const { data } = response;
      getState().tree.drivers
      callback(true);
    })
}

// Transfer
export function transferDecisionTo(new_decider_email) {
  return (dispatch) => {
    const { objectSlug, controllerName } = EntryPoint.instance;
    transferDecision({ controllerName, objectSlug, new_decider_email }).then(response => {
      if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: loadTreeFailure });

      const { data } = response;
      const { decision, current_user, share_data } = data;
      dispatch(updateDecisionData({ ...decision }))
      dispatch(updateShareData({ ...share_data }))
      dispatch(updateUserData({ ...current_user }))
      dispatch(updateTreeData({ current_user }));
    })
  }
}
export function saveUploadingSources(sources) {
  return (dispatch) => {
    dispatch(updateTreeData({ uploading_sources: sources }));
  }
}

// Helpers
export const dispatchTreeDriversDataSources = (dispatch, getState, data, updateOnlySources = false) => {
  const { data_sources, drivers } = data;
  if(updateOnlySources) {
    dispatch(updateTreeData({ data_sources }));
  } else {
    dispatch(updateTreeData({ data_sources, drivers }));
    updateSidebarDriversDataSourcesData(dispatch, getState, drivers, data_sources)
    collectDecisionDriversComments(dispatch, getState, drivers)
  }
}
