import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { BASE_CHARTS_OPTIONS, SERIES_NAME_ROW } from "../../../models/d_sight/metric_chart_helpers";
import { isPresent } from "../../../helpers/common";
import { X_AXIS, Y_AXIS, Y_AXIS_LABEL, TOOLTIP } from "./chart_helpers"

const BASE_COLUMN_CHART_OPTIONS = {
  ...BASE_CHARTS_OPTIONS,
  chart: {
    type: 'column',
    style: {
      fontFamily: 'Montserrat, sans-serif',
    }
  },
  legend: {
    align: 'right',
    verticalAlign: 'middle',
    layout: 'vertical',
    itemStyle: {
      color: '#1F2F3F',
      fontWeight: '500',
      fontSize: '0.875rem'
    }
  },
  plotOptions: {
    series: {
      lineWidth: 3
    }
  }
}

const ColumnChart = ({
                       title, measure, categories, series, decimal = 0,
                       plotBands = null
                     }) => {
  const chartOptions = {
    ...BASE_COLUMN_CHART_OPTIONS,
    yAxis: [{
      ...Y_AXIS,
      labels: {
        ...Y_AXIS_LABEL,
        format: [
          measure.includes('$') ? '$' : null,
          '{value}',
          measure.replace('$', '')
        ].filter(isPresent).join('')
      },
      title: {
        text: [
          measure.includes('$') ? '$' : null,
          title,
          measure.replace('$', '')
        ].filter(isPresent).join(', ')
      }
    }],
    tooltip: {
      ...TOOLTIP,
      pointFormat: [
        '<tr>', SERIES_NAME_ROW,
        `<td style="padding:0"><b>${measure.includes('$') ? '$' : ''}{point.y:.${decimal}f}${measure.replace('$', '')}`,
        '</b></td></tr>'
      ].join('')
    },
    xAxis: {
      ...X_AXIS,
      categories,
      plotBands
    },
    series
  };

  return <>
    <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ className: "forecast-chart-container" }} />
  </>
}
export default ColumnChart;
