import {UPDATE_SIGN_IN_DATA, UPDATE_SIGN_IN_FAILED} from "./types";
import {dispatchError, failedResponseHandler, isResponseFailed} from "../../helpers/store_helpers";
import {sendLoginLinkRequest, skipRegistrationRequest} from "../../utils/Api";
import EntryPoint from "../../EntryPoint";
import {updateAssignedDriversData} from "../assigned_drivers/actions";

export const sendLoginLink = (email, callback) => (dispatch) => {
  dispatch(updateSignInUserData({ loading: true }))

  sendLoginLinkRequest(email).then(response => {
    const { data } = response;
    if (isResponseFailed(response)) return dispatchError(dispatch, { data, callback: updateSignInUserFailure }, callback)

    const { email } = data;
    dispatch(updateSignInUserData({ loading: false, email }))
    callback(email)
  })
}

export const skipRegistration = (callback = () => {}) => (dispatch) => {
  const { objectSlug } = EntryPoint.instance;
  skipRegistrationRequest(objectSlug).then(response => {
    if (isResponseFailed(response)) return failedResponseHandler(dispatch, { ...response, callback: updateSignInUserFailure });
    const { data } = response.data;
    const {email, decision_slug} = data;

    dispatch(updateSignInUserData({ loading: false, email }))
    dispatch(updateAssignedDriversData({ loaded: false }))
    callback(true, decision_slug)
  })
}

export const updateSignInUserData = (data) => ({
  type: UPDATE_SIGN_IN_DATA,
  payload: {
    ...data
  }
});
export const updateSignInUserFailure = error => ({
  type: UPDATE_SIGN_IN_FAILED,
  payload: {
    error
  }
});
