import React, {useEffect, useMemo, useRef, useState} from 'react';
import { connect } from "react-redux";
import {
  isHomepageSectionLoaded,
  isHomepageSectionLoading,
  loadDecisionSets,
  needToLoadHomepageSection
} from "../../../store/homepage/actions";
import {isBlank, paginateArray, qSortArray} from "../../../helpers/common";
import { Loader } from "../../../common/Loader";
import DecisionSetTile from "../tiles/DecisionSetTile";
import DecisionSet from "../../../models/decision_set"
import {Pagination} from "../../../common/Pagination";
import {refreshPaginationCallback, TABS_PER_PAGE} from "./AssignedDriversSection";
import {scrollTopSection} from "./DecisionsSection";
import { useSectionVisibility } from "../../../helpers/cookie_helpers";

const DecisionsSetsSection = ({ home, loadDecisionSets, current_user }) => {
  useEffect(() => {
    if (needToLoadHomepageSection(home, 'decision_sets')) {
      loadDecisionSets({ in_flight: true })
    }
  }, [home.decision_sets.loaded])

  const inFlightSets = home.decision_sets.data || []
  const tiles = qSortArray(inFlightSets,false, d => d.created_at)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const refHeader = useRef(null)

  const recordsPerPage = useMemo(() => paginateArray(tiles, page, TABS_PER_PAGE), [page, tiles])

  const [isSectionVisible, setIsSectionVisible] = useSectionVisibility(current_user.email,  "decision-sets");

  refreshPaginationCallback(tiles, setTotalPages, page, setPage)

  if (isBlank(tiles) && home.decision_sets.loaded) return null;

  return (
    <div>
      <div className="row scroll-margin-tab" ref={refHeader}>
        <div className="col">
          <h1 className={`text-nowrap dropdown-toggle pointer ${isSectionVisible ? 'open' : ''}`} onClick={() => setIsSectionVisible(!isSectionVisible)}>
            In-flight decision flow
          </h1>
        </div>
      </div>
      {isSectionVisible && (
        <>
          <div className="row" hidden={isHomepageSectionLoading(home, 'decision_sets')}>
            {
              recordsPerPage.map((t) =>
                <DecisionSetTile decision_set={t} key={`decisions-center-decision-set-tile-${t.slug}`} />
              )
            }
          </div>
          <div className="row mb-3" hidden={home.decision_sets.loading || totalPages < 2} onClick={() => scrollTopSection(refHeader)}>
            <Pagination page={page} totalPages={totalPages} setPage={setPage} totalCount={tiles.length} perPage={TABS_PER_PAGE} />
          </div>
        </>
      )}
      <div className="row" hidden={isHomepageSectionLoaded(home, 'decision_sets')}>
        <Loader />
      </div>
    </div>
  );
}
const mapStateToProps = ({ home, current_user }) => ({ home, current_user });
export default connect(mapStateToProps, { loadDecisionSets })(DecisionsSetsSection);
