import React from 'react';
import { connect } from "react-redux";
import { isPresent, isBlank, successActions } from "../../../helpers/common";
import {userAlreadyAddedAsAssignee, collaboratorCanEditCollaborators, isDecider, userName} from "../../../helpers/user_helpers";
import { reloadShareData } from "../../../store/share/actions";
import { removeDecisionInvite } from "../../../store/decision/actions";
import Alerts from "../../../../js/alerts";
import { BaseDropdownBtn } from "../../../common/BaseHamburgerBtn";
import { IconBtnDropdown } from "../../../common/dropdowns";
import { reloadContacts } from "../../../store/contacts/actions";
import CollabRowDescription from "../helpers/CollabRowDescription";
import UserAvatarImage from "../../../common/UserAvatarImage";
import { resetDecisionSetData } from "../../../store/decision_set/common_actions";
import { transferDecisionTo } from "../../../store/tree/actions";
import {removeAssignmentWarning} from "../../../helpers/alert_helpers";
import {driversToArray} from "../../../helpers/drivers_helpers";
import Driver from "../../../models/driver";
import CloseIcon from "../../../common/CloseIcon";
import {useHistory} from "react-router-dom";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";

const collaboratorMenuEvents = {
  makeManager: 'makeManager',
  removeInvite: 'removeInvite'
}

const removeInvite = (slug, user, email, removeDecisionInvite, actions, history, driversData, removeYourSelf = false, decision) => {
  const pendingDrivers = driversData.filter(d => !new Driver(d.driver).driverStateData.completed);
  const isExistedAssignee = userAlreadyAddedAsAssignee(user?.email || email, pendingDrivers);

  const removeAction = (data) => removeDecisionInvite(data, (success) => {
    successActions(success, actions)
    success && removeYourSelf && history.push('/homepage')
  })

  Alerts.warning({
    title: `Are you sure you want to remove ${removeYourSelf ? 'yourself from this decision' : 'this collaborator'}?`
  }).then(confirmed => {
    if (confirmed) {
      if (!isExistedAssignee || removeYourSelf || isDSightDecision(decision)) return removeAction({slug})

      removeAssignmentWarning(confirmed => {
        removeAction({slug, unassign_drivers: confirmed})
      }, user?.full_name || email);
    }
  }, () => {})
}

const makeManager = (user, transferDecision) => {
  Alerts.warning({
    title: `Are you sure you want ${user.full_name} to replace you as the manager of this decision?`
  }).then(confirmed => {
    if (confirmed) {
      transferDecision(user?.email)
    }
  }, () => {})
}

const CollaboratorsInviteRow = ({
                                  slug, user, email, decision, current_user, driversData,
                                  transferDecision, removeDecisionInvite,
                                  reloadShareData, reloadContacts, resetDecisionSetData, isLegacy = false
                                }) => {
  const history = useHistory();
  const removeYourSelf = current_user.email === (user?.email || email)

  const removeCollaborator = () => removeInvite(
    slug,  user, email, removeDecisionInvite,
    removeYourSelf ? [] : [reloadShareData, reloadContacts, resetDecisionSetData],
    history,  driversData, removeYourSelf, decision
  )

  const collaboratorMenuSelect = (eventKey) => {
    switch (eventKey) {
      case collaboratorMenuEvents.makeManager:
        return makeManager(user, transferDecision)
      case collaboratorMenuEvents.removeInvite:
        return removeCollaborator()
    }
  }

  const name = userName(user, email)
  const isUserDecider = slug === 'decider'
  const deciderText = decision.assigned_decider_email === (user?.email || email) && decision.decided_at ? 'decider' : '';
  const recommenderText = decision.recommendation?.assigned_recommender_email === (user?.email || email) && decision.recommendation.recommended_at ? 'recommender' : '';
  const hideRemoveIcon = !collaboratorCanEditCollaborators(current_user, decision) || isUserDecider

  return <div className="d-flex mb-1 align-items-center">
    <span className="collaborator_img position-relative">
      <UserAvatarImage user={user} size='md' pendingUserEmail={email} />
      <span className={ isBlank(recommenderText) && isBlank(deciderText) ? 'd-none' : 'd-inline' }>
        <i className="fas fa-star fa-sm text-warning position-absolute end-0"/>
      </span>
    </span>
    <CollabRowDescription {...{ name, isUserDecider, decision, email, deciderText, recommenderText }} />
    <div className="ms-auto" hidden={hideRemoveIcon}>
      <CloseIcon onClose={removeCollaborator} danger={true} />
    </div>
    <div className="ms-auto" hidden={isLegacy || isUserDecider || !isDecider(current_user)}>
      <IconBtnDropdown id={`invite-menu-dropdown-${slug}`} onSelect={collaboratorMenuSelect}>
         <BaseDropdownBtn eventKey={collaboratorMenuEvents.makeManager} title="Make manager" hidden={isBlank(user)} />
         <BaseDropdownBtn eventKey={collaboratorMenuEvents.removeInvite} title="Remove" bsPrefix="text-danger" />
      </IconBtnDropdown>
    </div>
  </div>
}
const mapStateToProps = ({ current_user, tree, decision }) => ({
  current_user, decision,
  default_user_avatar_url: tree.default_user_avatar_url,
  driversData: isPresent(tree.drivers) ? driversToArray(tree.drivers).filter(d => d.driver.question) : []
});
export default connect(mapStateToProps, {
  removeDecisionInvite, reloadShareData, reloadContacts, resetDecisionSetData, transferDecision: transferDecisionTo
})(CollaboratorsInviteRow);
