import React, {useEffect, useMemo, useRef, useState} from "react";
import { connect } from "react-redux";
import CommentsBlock from "../../driver_entry/CommentsBlock";
import {
  DataSourcesRow,
  DSightAnalysis,
  AnsweredByRow,
  ConfidenceRow,
  AnswerRow,
  RatedChoices,
  ExplanationRow, DSightAnsweredByRow
} from "../../modals/DriverShowModal";
import Decision from "../../../models/decision";
import Driver from "../../../models/driver";
import {qSortArray, isPresent, isBlank} from "../../../helpers/common";
import * as moment from "moment/moment";
import { RATING_SCALES_DATA } from "../../../helpers/drivers_helpers";
import { MAX_SCALE, MIN_SCALE } from "../../../models/DriverChoiceRating";
import {isDSightDecision} from "../../../helpers/home_decision_helpers";
import { ADD_COMMENT_ACTION } from "../../side_panel/discussion/MessageRow";
import UserAvatarImage from "../../../common/UserAvatarImage";
import {userName} from "../../../helpers/user_helpers";

const DriverResponderRow = ({ driver, driverResponder, insightsData, avatarSize = 'md', decision }) => {
  if(isBlank(driverResponder) || isDSightDecision(decision)) return null;

  const prefix = isPresent(driver.driver_type_name) ? `${driver.driver_type_name} provided by` : 'Provided by'

  return <div className={`d-flex align-items-center mt-3 ${isPresent(insightsData) ? 'mb-3' : ''}`}>
    <UserAvatarImage user={driverResponder} size={avatarSize} isPendingUser={isBlank(driverResponder.full_name)} />
    <div className="text-muted ms-2">
      {prefix} {userName(driverResponder) || driverResponder}
    </div>
  </div>
};

const ExpandedContent = ({ tree, driverData, decision, driver, drivers, user, driverResponder,
                           answeredByUser, lastUpdateByUser, driverDataSources }) => {
  const innerRef = useRef(null)
  const [trigger, setTrigger] = useState(null);
  const decisionObj = new Decision(decision, drivers)
  const driverObj = useMemo(() => new Driver(driverData.driver, [], {}, decisionObj, true), [driver, decisionObj]);
  const startScale = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.start_scale || MIN_SCALE
  const endScale = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.end_scale || MAX_SCALE
  const isYesOrNo = RATING_SCALES_DATA[driverObj.driver?.rating_scale]?.yes_or_no;
  const enteredUser = lastUpdateByUser ? lastUpdateByUser : answeredByUser;
  const enteredAt = driver.last_update_at ? driver.last_update_at : driver.answered_at;

  useEffect(() => {
    if(isPresent(tree.scroll_to_driver_comment)) {
      const [driverSlug, _, triggerData] = tree.scroll_to_driver_comment.split('-');
      if(driverSlug === driverData.driver.slug && triggerData === ADD_COMMENT_ACTION) setTrigger(ADD_COMMENT_ACTION);
    } else {
      setTrigger(null);
    }
  }, [tree.scroll_to_driver_comment])

  return <form>
    {
      decisionObj.isRateAndCompareDriverChoicesHidden ?
        <div className="mb-3"><AnswerRow driver={driver} /></div> :
        <RatedChoices driverObj={driverObj} startScale={startScale} endScale={endScale} isYesOrNo={isYesOrNo} />
    }
    <div className="mb-3">
      <ExplanationRow driver={driver} />
    </div>
    <ConfidenceRow driver={driver} applyAccordion={false}/>
    <DriverResponderRow {...{ driverResponder, driver, insightsData: driverObj.insightsData, avatarSize: 'sm', decision }} />
    {
      isDSightDecision(decision) ?
        <DSightAnsweredByRow {...{ driver, answeredByUser: enteredUser, enteredAt, insightsData: driverObj.insightsData, avatarSize: 'sm' }} />:
        <AnsweredByRow driverResponder={driverResponder} answeredByUser={enteredUser} enteredAt={enteredAt} />
    }
    <div hidden={!isDSightDecision(decision)}>
      <DSightAnalysis decision={decision} driverObj={driverObj} keyPreffix={'driver-show-modal'} applyAccordion={false} />
    </div>
    <DataSourcesRow driverDataSources={driverDataSources} driver={driver} applyAccordion={false} />
    <CommentsBlock {...{ ref: innerRef, trigger, avatarSize: 'sm', driver: driverData.driver, applyAccordion: false, classNames: isBlank(driverDataSources) ? 'mt-3' : 'mt-2'} } />
  </form>
}
const mapStateToProps = ({ modal, decision, tree, current_user, contacts }, { driverData }) => {
  const users  = decision.users || [];
  const driver = driverData.driver
  const driverSourcesSlugs = driverData.driver_sources_slugs || [];
  const driverDataSources = tree.data_sources.filter((source) => driverSourcesSlugs.includes(source.slug));
  const sortedDriverDataSources = qSortArray(driverDataSources, true,(o) => moment(o.created_at));

  return {
    tree,
    decision, driver, driverDataSources: sortedDriverDataSources,
    drivers: tree.drivers,
    answeredByUser: users.find((user) => user.email === driver.answered_by_user) || driver.answered_by_user,
    lastUpdateByUser: users.find((user) => user.email === driver.last_update_by_user) || driver.last_update_by_user,
    driverResponder: contacts.contacts.find(contact => contact.email === driver.responder_email) || driver.responder_email,
    default_user_avatar_url: tree.default_user_avatar_url,
    user: current_user,
    trigger: modal.trigger
  }
};
export default connect(mapStateToProps)(ExpandedContent);
