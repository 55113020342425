import { useEffect } from "react";
import { TABS_URLS } from "../../helpers/home_helpers";
import { generateExcelSuccess } from "../../helpers/alert_helpers";
import { isBlank, isPresent, qSortArray } from "../../helpers/common";
import { DURATION } from "../../alerts";
import * as moment from "moment";

export const successAlert = (showAlert) => {
  showAlert({
    text: 'Success! Your update has been applied.',
    type: 'success',
    addClass: 'text-center',
    dismissible: false,
    duration: DURATION
  });
}
export const useWhiteBackgroundEffect = () => {
  useEffect(() => {
    document.body.classList.add('bg-white');
    document.documentElement.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
      document.documentElement.classList.remove('bg-white');
    };
  }, []);
}

export const checkAllNonChartsComponentsRendered = (rendered, setRendered) => {
  if (!rendered) {
    setTimeout(() => setRendered(true), 100);
    return true;
  }
  return false;
}

export const createDecisionTabWithScenario = (forecast_simulator_scenario) =>
  window.open(`/homepage/${TABS_URLS.create_decision}?forecast_scenario_id=${forecast_simulator_scenario.scenario_id}`,
    '_blank').focus()

export const getScenarioIdFromParam = (document) => {
  const getParams = new URLSearchParams(document.location.search);
  return getParams.get('forecast_scenario_id');
}

export const generateExcelFile = (gridRef) => {
  generateExcelSuccess('Your download will begin shortly.');
  gridRef.current.api.exportDataAsExcel();
}

export const filterDropdownScenarios = (forecast_simulator_scenario) => forecast_simulator_scenario.config_scenarios

export const filteredOptions = (allOptions, search) =>
  allOptions.filter(hash => {
    if (isBlank(search)) {
      hash.filtered_actualized = null;
      return true;
    }
    if (hash.display_name?.toLowerCase()?.includes(search)) {
      hash.filtered_actualized = null;
      return true;
    }

    hash.filtered_actualized = hash.actualized?.filter(h =>
        h.display_name?.toLowerCase()?.includes(search) || formatedDate(h).toLowerCase().includes(search)
    );
    return isPresent(hash.filtered_actualized);
    }
  )

const formatedDate = (h) => moment(h.finalized_at).format('D MMM, yyyy')

export const prepareAllOptionsArray = (allOptions) =>
  allOptions.flatMap(h =>
    [
      h,
      ...(h.actualized || []).map(hh =>
        ({ ...hh, display_name: `${h.display_name} ${formatedDate(hh)}` })
      )
    ]
  )

export const scenariosOptions = (forecast_simulator_scenario) => {
  return [
    ...qSortArray(
      filterDropdownScenarios(forecast_simulator_scenario),
      true, (hash) => [hash.default_scenario ? 0 : 1, hash.display_name
      ]
    )
  ]
}
