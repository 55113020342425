import {
    HOMEPAGE_LOAD_DATA,
    HOMEPAGE_LOAD_DATA_FAILED,
    HOMEPAGE_LOAD_DATA_STARTED,
    HOMEPAGE_RESET_DSIGHTS_DATA, HOMEPAGE_RESET_FORECAST_DATA, HOMEPAGE_RESET_REPORTS_DATA,
    HOMEPAGE_RESET_SEARCH, HOMEPAGE_UPDATE_DATA
} from "./types";

export const DEFAULT_SORT_ORDER = 'most_recent_activity'

export const BASE_SECTION = {
    data: [],
    loading: false,
    loaded: false,
}

export const BASE_FORECAST_SIMULATOR = {
    data: { scenarios: [], configs: [] },
    loading: false,
    loaded: false,
    error: ''
}

export const BASE_SECTION_WITH_PAGINATION = {
    data: [],
    loading: false,
    loaded: false,
    current_page: 1,
    total_pages: 1,
    total_count: 0,
    total_sets: 0,
    total_decisions: 0
}

const initialState = {
    loading: false,
    loaded: false,
    submit: false,
    current_tab: '',
    user_orgs: [],
    slack_notifier_path: null,
    ms_teams_notifier_path: null,
    error: '',
    draft: BASE_SECTION,
    approval_needed: BASE_SECTION,
    assigned_objects: BASE_SECTION,
    in_flight: BASE_SECTION,
    due_followup: BASE_SECTION,
    create_decision: BASE_SECTION,
    create_decision_additional_data: {},
    admin_templates: BASE_SECTION,
    template_sets: BASE_SECTION,
    admin_template_sets: BASE_SECTION,
    decision_sets: BASE_SECTION,
    users: BASE_SECTION,
    search_results: BASE_SECTION_WITH_PAGINATION,
    groups: BASE_SECTION,
    learning: BASE_SECTION,
    dsight: BASE_SECTION,
    report_insights: BASE_SECTION,
    reports: BASE_SECTION_WITH_PAGINATION,
    search: '',
    sort_order: DEFAULT_SORT_ORDER,
    state: 'all',
    owners: '',
    shared_with_admissions: [],
    d_sight_only: false,
    category_options: [],
    template_users: [],
    property_options: [],
    report_property_options: [],
    org_sso: false,
    invite_org_users_default_notification_text: '',
    default_user_avatar_url: '',
    no_results_image_url: null,
    request_access_image_url: null,
    request_access_sent_image_url: null,
    request_access_decision_slug: '',
    forecast_simulator: BASE_FORECAST_SIMULATOR
};

export function homepageReducer(
    state = initialState,
    action
) {
    switch (action.type) {
        case HOMEPAGE_LOAD_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case HOMEPAGE_UPDATE_DATA: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: false
            };
        }
        case HOMEPAGE_RESET_SEARCH: {
            return {
                ...state,
                search: '',
                search_results: BASE_SECTION_WITH_PAGINATION,
                error: '',
                loading: false,
            };
        }
        case HOMEPAGE_LOAD_DATA_STARTED: {
            return {
                ...state,
                ...action.payload,
                error: '',
                loading: true
            }
        }
        case HOMEPAGE_LOAD_DATA_FAILED: {
            return {
                ...state,
                ...action.payload,
                loading: false
            }
        }
        case HOMEPAGE_RESET_FORECAST_DATA: {
            return {
                ...state,
                forecast_simulator: BASE_FORECAST_SIMULATOR
            }
        }
        case HOMEPAGE_RESET_REPORTS_DATA:
            return {
            ...state,
            reports: BASE_SECTION_WITH_PAGINATION
        }
        case HOMEPAGE_RESET_DSIGHTS_DATA: {
            return {
                ...state,
                dsight: BASE_SECTION
            }
        }
        default:
            return state;
    }
}
