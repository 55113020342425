import React from 'react';
import { Link, useHistory } from "react-router-dom";
import UserAvatarImage from "../../../common/UserAvatarImage";
import CreatedData from "./CreatedData";
import {isBlank, isPresent} from "../../../helpers/common";
import ActualizedTooltip, { actualizedText } from "../../../forecast_simulator_scenario/components/ActualizedTooltip";
import {CalendarCheckMenuIconBtn} from "../../../common/dropdowns";
import BaseHamburgerBtn from "../../../common/BaseHamburgerBtn";

const TileContent = ({ scenario, user }) => {
  const history = useHistory();

  const scenarioClickAction = (e, actualizedScenario) => {
    e.preventDefault();
    history.push(`/forecast_simulator/scenarios/${actualizedScenario.id}`)
  }

  return <div className="homepage-list-col">
    <div className="d-flex align-items-center list-col-content overflow-visible bg-white p-2">
      <div className="text-start overflow-hidden lh-sm">
        <div className={`text-nowrap text-ellipsis fw-bolder ${scenario.default_scenario ? 'text-muted' : ''}`}>
          {scenario.display_name}
        </div>
        <CreatedData scenario={scenario} classNames={'text-muted'}/>
      </div>
      <div className="ps-2 ms-auto">
        <div className={`d-flex justify-content-end text-end`}>
          {
            isPresent(scenario.actualized) &&
              <CalendarCheckMenuIconBtn id={`actualized-scenario-${scenario.id}`}
                                        disabled={isBlank(scenario.actualized)}
                                        className="d-inline me-2">
                {
                  scenario.actualized.map(sa =>
                    <BaseHamburgerBtn onSelect={(e) => scenarioClickAction(e, sa)}
                                      key={`scenario-copy-homepage-${sa.id}`}
                                      title={actualizedText(sa.finalized_at)}/>
                  )
                }
              </CalendarCheckMenuIconBtn>
          }
          <ActualizedTooltip actualizedDate={scenario.finalized_at}
                             iconClassName="sm-avatar me-2"
                             iconSize={'sm'}/>
          {user && <UserAvatarImage key={`${scenario.id}`} user={user}/>}
        </div>
      </div>
    </div>
  </div>
}


const handleLinkClick = (e) => {
  // If clicking on the dropdown button, prevent the default link behavior
  if (e.target.closest('.dropdown-toggle')) {
    e.preventDefault();
  }
};

export default ({ scenario, user }) =>
  <div className="col-12 mb-2">
    {
      isPresent(scenario.default_scenario) ?
        <div><TileContent {...{ scenario, user }} /></div> :
        <Link to={`/forecast_simulator/scenarios/${scenario.id}`}
              onClick={handleLinkClick}
              className="text-dark text-center">
          <TileContent {...{ scenario, user }} />
        </Link>
    }
  </div>
