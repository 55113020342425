const STATES = {
  not_started: 'not_started',
  in_progress: 'in_progress',
  completed: 'completed',
  failed: 'failed',
  to_recalculate: 'to_recalculate',
  updating: 'updating',
  freeze_progress: 'freeze_progress',
  archived: 'archived'
}
const LOCKED_STATES = [
  STATES.freeze_progress,
  STATES.archived
]
const COMPLETED_STATES = [
  ...LOCKED_STATES,
  STATES.completed
]

export const isForecastScenarioReadyByState = (calculating_state) => COMPLETED_STATES.includes(calculating_state)

export const isLockedState = (calculating_state) => LOCKED_STATES.includes(calculating_state)
