import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { isBlank } from "../../../helpers/common";

export default ({ driverData, childrenDrivers, setShowChildren, hideFirstLevel = false }) => {
  const handleArrowClick = (e) => {
    const icon = document.getElementById(`toggle-sub-drivers-icon-${driverData.driver.slug}`);
    icon.classList.remove('first-load');
    icon.classList.toggle('down');
    icon.classList.toggle('up');
    const expanded = icon.classList.contains('up');
    setShowChildren(expanded);
    e.stopPropagation();
  };

  useEffect(() => {
    const arrow = document.getElementById(`toggle-sub-drivers-${driverData.driver.slug}`);
    arrow.addEventListener('click', handleArrowClick)

    return () => {
      arrow.removeEventListener('click', handleArrowClick)
    };
  }, []);

  return <Button hidden={isBlank(childrenDrivers) || hideFirstLevel}
                 id={`toggle-sub-drivers-${driverData.driver.slug}`}
                 className="position-absolute toggle-sub-drivers btn btn-light btn-xsm btn-xsm-round bg-light-gray text-primary">
    <i className={`fas fa-chevron-up arrow up first-load`} id={`toggle-sub-drivers-icon-${driverData.driver.slug}`}/>
  </Button>
}
