import React, { useMemo } from "react";
import Wrapper from "../Wrapper";
import DecisionStatus from "../decision/DecisionStatus";
import Description from "../decision/Description";
import AssignedUser from "../shared/AssignedUser";
import DecisionInfo from "../decision/DecisionInfo";
import { isBlank } from "../../../helpers/common";
import RecordedDecisionCard from "./RecordedDecisionCard";
import RecommendedDecisionCard from "./RecommendedDecisionCard";
import {isDeciderReadOnly, isVisitor} from "../../../helpers/user_helpers";
import { assignedDecisionUser } from "./DecisionCard";
import { ArrowUpIcon } from "../../../icons/custom_icons";

export default ({ decision, sidebar, collaborators, contactsData, decisionObject, user,
                  setDecisionInputSidebarOpen, setRecommendationInputSidebarOpen, showArrow = false }) => {
  if(decisionObject.isDecisionRecorded) return <RecordedDecisionCard />
  if(decisionObject.isEnteredRecommendation) return <RecommendedDecisionCard />

  const assignedUser = assignedDecisionUser(collaborators, contactsData, decisionObject);
  const recommendationObject =  useMemo(() => decisionObject.recommendation, [decisionObject])

  const onEditDecision = () => {
    if(isVisitor(user) || isDeciderReadOnly(user)) return false;
    if(!decisionObject.isRecordedOrRecommended) return setDecisionInputSidebarOpen(!sidebar.decisionInputSidebar);

    return (isBlank(recommendationObject) || recommendationObject.isEnteredRecommendation) ?
      setDecisionInputSidebarOpen(!sidebar.decisionInputSidebar) :
      setRecommendationInputSidebarOpen(!sidebar.recommendationInputSidebar)
  }

  return <Wrapper>
    <div className="d-flex">
      <DecisionStatus {...{decisionObject}} />
      <div className="d-flex flex-column my-auto lh-sm w-100 me-1" style={{minHeight: 36}}>
        <Description {...{user, decision, description: decision.description, onClick: onEditDecision}} />
        <DecisionInfo {...{user, decision, decisionObject}} />
      </div>
      <AssignedUser bubbleIcon={`decision-assignment-${decision.slug}`} assignedUser={assignedUser}/>
    </div>
    <div className={`arrow-up-icon-block ${showArrow ? 'd-block' : 'd-none'}`}>
      <ArrowUpIcon/>
    </div>
  </Wrapper>
}
