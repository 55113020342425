import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {updateDriver} from "../../store/modals/actions";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../common/CloseIcon";
import { ModalDoneFooter } from "../../common/modals";
import DecisionAssignToBlock from "../../tree_view/modals/helpers/DecisionAssignToBlock";
import NotesRow from "./helpers/NotesRow";
import * as moment from "moment/moment";
import DriverDueDate from "../../tree_view/modals/helpers/DriverDueDate";
import {isBlank, isPresent, successActions} from "../../helpers/common";
import {findDriverData} from "../../tree_view/accordion_tree/shared/helpers";
import {
  collaboratorCanEditCollaborators,
  isDecider,
  isExistingCollaborator,
  userAlreadyAddedAsAssignee
} from "../../helpers/user_helpers";
import {assignDriverWarning} from "../../helpers/alert_helpers";
import {checkLoadingEffect} from "../../helpers/callbacks_helpers";
import {collectCollaborationInvites} from "../../helpers/decision_helpers";
import {loadContacts} from "../../store/contacts/actions";
import {fetchContacts} from "../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import {driversToArray} from "../../helpers/drivers_helpers";
import {isDSightDecision} from "../../helpers/home_decision_helpers";

export const driversInviteCallback = ({
                                        userName, assignedToUser, isExistedCollaborator, setIsDriversInvite,
                                        isExistedAssignee, actions, userCanEditCollaborators
                                      }) => {
  if(isExistedCollaborator) setIsDriversInvite(false);
  if(isExistedAssignee && !isExistedCollaborator) setIsDriversInvite(true);
  if(!userCanEditCollaborators) setIsDriversInvite(true);

  if (!isExistedAssignee && !isExistedCollaborator && isPresent(assignedToUser) && userCanEditCollaborators) {
    setTimeout(() => {
      assignDriverWarning(confirmed => {
        setIsDriversInvite(!confirmed);
        successActions(true, actions)
      }, userName);
    }, 50);
  } else {
    successActions(true, actions)
  }
};

export const showDriverAssign = (modal) => modal.type === "DriverAssignModal" && modal.shown;

export const getAssigneeName = (email, collaborators) => collaborators.find((collaborator) => collaborator.email === email)?.full_name || email;

export const DriverAssignModal = ({
                                    driverData, show, onClose, updateDriver,
                                    contactsData, decision, loadContacts, driversData,
                                    current_user, key = ''
                                  }) => {
  if (isBlank(driverData) || isBlank(driverData?.driver?.slug)) return null

  const { slug } = driverData.driver;
  const prevNotes = driverData.driver.notes || '';
  const [notes, setNotes] = useState(prevNotes);
  const prevAssignedToUser = driverData?.driver?.assign_to_user || '';
  const [assignedToUser, setAssignedToUser] = useState(prevAssignedToUser);
  const initDate = isPresent(driverData.driver.due_date) ? moment(driverData.driver.due_date).format('DD MMM, yyyy') : null;
  const [dueDate, setDueDate] = useState(initDate);
  const [submitState, setSubmitState] = useState(false);
  const [isDriversInvite, setIsDriversInvite] = useState(null);

  const invites = isPresent(decision.invites) ? collectCollaborationInvites(decision) : []

  checkLoadingEffect(contactsData, () => loadContacts(() => {}, { invite_yourself: true }));

  const collaborators = fetchContacts({}, contactsData, true, true)

  const userName = getAssigneeName(assignedToUser, collaborators);
  const isExistedCollaborator = isExistingCollaborator(assignedToUser, invites, decision.user);
  const isExistedAssignee = userAlreadyAddedAsAssignee(assignedToUser, driversData);
  const userCanEditCollaborators = isDecider(current_user) || collaboratorCanEditCollaborators(current_user, decision)

  const onChangeDueDate = (date) => {setDueDate(moment(date).format('DD MMM, yyyy'))};
  const onCancelDueDate = () => {setDueDate(null)};

  useEffect(() => {
    if (!show && !submitState) {
      setNotes(prevNotes);
      setAssignedToUser(prevAssignedToUser);
      setDueDate(initDate);
      setIsDriversInvite(null);
    }
  }, [show, driverData]);

  const onDone = () => {
    if (isDSightDecision(decision)) {
      setSubmitState(true)
      return onClose();
    }

    driversInviteCallback({
      userName,
      assignedToUser,
      isExistedCollaborator,
      setIsDriversInvite,
      isExistedAssignee,
      actions: [() => setSubmitState(true), onClose],
      userCanEditCollaborators
    });
  }

  useEffect(() => {
    if (initDate !== dueDate || prevNotes !== notes || prevAssignedToUser !== assignedToUser) {
      submitState && updateDriver({slug,
        due_date: dueDate,
        assign_to_user: assignedToUser,
        drivers_invite: isDriversInvite,
        notes
      });
    }
    setSubmitState(false)
  }, [submitState])

  return <Modal key={key} size="lg" backdrop="static" show={show} onHide={onClose}>
    <Modal.Body>
      <CloseIcon onClose={onClose} />
      <h2>Assign driver</h2>
      <form>
        <div className="row">
          <div className='col-sm-6'>
            <DecisionAssignToBlock {...{assignedToUser, setAssignedToUser }} />
          </div>
          <div className='col-sm-6'>
            <DriverDueDate dueDate={dueDate} onChangeDueDate={onChangeDueDate} onCancelDueDate={onCancelDueDate} />
          </div>
        </div>
        <NotesRow {...{ notes, setNotes }} addClass='mb-0' />
      </form>
    </Modal.Body>
    <ModalDoneFooter onClose={onDone} />
  </Modal>
};

const mapStateToProps = ({ sidebar, modal, contacts, decision, tree, current_user }) => {
  const driverData = findDriverData(
    sidebar.driverInputSidebar ? sidebar : modal,
    (sidebar.driverInputSidebar ? sidebar.driverSlug : modal.slug) || ''
  )
  return {
    modal, driverData, decision, current_user,
    contactsData: contacts,
    driversData: isPresent(tree.drivers) ? driversToArray(tree.drivers).filter(d => d.driver.question) : []
  };
};
export default connect(mapStateToProps, {updateDriver, loadContacts})(DriverAssignModal);
