import React from 'react';
import { connect } from "react-redux";
import AssignToRow from "./AssignToRow";
import { fetchContacts } from "../../../tree_wizard/steps_wizard/steps/helpers/tree_builder_step";
import { isPresent } from "../../../helpers/common";
import {checkLoadingEffect} from "../../../helpers/callbacks_helpers";
import {loadContacts} from "../../../store/contacts/actions";

const DecisionAssignToBlock = ({
                                 contactsData, assignedToUser, setAssignedToUser, setAsCollaborator = () => {},
                                 updateAction = () => {}, decision, tree,
                                 collaborators = null, hidden = false, allowPending = true,
                                 definedSubmitCallback = () => {}, allowGuest = true, loadContacts,
                                 ...opts
                               }) => {
  if (hidden) return null;

  checkLoadingEffect(contactsData, () => loadContacts(() => {}, { invite_yourself: true }));

  collaborators = collaborators || fetchContacts({}, contactsData, allowGuest, allowPending)

  const onChangeAssignee = (option) => {
    const value = isPresent(option) && option.value ? option.value : '';
    setAssignedToUser(value);
    if (definedSubmitCallback()) return;

    updateAction(value);
  };

  const onCreateAssignee = (value) => {
    setAssignedToUser(value);
    if (definedSubmitCallback()) return;

    updateAction(value);
  };

  return <AssignToRow {...{ ...opts, collaborators, assignedToUser, onChangeAssignee, onCreateAssignee }} />
}
const mapStateToProps = ({ contacts, decision, tree }) => ({ contactsData: contacts, decision, tree });
export default connect(mapStateToProps, {loadContacts})(DecisionAssignToBlock);
