import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import FactsSelect from "./components/FactsSelect";
import TotalsSelect from "./components/TotalsSelect";
import SimulatedType, { SIMULATED_TYPES } from "./components/SimulatedType";
import ChartType, { CHART_TYPES } from "./components/ChartType";
import ChartValuesType, { CHART_VALUES_TYPES } from "./components/ChartValuesType";
import ScenariosChart, { calculateScenariosData } from "./components/ScenariosChart";
import { Loader } from "../../common/Loader";
import { arrayLastElement, generatePromisesCallbacks, isBlank, isPresent } from "../../helpers/common";
import { checkAllNonChartsComponentsRendered } from "../helpers/helpers";
import { generateCustomPeriod } from "../../models/forecast/ForecastTImeScale";

const groupRowsByCmus = ({
                           scenario, cmuValues, periods, rows = false
                         }) => {
  const cmuIds = Object.keys(cmuValues).map(i => parseInt(i));
  const res = {};
  const totalPeriod = generateCustomPeriod({
    fromPeriod: periods[0],
    toPeriod: arrayLastElement(periods),
    timeScale: periods[0].timeScale
  });
  (rows || scenario.scenariosRows).forEach(row => {
    const cmuId = cmuIds.find(cmu => row.cmus.includes(cmu))
    if (row.isInTimePeriod(totalPeriod)) {
      const periodId = periods.find(p => row.isInTimePeriod(p)).id
      const cacheKey = `${cmuId}-${periodId}`
      if (isBlank(res[cacheKey])) res[cacheKey] = []
      res[cacheKey].push(row)
    }

    if (isBlank(res[`${cmuId}`])) res[`${cmuId}`] = []
    res[`${cmuId}`].push(row)
  });
  return res;
}

const calculateFunction = async ({
                                   scenario, driverColumn,
                                   cmuValues, cmusGroups, periods,
                                   rows = false
                                 }) => {
  if (isBlank(rows) || isBlank(driverColumn)) return {};

  const result = {};
  const groupedRowsByCmus = groupRowsByCmus({ scenario, cmuValues, periods, rows })
  Object.keys(cmuValues)?.forEach(cmuId => {
    const data = calculateScenariosData({
      periods, scenario,
      cmuId, cmusGroups,
      driverColumn,
      groupedRows: groupedRowsByCmus
    });
    if (data.every(val => val === null)) return;

    result[cmuId] = data
  })
  return result;
}

const generateLoadSimPromise = ({
                                  controller,
                                  forecastScenario, setSimulatedChartDataHashes,
                                  driverColumn, cmuValues, cmusGroups, periods,
                                  filteredSimulationScenarioRows
                                }) => {
  return new Promise((resolve, reject) => {
    // fetching data from resource one and changing some states
    controller.signal.addEventListener('abort', () => reject());
    calculateFunction({
      scenario: forecastScenario, rows: filteredSimulationScenarioRows,
      driverColumn, cmuValues, cmusGroups, periods
    }).then(result => {
      if (isPresent(result)) setSimulatedChartDataHashes(result)
      resolve(true)
    })
  })
}

const generateLoadBenchPromise = ({
                                    controller,
                                    forecastBenchmarkScenario, setBenchmarkChartDataHashes,
                                    driverColumn, cmuValues, cmusGroups, periods, simulatedType,
                                    filteredBenchmarkScenarioRows
                                  }) => {
  return new Promise((resolve, reject) => {
    // fetching data from resource two and changing some states
    if (simulatedType !== SIMULATED_TYPES.both) return resolve(true)

    controller.signal.addEventListener('abort', () => reject());
    calculateFunction({
      scenario: forecastBenchmarkScenario, rows: filteredBenchmarkScenarioRows,
      driverColumn, cmuValues, cmusGroups, periods
    }).then(result => {
      if (isPresent(result)) setBenchmarkChartDataHashes(result)
      resolve(true)
    })
  });
}


const ScenarioForecastCharts = ({
                                  forecast_simulator_scenario,
                                  config, timeScale,
                                  forecastScenario, forecastBenchmarkScenario,
                                  simulatedChartDataHashes, setSimulatedChartDataHashes,
                                  filteredSimulationScenarioRows, filteredBenchmarkScenarioRows,
                                  benchmarkChartDataHashes, setBenchmarkChartDataHashes
                                }) => {
  const controller = new AbortController();
  useEffect(() => {
    return generatePromisesCallbacks({ setLoading, controller })
  }, []);

  const [nonChartsComponentsRendered, setNonChartsComponentsRendered] = useState(false);
  const view_options = forecast_simulator_scenario?.scenario?.view_options || {}
  const [selectedDriver, setSelectedDriver] = useState(view_options?.selectedDriver)
  const [aggregatedType, setAggregatedType] = useState(view_options?.selectedSubTotal)
  const [simulatedType, setSimulatedType] = useState(view_options?.simulatedType || SIMULATED_TYPES.simulated)
  const [chartValuesType, setChartValuesType] = useState(view_options?.chartValuesType || CHART_VALUES_TYPES.percent)
  const [chartType, setChartType] = useState(view_options?.chartType || CHART_TYPES.line)
  const [loading, setLoading] = useState(forecast_simulator_scenario.view_loading || (isBlank(simulatedChartDataHashes) && isBlank(benchmarkChartDataHashes)))

  const periods = useMemo(() => forecastScenario.periods(), [isPresent(forecastScenario?.timeScale), forecastScenario?.timeScale, view_options.from, view_options.to]);

  const { filterScopes, cmusGroups } = forecastScenario.simulationScopesData
  const cmuGroupsCacheKey = cmusGroups.flatMap(row => row).join('-')

  const cmuValues = useMemo(() => {
    const cmuColumn = config.cmuColumns.find(cmu => cmu.id === aggregatedType);
    if (isPresent(filterScopes[aggregatedType]?.cmus)) {
      return filterScopes[aggregatedType]?.cmus.reduce((result, id) => {
        result[id] = (cmuColumn?.values || {})[id] || '';
        return result;
      }, {})
    } else if (isPresent(cmusGroups.flatMap(i => i))) {
      const allCmuGroups = forecast_simulator_scenario.cmus
      const columnCmus = Object.keys(cmuColumn?.values || {})
      return columnCmus.reduce((result, cmuColumnId) => {
        const cmuId = parseInt(cmuColumnId)
        if (
          allCmuGroups.some(group =>
            group.includes(cmuId) && cmusGroups.every(cmus => cmus.some(cmId => group.includes(parseInt(cmId))))
          )
        ) {
          result[cmuColumnId] = (cmuColumn?.values || {})[cmuColumnId] || '';
        }
        return result;
      }, {})
    }
    return cmuColumn?.values || {};
  }, [config?.cmuColumns, aggregatedType, cmuGroupsCacheKey])

  const driverColumn = useMemo(() => {
    return config?.allFactsColumns.find(column => column.id === selectedDriver)
  }, [selectedDriver, config?.allFactsColumns])

  const cmusCacheKey = Object.keys(cmuValues).join('-');
  const periodsCacheKey = periods.map(h => h.id).join('-');

  useEffect(() => {
    if (!nonChartsComponentsRendered) return;
    if (isPresent(benchmarkChartDataHashes)) setBenchmarkChartDataHashes({})

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadSimPromise({
          controller,
          forecastScenario, setSimulatedChartDataHashes,
          driverColumn, cmuValues, cmusGroups, periods,
          filteredSimulationScenarioRows
        }),
        () => generateLoadBenchPromise({
          controller,
          forecastBenchmarkScenario, setBenchmarkChartDataHashes,
          driverColumn, cmuValues, cmusGroups, periods, simulatedType,
          filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [selectedDriver, aggregatedType, cmusCacheKey, driverColumn?.id.toString(), periodsCacheKey]);
  useEffect(() => {
    if (!nonChartsComponentsRendered) return;
    if (isPresent(simulatedChartDataHashes)) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadSimPromise({
          controller,
          forecastScenario, setSimulatedChartDataHashes,
          driverColumn, cmuValues, cmusGroups, periods,
          filteredSimulationScenarioRows
        }),
        () => generateLoadBenchPromise({
          controller,
          forecastBenchmarkScenario, setBenchmarkChartDataHashes,
          driverColumn, cmuValues, cmusGroups, periods, simulatedType,
          filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [nonChartsComponentsRendered]);

  useEffect(() => {
    if (isPresent(benchmarkChartDataHashes)) return;
    if (simulatedType !== SIMULATED_TYPES.both) return;
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadBenchPromise({
          controller,
          forecastBenchmarkScenario, setBenchmarkChartDataHashes,
          driverColumn, cmuValues, cmusGroups, periods, simulatedType,
          filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [simulatedType]);
  useEffect(() => {
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadSimPromise({
          controller,
          forecastScenario, setSimulatedChartDataHashes,
          driverColumn, cmuValues, cmusGroups, periods,
          filteredSimulationScenarioRows
        })
      ]
    })
  }, [filteredSimulationScenarioRows]);
  useEffect(() => {
    if (!nonChartsComponentsRendered) return;

    return generatePromisesCallbacks({
      setLoading, controller,
      promises: [
        () => generateLoadBenchPromise({
          controller,
          forecastBenchmarkScenario, setBenchmarkChartDataHashes,
          driverColumn, cmuValues, cmusGroups, periods, simulatedType,
          filteredBenchmarkScenarioRows
        })
      ]
    })
  }, [filteredBenchmarkScenarioRows]);

  useEffect(() => {
    if (nonChartsComponentsRendered) return;
    if (isPresent(simulatedChartDataHashes)) return;
    if (!loading) setLoading(true);
  }, []);

  return <>
    <div className="container-xxl">
      <div className="row justify-content-center">
        <div className="col-7 col-lg-3 px-1 mb-2">
          <FactsSelect {...{ selectedDriver, setSelectedDriver, config, timeScale, loading: forecast_simulator_scenario.view_loading || !nonChartsComponentsRendered || loading }} />
        </div>
        <div className="col-5 col-lg-2 px-1 mb-2">
          <TotalsSelect {...{ aggregatedType, setAggregatedType, config, loading: forecast_simulator_scenario.view_loading || !nonChartsComponentsRendered || loading }} />
        </div>
        <div className="col-auto px-1 mb-2">
          <SimulatedType {...{ simulatedType, setSimulatedType, loading: forecast_simulator_scenario.view_loading || !nonChartsComponentsRendered || loading }} />
        </div>
        <div className="col-auto px-1 mb-2">
          <ChartValuesType {...{ chartValuesType, setChartValuesType, loading: forecast_simulator_scenario.view_loading || !nonChartsComponentsRendered || loading }} />
        </div>
        <div className="col-auto px-1 mb-2">
          <ChartType {...{ chartType, setChartType, loading: forecast_simulator_scenario.view_loading || !nonChartsComponentsRendered || loading }} />
        </div>
      </div>
    </div>
    <div className="mt-2 charts-container">
      { (checkAllNonChartsComponentsRendered(nonChartsComponentsRendered, setNonChartsComponentsRendered) || loading) && <Loader />}
      {
        !loading && nonChartsComponentsRendered &&
          <ScenariosChart {...{
            simulatedChartDataHashes, benchmarkChartDataHashes, driverColumn, cmuValues, periods, cmusGroups,
            simulatedType, chartValuesType, chartType,
            forecastScenario
          }} />
      }
    </div>
  </>
}
const mapStateToProps = ({ forecast_simulator_scenario }) => ({ forecast_simulator_scenario });
export default connect(mapStateToProps)(ScenarioForecastCharts);
